import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Helmet from 'components/Helmet';
import Actions from './components/Actions';
import EmailTable from './components/EmailTable';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';

const EmailList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('EMAIL.MODEL_TITLE')} />
      <ContentHeader
        title={t('EMAIL.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={<Breadcrumbs section={t('EMAIL.MODEL_TITLE')} />}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.EMAIL.LIST}>
        <EmailTable />
      </LimitedAccess>
    </>
  );
};

export default EmailList;
