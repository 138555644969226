import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Typography } from '_template';
import { ROUTE } from 'constants/route';
import { CommissionData } from '../../interfaces';
import CommisssionItemsTable from './CommisssionItems';

interface CommissionFormProps {
  recordId?: string;
  data?: CommissionData;
}

const CommissionForm = ({ recordId, data }: CommissionFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const isNew = recordId === 'new';

  // const [createMutation] = useMutation(CreateCommissionMutation);
  // const [updateMutation] = useMutation(UpdateCommissionMutation);

  const onCancel = () => navigate(ROUTE.COMMISSION_LIST);

  const validationSchema = Yup.object({});

  const onSubmit = (newData: CommissionData) => {
    // const variables = {
    //   id: recordId,
    //   data: {
    //     name: newData.name,
    //     category: newData.category,
    //     published: !!newData.published,
    //   },
    // };
    // handleSaveData(isNew ? createMutation : updateMutation, variables, onCancel);
  };

  const { consultant } = data || {};

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form submitButtonDisabled onSubmit={handleSubmit} onCancel={onCancel}>
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item sm={12}>
                  <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                    {consultant?.lastName} {consultant?.firstName}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {consultant?.number}
                  </Typography>
                  <Typography gutterBottom>
                    {consultant?.permanentAddress?.street}{' '}
                    {consultant?.permanentAddress?.houseNumber}
                    <br />
                    {consultant?.permanentAddress?.zipCode}{' '}
                    {consultant?.permanentAddress?.city}
                    <br />
                    {consultant?.permanentAddress?.country}
                  </Typography>
                  <Typography gutterBottom>
                    IČO: {consultant?.companyIC}
                    <br />
                    DIČ DPH: {consultant?.companyICDPH}
                    <br />
                  </Typography>
                  <Typography gutterBottom>
                    IBAN: {consultant?.bankIBAN}
                  </Typography>
                  <Typography gutterBottom>
                    Pozice: <strong>{consultant?.position?.name}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Tabs panelsToCard={false} sx={{ mt: 5 }}>
                    <TabPanel label={t('COMMISSION_ITEM.LIST')}>
                      {data?.items && (
                        <CommisssionItemsTable items={data.items} />
                      )}
                    </TabPanel>
                  </Tabs>
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default CommissionForm;
