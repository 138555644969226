import { ModuleRightType } from 'constants/rights';

export const positionRights: ModuleRightType = {
  POSITION: {
    LIST: 'POSITION.LIST',
    ADD: 'POSITION.ADD',
    EDIT: 'POSITION.EDIT',
    DELETE: 'POSITION.DELETE',
  },
};
