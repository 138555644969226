import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import ButtonAdd from 'components/ButtonAdd';
import FunctionsIcon from '@mui/icons-material/Functions';
import CalculateModal from './CalculateModal';

const Actions = () => {
  const [showModal, setShowModal] = useState(false);

  const handleToggleOpenModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      {showModal && <CalculateModal onClose={handleToggleOpenModal} />}
      <ButtonAdd
        size="small"
        startIcon={<FunctionsIcon />}
        onClick={handleToggleOpenModal}
      >
        <Trans>COMMISSION.CALCULATE</Trans>
      </ButtonAdd>
    </>
  );
};

export default Actions;
