import { bankAccountRights } from 'modules/BankAccount/constants/rights';
import { consultantRights } from 'modules/Consultant/constants/rights';
import { commissionRights } from 'modules/Commission/constants/rights';
import { currencyRights } from 'modules/Currency/constants/rights';
import { emailRights } from 'modules/Email/constants/rights';
import { emailTemplateRights } from 'modules/EmailTemplate/constants/rights';
import { paymentRights } from 'modules/Payment/constants/rights';
import { positionRights } from 'modules/Position/constants/rights';
import { presentationTypeRights } from 'modules/PresentationType/constants/rights';
import { registrationRights } from 'modules/Registration/constants/rights';
import { settingRights } from 'modules/Setting/constants/rights';
import { taxDocumentRights } from 'modules/TaxDocument/constants/rights';
import { userRights } from 'modules/User/constants/rights';
import { userGroupRights } from 'modules/UserGroup/constants/rights';

export interface ModuleRightType {
  [key: string]: {
    [key: string]: string,
  };
};

export const RIGHTS: ModuleRightType = {
  ...bankAccountRights,
  ...commissionRights,
  ...consultantRights,
  ...currencyRights,
  ...emailRights,
  ...emailTemplateRights,
  ...paymentRights,
  ...positionRights,
  ...presentationTypeRights,
  ...registrationRights,
  ...settingRights,
  ...taxDocumentRights,
  ...userRights,
  ...userGroupRights,
};
