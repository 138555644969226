import React from 'react';
import { useMutation } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { Confirm, MenuItem, displayAlert, displaySuccess } from '_template';
import { ResendMembershipConfirmationMessageMutation } from 'modules/Consultant/query';
import { DropDownButtonSendActionsProps } from './DropDownButtonSendActions';

const MenuItemSendMembershipConfirmation = (
  props: DropDownButtonSendActionsProps
) => {
  const { t } = useTranslation();
  const [sendMembershipConfirmationMutation] = useMutation(
    ResendMembershipConfirmationMessageMutation
  );

  const onClick = () => {
    Confirm({
      title: t(
        'CONSULTANT.RESENT_MESSAGE.MEMBERSHIP_CONFIRMATION.REALLY_TO_SEND'
      ),
      description: t<string>(
        'CONSULTANT.RESENT_MESSAGE.MEMBERSHIP_CONFIRMATION.REALLY_TO_SEND_DESCRIPTION'
      ),
      labelSubmit: t('GENERAL.YES'),
      labelSubmitVariant: 'primary',
      labelCancel: t('GENERAL.NO'),
    })
      .then(() => {
        sendMembershipConfirmationMutation({
          variables: {
            consultantId: props.consultant.id,
          },
          refetchQueries: ['emailList', 'fileList'],
        })
          .then(() => {
            displaySuccess(t('CONSULTANT.MESSAGE_SEND_SUCCESS'));
            props.refetch();
          })
          .catch(() => displayAlert(t('CONSULTANT.MESSAGE_SEND_FAILED')));
      })
      .catch(() => {});
  };

  return (
    <MenuItem sx={{ fontSize: 12 }} onClick={() => onClick()}>
      <Trans>CONSULTANT.RESENT_MESSAGE.MEMBERSHIP_CONFIRMATION.LABEL</Trans>
    </MenuItem>
  );
};

export default MenuItemSendMembershipConfirmation;
