import React, { forwardRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { TextFieldProps } from '_template';
import { GetConsultantListQuery } from 'modules/Consultant/query';
import { consultantNumberAndName } from 'modules/Consultant/helpers/consultant';
import AutoComplete from 'components/AutoComplete';

type ConsultantSelectProps = TextFieldProps & {
  readOnly?: boolean;
};

const ConsultantSelect = forwardRef((props: ConsultantSelectProps, _) => {
  const { data } = useQuery(GetConsultantListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const { consultantList } = data || {};

  const options = useMemo(() => {
    return (consultantList?.rows || []).map((consultant: GQL.IConsultant) => {
      return {
        label: consultantNumberAndName(consultant),
        value: consultant.id,
      };
    });
  }, [consultantList]);

  return <AutoComplete {...props} options={options} />;
});

export default ConsultantSelect;
