export const toggleInArray = <T>(values: T[], value: T) => {
  const indexOf = values.indexOf(value);

  if (indexOf === -1) {
    values.push(value);
    return values;

  } else {
    const newValues = [...values];
    newValues.splice(indexOf, 1);
    return newValues;

  }
};
