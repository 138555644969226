import React from 'react';
import AppLayout from 'components/AppLayout';
import EmailTemplateList from '../containers/EmailTemplateList';
import EmailTemplateDetail from '../containers/EmailTemplateDetail';

const routes = {
  path: 'emailTemplate',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <EmailTemplateList />,
    },
    {
      path: 'detail/:recordId?',
      element: <EmailTemplateDetail />,
    },
  ],
};

export default routes;
