export const hasControlError = (error: string | Object) => {
  if (typeof error === 'object' && Object.keys(error).length > 0) {
    return true;
  }
  if (typeof error === 'string') {
    return error !== '';
  }
  return false;
};

export const textControlError = (error: string | Object) => {
  if (typeof error === 'object' && Object.keys(error).length > 0) {
    // @ts-ignore
    return error[Object.keys(error)[0]];
  }
  if (typeof error === 'string') {
    return error;
  }
  return '';
};
