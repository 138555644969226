import React from 'react';
import AppLayout from 'components/AppLayout';
import EmailList from '../containers/EmailList';

const routes = {
  path: 'email',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <EmailList />,
    },
  ],
};

export default routes;
