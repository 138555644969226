import React from 'react';
import { GetCommissionQuery } from 'modules/Commission/query';
import ContainerDetailWrapper from 'components/ContainerDetailWrapper';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';
import CommissionForm from './CommissionForm';

const CommissionDetail = () => (
  <ContainerDetailWrapper
    query={GetCommissionQuery}
    parseQueryData={(data) => data?.commission || null}
    languagePrefix="COMMISSION"
    routeList={ROUTE.COMMISSION_LIST}
    rights={RIGHTS.COMMISSION}
  >
    {({ recordId, data }) => (
      <CommissionForm
        recordId={recordId}
        data={{
          ...data,
          published: data ? data.published : true,
        }}
      />
    )}
  </ContainerDetailWrapper>
);

export default CommissionDetail;
