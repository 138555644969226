import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface LoaderScreenProps {
  open?: boolean;
  onClick?: () => void;
}

const LoaderScreen = ({ open = true, onClick }: LoaderScreenProps) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
    onClick={onClick}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default LoaderScreen;
