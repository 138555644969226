import React, { forwardRef, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GetPositionListQuery } from 'modules/Position/query';
import { positionNumberAndName } from 'modules/Position/helpers/position';
import AutoComplete from 'components/AutoComplete';
import { Box, TextFieldProps } from '_template';

type PositionSelectProps = TextFieldProps & {
  readOnly?: boolean;
  multiple?: boolean;
};

interface PositionData extends GQL.IPosition {
  level: number;
}

const PositionSelect = forwardRef((props: PositionSelectProps, _) => {
  const { data } = useQuery(GetPositionListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const { positionList } = data || {};

  const treeData = useCallback(
    (parentId: string | null = null, level: number = 0): PositionData[] | undefined => {
      if (!positionList) {
        return undefined;
      }

      return (positionList?.rows || [])
        .filter((position: GQL.IPosition) => (position.parent?.id || null) === parentId)
        .reduce((acc: PositionData[], position: GQL.IPosition) => {
          acc.push({
            ...position,
            level,
          });
          treeData(position.id, level + 1)?.forEach((subPosition: PositionData) => {
            acc.push(subPosition);
          });
          return acc;
        }, []);
    },
    [positionList]
  );

  const options = useMemo(() => {
    return (treeData() || []).map((position: PositionData) => {
      return {
        label: positionNumberAndName(position),
        level: position.level,
        value: position.id,
      };
    });
  }, [treeData]);

  return (
    <AutoComplete
      {...props}
      options={options}
      autocompleteProps={{
        renderOption: (props: any, option: any) => (
          <Box component='li' {...props}>
            <Box sx={{ pl: option.level * 3 }}>{option.label}</Box>
          </Box>
        ),
      }}
    />
  );
});

export default PositionSelect;
