import { notifySaveSuccess, notifySaveError } from 'helpers/notifications';

export const handleSaveData = (
  mutation: any,
  data: any,
  cancel: (result?: any) => void,
  refetch?: () => void
) => {
  mutation({ variables: { ...data } })
    .then(cancel)
    .then(refetch)
    .then(notifySaveSuccess)
    .catch(notifySaveError);
};
