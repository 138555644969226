import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Card, CheckIcon, Table, TableContainer, TableColumn, TableActions } from '_template';
import { DeleteUserMutation, GetUserListQuery } from 'modules/User/query';
import ButtonDelete from 'components/ButtonDelete';
import { selectLoggedUser } from 'stores/loggedUserSlice';
import { ROUTE } from 'constants/route';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import Link from 'components/Link';
import { RIGHTS } from 'constants/rights';

const UserTable = () => {
  const { t } = useTranslation();
  const loggedUser = useSelector(selectLoggedUser);
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs('user', {
    sortField: 'name',
    sortBy: 'asc',
  });

  const userListQuery = useQuery(GetUserListQuery, {
    variables: {
      where: {
      },
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network'
  });

  const { loading, error, data } = userListQuery;

  const refetch = () => userListQuery.refetch();

  const [deleteUser] = useMutation(DeleteUserMutation);

  return (
    <TableContainer component={Card}>
      <Table<GQL.IUser>
        dataSource={data?.userList?.rows}
        recordsCount={data?.userList?.count}
        loading={loading}
        error={error}
        {...tableProps}
      >
        <TableColumn
          label={t('USER.NAME')}
          dataIndex="name"
          sortable
          defaultSortBy="asc"
          render={(row: GQL.IUser) => (
            <Link
              component={NavLink}
              to={ROUTE.USER_DETAIL.replace(':recordId', row.id)}
              right={RIGHTS.USER.EDIT}
            >
              {row.name}
            </Link>
          )}
        />
        <TableColumn
          label={t('USER.USER_NAME')}
          dataIndex="userName"
          sortable
          defaultSortBy="asc"
          sx={{
            display: { xs: 'none', md: 'table-cell' },
          }}
        />
        <TableColumn
          label={t('USER.EMAIL')}
          dataIndex="email"
          sortable
          defaultSortBy="asc"
          sx={{
            display: { xs: 'none', sm: 'table-cell' },
          }}
        />
        <TableColumn
          label={t('USER.USER_GROUP')}
          dataIndex="userGroup"
          sx={{
            display: { xs: 'none', lg: 'table-cell' },
          }}
          render={(row: GQL.IUser) => row.userGroup?.name}
        />
        <TableColumn
          label={t(`USER.ACTIVE`)}
          dataIndex="active"
          align="center"
          sx={{
            display: {
              xs: 'none',
              sm: 'table-cell',
              width: '70px',
            },
          }}
          render={({ active }: GQL.IUser) => <CheckIcon value={active === true} withColor />}
        />
        <TableActions
          render={(row: GQL.IUser) => {
            return (
              <ButtonDelete
                message={t('USER.REALLY_TO_DELETE')}
                disabled={loggedUser?.id === row.id}
                right={RIGHTS.USER.DELETE}
                onDelete={() => deleteUser({ variables: { id: row.id } })}
                refetch={refetch}
              />
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default UserTable;
