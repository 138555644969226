import React from 'react';
import { useQuery } from '@apollo/client';
import { MenuItem, TextFieldProps } from '_template';
import { GetUserGroupListQuery } from 'modules/UserGroup/query';
import Select from 'components/Select';

const UserGroupSelect = (props: TextFieldProps) => {
  const { data } = useQuery(GetUserGroupListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const { userGroupList } = data || {};

  return (
    <Select {...props}>
      {userGroupList?.rows?.map((userGroup: GQL.IUserGroup) => (
        <MenuItem key={userGroup.id} value={userGroup.id}>
          {userGroup.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default UserGroupSelect;
