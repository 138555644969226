import React from 'react';
import AppLayout from 'components/AppLayout';
import ConsultantList from '../containers/ConsultantList';
import ConsultantDetail from '../containers/ConsultantDetail';

const routes = {
  path: 'consultant',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <ConsultantList />,
    },
    {
      path: 'detail/:recordId?',
      element: <ConsultantDetail />,
    },
  ],
};

export default routes;
