import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, CssBaseline, Paper } from '@mui/material';
import { SidebarItemOptions } from '../Sidebar/types';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Footer from './Footer';
import GlobalStyle from '../../helpers/GlobalStyle';

const drawerWidth = 258;

interface LayoutSidebarProps {
  header?: string;
  logo?: string;
  items: SidebarItemOptions[];
}

export interface LayoutProps {
  sidebar: LayoutSidebarProps;
  header?: React.ReactNode;
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ sidebar, header, children }) => {
  const router = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <GlobalStyle />
      <Box
        sx={(theme) => ({
          width: 0,
          [theme.breakpoints.up("md")]: {
            width: `${drawerWidth}px`,
          },
          flexShrink: 0,
        })}
      >
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            header={sidebar.header}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={sidebar.items}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            header={sidebar.header}
            items={sidebar.items}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
        }}
      >
        <Navbar
          rightContent={header}
          onDrawerToggle={handleDrawerToggle}
        />
        <Paper
          sx={(theme) => ({
            p: 4,
            [theme.breakpoints.up("md")]: {
              p: 6,
            },
            flex: 1,
            background: theme.palette.background.default,
            "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
              flex: 'none',
            },
            "& .MuiPaper-root .MuiPaper-root": {
              boxShadow: 'none',
            },
          })}
        >
          {children}
          <Outlet />
        </Paper>
        <Footer
          links={{
            '/support': 'Support',
            '/help-center': 'Help Center',
            '/privacy': 'Privacy',
            '/terms-of-service': 'Terms of Service',
          }}
        />
      </Box>
    </Box>
  );
};

export default Layout;
