import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Search } from 'react-feather';
import TextField from '../TextField';
import Box from '../Box';
import Button from '../Button';
import { IconButton, InputAdornment } from '@mui/material';
import { Close } from '@mui/icons-material';

interface QuickSearchFieldProps {
  searchQuery?: string;
  onSubmit: (value: string) => void;
}

const QuickSearchField = ({ searchQuery, onSubmit }: QuickSearchFieldProps) => {
  const [value, setValue] = useState<string>(searchQuery || '');

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key.toLowerCase() === 'enter') {
      onSubmit(value);
    }
    if (e.key.toLowerCase() === 'escape') {
      onClear();
    }
  };

  const onClear = () => {
    setValue('');
    onSubmit('');
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue((e.target as HTMLInputElement).value);
  };

  return (
    <Box
      sx={{
        ml: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      <Box>
        <TextField
          name="searchQuery"
          placeholder="Vyhledávání"
          fullWidth={false}
          value={value}
          sx={{
            bgcolor: 'white',
            width: 200,
            m: 0,
          }}
          sxControl={{
            m: 0,
          }}
          withoutFormik
          withoutLabel
          withoutHelperText
          InputProps={{
            endAdornment: searchQuery ? (
              <InputAdornment position="end">
                <IconButton onClick={onClear} sx={{ p: 1 }}>
                  <Close sx={{ fontSize: 15 }} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </Box>
      <Box>
        <Button
          size="small"
          color="secondary"
          sx={{ minWidth: 'auto', height: 'auto', p: 2, ml: 1, mt: 0.5 }}
          onClick={() => onSubmit(value)}
        >
          <Search size={18} />
        </Button>
      </Box>
    </Box>
  );
};

export default QuickSearchField;
