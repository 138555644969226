import React from 'react';
import { GetEmailTemplateQuery } from 'modules/EmailTemplate/query';
import ContainerDetailWrapper from 'components/ContainerDetailWrapper';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';
import EmailTemplateForm from './EmailTemplateForm';

const EmailTemplateDetail = () => (
  <ContainerDetailWrapper
    query={GetEmailTemplateQuery}
    parseQueryData={(data) => data?.emailTemplate || null}
    languagePrefix="EMAIL_TEMPLATE"
    routeList={ROUTE.EMAIL_TEMPLATE_LIST}
    rights={RIGHTS.EMAIL_TEMPLATE}
  >
    {({ recordId, data }) => (
      <EmailTemplateForm
        recordId={recordId}
        data={{
          ...data,
        }}
      />
    )}
  </ContainerDetailWrapper>
);

export default EmailTemplateDetail;
