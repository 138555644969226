import React from 'react';
import { Link as TemplateLink, LinkProps as TemplateLinkProps, Typography } from '_template';
import { useUserAccess } from 'hooks/useUserAccess';

interface LinkProps extends TemplateLinkProps {
  // TODO: doplnit správný typ
  component?: any;
  // component: ForwardRefExoticComponent<LinkProps & RefAttributes<HTMLAnchorElement>>;
  to: string;
  right?: string;
}

const Link = ({ component, right, to, ...props }: LinkProps) => {
  const { getAccess } = useUserAccess();

  if (right && !getAccess(right)) {
    return (
      <Typography {...props}>
        {props.children}
      </Typography>
    );
  }

  return (
    <TemplateLink
      {...props}
      component={component}
      to={to}
    />
  );
};

export default Link;
