import React from 'react';
import { Box, Typography } from '@mui/material';
import Button from '../../components/Button';
import GlobalStyle from '../../helpers/GlobalStyle';

interface NotFoundProps {
  onClick?: () => void;
}

const NotFound = ({ onClick }: NotFoundProps) => {
  const onClickReturn = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        height: 'calc(100% - 170px)',
      }}
    >
      {/* <Helmet title="404 Error" /> */}
      <GlobalStyle />
      <Box>
        <Typography component="h1" variant="h1" align="center" gutterBottom>
          404
        </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          Page not found.
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          The page you are looking for might have been removed.
        </Typography>
        <Button
          to="/"
          variant="contained"
          color="secondary"
          sx={{ mt: 2 }}
          onClick={onClickReturn}
        >
          Return to Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
