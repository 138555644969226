import React, { ChangeEvent } from 'react';
import {
  DropdownMenu,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Tooltip,
} from '_template';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageList } from 'stores/languageSlice';
import { changeInputLanguage, selectInputLanguage } from 'stores/appSlice';
import {
  hasControlError,
  textControlError,
} from '_template/helpers/forms/controlError';

export type TextFieldProps = MuiTextFieldProps & {
  languageObject?: boolean;
  readOnly?: boolean;
  mask?: RegExp;
  withoutHelperText?: boolean;
};

const TextField = ({
  languageObject = false,
  readOnly = false,
  mask,
  withoutHelperText = false,
  ...props
}: TextFieldProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputLanguage = useSelector(selectInputLanguage);
  const languages = useSelector(selectLanguageList);

  /* eslint-disable-next-line */
  const formikContext = useFormikContext();

  const controlValue =
    props.value ||
    (props.name &&
      formikContext?.values &&
      (formikContext?.values as any)[props.name]);

  const controlError =
    props.error ||
    (props.name &&
      formikContext?.errors &&
      (formikContext?.errors as any)[props.name]);

  const isValidByMask = (value: string) => {
    if (mask) {
      return mask.test(value);
    }
    return true;
  };

  const onChange = (e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (formikContext && isValidByMask(value)) {
      if (languageObject && inputLanguage) {
        formikContext?.setFieldValue(name, {
          ...controlValue,
          [inputLanguage.code]: value,
        });
      } else {
        formikContext?.setFieldValue(name, value);
      }
    }
  };

  const onClickChangeInputLanguage = (l: GQL.ILanguage) => {
    dispatch(changeInputLanguage(l));
  };

  const value =
    controlValue !== null && controlValue !== undefined
      ? inputLanguage?.code &&
        languageObject &&
        typeof controlValue === 'object'
        ? controlValue[inputLanguage.code] || ''
        : controlValue
      : '';

  const helperText =
    props.helperText ||
    textControlError(controlError) ||
    (withoutHelperText ? undefined : ' ');

  return (
    <MuiTextField
      value={value}
      onChange={onChange}
      InputProps={{
        readOnly,
        startAdornment: languageObject &&
          languages!.length > 1 &&
          inputLanguage && (
            <InputAdornment position="start" sx={{ ml: '-5px' }}>
              <DropdownMenu
                control={
                  <Tooltip title={t('FORM.CHANGE_LOCALIZATION')}>
                    <IconButton
                      size="small"
                      sx={{
                        mt: '2px',
                        width: '24px',
                        height: '24px',
                        fontSize: 13,
                      }}
                    >
                      <small>{inputLanguage.code.toUpperCase()}</small>
                    </IconButton>
                  </Tooltip>
                }
              >
                {languages
                  ?.filter((l: GQL.ILanguage) => l.id !== inputLanguage.id)
                  ?.map((l: GQL.ILanguage) => (
                    <MenuItem
                      key={l.id}
                      sx={{ fontSize: 12 }}
                      onClick={() => onClickChangeInputLanguage(l)}
                    >
                      {l.code.toUpperCase()}
                    </MenuItem>
                  ))}
              </DropdownMenu>
            </InputAdornment>
          ),
      }}
      FormHelperTextProps={{
        style: {
          display:
            withoutHelperText && helperText === undefined ? 'none' : 'inherit',
        },
      }}
      {...props}
      error={hasControlError(controlError)}
      helperText={helperText}
      sx={{
        pb: withoutHelperText ? 2 : 0,
      }}
    />
  );
};

export default TextField;
