import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PatternIcon from '@mui/icons-material/Pattern';
import { Card, CardContent } from '_template';
import SettingTitle from 'modules/Setting/components/SettingTitle';
import SettingLayout from 'modules/Setting/components/SettingLayout';
import SettingValue from 'modules/Setting/components/SettingValue';
import RegistrationModeSetting from './RegistrationModeSetting';

const RegistrationSetting = () => {
  const { t } = useTranslation();

  return (
    <SettingLayout>
      <SettingTitle icon={<PatternIcon />}>
        <Trans>REGISTRATION.MODEL_TITLE</Trans>
      </SettingTitle>
      <Card>
        <CardContent>
          <SettingValue 
            label={t('REGISTRATION.SETTING_REGISTRATION_MODE')}
          >
            <RegistrationModeSetting />
          </SettingValue>
        </CardContent>
      </Card>
    </SettingLayout>
  );
};

export default RegistrationSetting;
