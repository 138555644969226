import React from 'react';
import { Box } from '_template';

interface ConsultantAvatarProps {
  url: string;
  sx: any;
}

const ConsultantAvatar = ({ url, sx }: ConsultantAvatarProps) => {
  return (
    <Box sx={{ maxHeight: '48px', maxWidth: '48px', ...sx }}>
      <img
        src={url}
        style={{
          maxWidth: '100%',
          maxHeight: '48px',
          borderRadius: '100%',
          border: '1px solid #ddd',
          padding: '2px',
        }}
      />
    </Box>
  );
};

export default ConsultantAvatar;
