import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  Table,
  TableContainer,
  TableColumn,
  TableActions,
  FilterContainer,
} from '_template';
import {
  DeleteRegistrationMutation,
  GetRegistrationListQuery,
} from 'modules/Registration/query';
import ButtonDelete from 'components/ButtonDelete';
import { ROUTE } from 'constants/route';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import Phone from 'components/Phone';
import { registrationName } from 'modules/Registration/helpers/registration';
import { positionNumberAndName } from 'modules/Position/helpers/position';
import Link from 'components/Link';
import { RIGHTS } from 'constants/rights';
import useDataFilter from 'helpers/table/useDataFilter';

const RegistrationTable = () => {
  const { t } = useTranslation();
  const { searchQuery, filterProps } = useDataFilter();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs(
    'registration',
    {
      sortField: 'createdAt',
      sortBy: 'desc',
    }
  );

  const registrationListQuery = useQuery(GetRegistrationListQuery, {
    variables: {
      where: {
        searchQuery,
      },
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, error, data } = registrationListQuery;

  const refetch = () => registrationListQuery.refetch();

  const [deleteRegistration] = useMutation(DeleteRegistrationMutation);

  return (
    <>
      <FilterContainer {...filterProps} />
      <TableContainer component={Card}>
        <Table<GQL.IRegistration>
          dataSource={data?.registrationList?.rows}
          recordsCount={data?.registrationList?.count}
          loading={loading}
          error={error}
          onRefetch={refetch}
          {...tableProps}
        >
          <TableColumn
            label={t('REGISTRATION.CREATED_AT')}
            dataIndex="createdAt"
            sortable
            defaultSortBy="desc"
            sx={{
              display: { xs: 'none', md: 'table-cell' },
            }}
            render={(row: GQL.IRegistration) =>
              moment(row.createdAt).format('D.M.Y')
            }
          />
          <TableColumn
            label={t('REGISTRATION.PAR')}
            dataIndex="parent"
            width={70}
            sx={{
              display: { xs: 'none', lg: 'table-cell' },
            }}
            render={(row: GQL.IRegistration) =>
              !row.parent?.isSystem ? row.parent?.number : ''
            }
          />
          <TableColumn
            label={t('REGISTRATION.POSITION')}
            dataIndex="position"
            sx={{
              display: { xs: 'none', lg: 'table-cell' },
            }}
            render={(row: GQL.IRegistration) =>
              positionNumberAndName(row.position)
            }
          />
          <TableColumn
            label={t('REGISTRATION.NAME')}
            dataIndex="name"
            sortable
            defaultSortBy="asc"
            render={(row: GQL.IRegistration) => (
              <Link
                component={NavLink}
                to={ROUTE.REGISTRATION_DETAIL.replace(':recordId', row.id)}
                right={RIGHTS.REGISTRATION.EDIT}
              >
                {registrationName(row)}
              </Link>
            )}
          />
          <TableColumn
            label={t('REGISTRATION.EMAIL')}
            dataIndex="email"
            sortable
            defaultSortBy="asc"
            sx={{
              display: { xs: 'none', sm: 'table-cell' },
            }}
          />
          <TableColumn
            label={t('REGISTRATION.MOBILE')}
            dataIndex="mobile"
            sortable
            defaultSortBy="asc"
            sx={{
              display: { xs: 'none', sm: 'table-cell' },
            }}
            render={(row: GQL.IRegistration) => (
              <Phone
                data={{
                  phoneNumber: row.mobileNumber,
                  phonePrefix: row.mobilePrefix,
                }}
              />
            )}
          />
          <TableActions
            render={(row: GQL.IRegistration) => {
              return (
                <ButtonDelete
                  message={t('REGISTRATION.REALLY_TO_DELETE')}
                  right={RIGHTS.REGISTRATION.DELETE}
                  onDelete={() =>
                    deleteRegistration({ variables: { id: row.id } })
                  }
                  refetch={refetch}
                />
              );
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default RegistrationTable;
