import React, { ChangeEvent } from 'react';
import { TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';
import TextField from '../TextField';

type DatePickerFieldProps = TextFieldProps & {
  withoutFormik?: boolean;
}

const DatePickerField = ({ withoutFormik = false, ...props }: DatePickerFieldProps) => {
  /* eslint-disable-next-line */
  const formikContext = !withoutFormik ? useFormikContext() : null;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    if (formikContext) {
      formikContext?.setFieldValue(name, value);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const value = props.value
    || (props.name && formikContext?.values && (formikContext?.values as any)[props.name]);

  return (
    <TextField
      type="date"
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={onChange}
      {...props}
    />
  )
};

export default DatePickerField;
