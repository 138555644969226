import React from 'react';
import { GetPaymentQuery } from 'modules/Payment/query/Payment';
import ContainerDetailWrapper from 'components/ContainerDetailWrapper';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';
import PaymentForm from './PaymentForm';

const PaymentDetail = () => (
  <ContainerDetailWrapper
    query={GetPaymentQuery}
    parseQueryData={(data) => data?.payment || null}
    languagePrefix="PAYMENT"
    routeList={ROUTE.PAYMENT_LIST}
    rights={RIGHTS.PAYMENT}
  >
    {({ recordId, data }) => (
      <PaymentForm
        recordId={recordId}
        data={{
          ...data,
          published: data ? data.published : true,
        }}
      />
    )}
  </ContainerDetailWrapper>
);

export default PaymentDetail;
