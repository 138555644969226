import { useMutation } from '@apollo/client';
import { UserLoginMutation } from 'modules/User/query';
import { AuthToken } from 'services/AuthToken';

const useAuth = () => {
  const [loginMutation] = useMutation(UserLoginMutation);

  const signIn = async (userName: string, password: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      loginMutation({
        variables: {
          userName,
          password,
        },
        onCompleted: (data) => {
          if (!data.userLogin) {
            throw new Error('User not found');
          }
          AuthToken.setToken(data.userLogin.authToken);
          resolve(true);
        },
        onError: (err) => {
          reject(err);
        },
      })
    });
  };

  const signOut = () => {
    AuthToken.removeToken();
  }

  const isLogged = (): boolean => {
    return AuthToken.getToken() !== null;
  }

  return {
    isLogged,
    signIn,
    signOut,
  };
};

export default useAuth;
