import { useTranslation } from 'react-i18next';
import {
  // Archive,
  // Image,
  // Layout,
  // Mail,
  // ShoppingCart,
  // PieChart,
  // Trello,
  // Tv,
  Sliders,
  Users,
  Settings,
  Mail,
  DollarSign,
} from 'react-feather';
import { ROUTE } from './route';
import { RIGHTS } from './rights';
import { SidebarItemOptions } from '_template';
import { useUserAccess } from 'hooks/useUserAccess';

const useSidebarMenuItems = () => {
  const { t } = useTranslation();
  const { getAccess } = useUserAccess();

  const items: SidebarItemOptions[] = [
    {
      pages: [
        {
          href: '/',
          icon: Sliders,
          title: t<string>('NAVIGATION.DASHBOARD'),
        },
        {
          icon: Users,
          title: t<string>('NAVIGATION.NETWORK'),
          children: [
            {
              href: ROUTE.REGISTRATION_LIST,
              title: t<string>('REGISTRATION.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.REGISTRATION.LIST),
            },
            {
              href: ROUTE.CONSULTANT_LIST,
              title: t<string>('NAVIGATION.CONSULTANT'),
              disabled: !getAccess(RIGHTS.CONSULTANT.LIST),
            },
            {
              href: ROUTE.POSITION_LIST,
              title: t<string>('NAVIGATION.POSITION'),
              disabled: !getAccess(RIGHTS.POSITION.LIST),
            },
            {
              href: ROUTE.PRESENTATION_TYPE_LIST,
              title: t<string>('PRESENTATION_TYPE.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.PRESENTATION_TYPE.LIST),
            },
            {
              href: ROUTE.COMMISSION_LIST,
              title: t<string>('COMMISSION.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.COMMISSION.LIST),
            },
          ]
        },
        {
          icon: Mail,
          title: t<string>('NAVIGATION.COMMUNICATIONS'),
          children: [
            {
              href: ROUTE.EMAIL_LIST,
              title: t<string>('EMAIL.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.EMAIL.LIST),
            },
            {
              href: ROUTE.EMAIL_TEMPLATE_LIST,
              title: t<string>('EMAIL_TEMPLATE.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.EMAIL_TEMPLATE.LIST),
            },
          ]
        },
        {
          icon: DollarSign,
          title: t<string>('NAVIGATION.FINANCE'),
          children: [
            {
              href: ROUTE.PAYMENT_LIST,
              title: t<string>('PAYMENT.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.PAYMENT.LIST),
            },
            {
              href: ROUTE.TAX_DOCUMENT_LIST,
              title: t<string>('TAX_DOCUMENT.MODEL_TITLE'),
              // disabled: !getAccess(RIGHTS.TAX_DOCUMENT.LIST),
            },
          ]
        },
        // {
        //   href: ROUTE.ORDER_LIST,
        //   icon: ShoppingCart,
        //   title: t<string>('NAVIGATION.ORDER'),
        //   children: [
        //     {
        //       href: ROUTE.ORDER_LIST,
        //       title: t<string>('NAVIGATION.ORDER'),
        //       badge: {
        //         value: '12',
        //         color: 'error',
        //       }
        //     },
        //     {
        //       href: ROUTE.INVOICE_LIST,
        //       title: t<string>('NAVIGATION.INVOICE')
        //     },
        //     {
        //       href: ROUTE.TAX_DOCUMENT_LIST,
        //       title: t<string>('NAVIGATION.PROFORMA')
        //     }
        //   ]
        // },
        // {
        //   href: ROUTE.CATEGORY_LIST,
        //   icon: Archive,
        //   title: t<string>('NAVIGATION.CATEGORY')
        // },
        // {
        //   href: ROUTE.PRODUCT_LIST,
        //   icon: Tv,
        //   title: t<string>('NAVIGATION.PRODUCT'),
        //   children: [
        //     {
        //       href: ROUTE.PRODUCT_LIST,
        //       title: t<string>('NAVIGATION.PRODUCT')
        //     },
        //     {
        //       href: ROUTE.ATTRIBUTE_LIST,
        //       title: t<string>('NAVIGATION.ATTRIBUTE')
        //     },
        //     {
        //       href: ROUTE.PARAMETER_LIST,
        //       title: t<string>('NAVIGATION.PARAMETER')
        //     },
        //     {
        //       href: ROUTE.PRODUCT_TAG_LIST,
        //       title: t<string>('NAVIGATION.TAG')
        //     },
        //     {
        //       href: ROUTE.BRAND_LIST,
        //       title: t<string>('NAVIGATION.BRAND')
        //     },
        //   ]
        // },
        // {
        //   href: ROUTE.CUSTOMER_LIST,
        //   icon: Users,
        //   title: t<string>('NAVIGATION.CUSTOMER')
        // },
      ],
    },
    // {
    //   title: t<string>('NAVIGATION.CONTENT'),
    //   pages: [
    //     {
    //       href: ROUTE.PAGE_LIST,
    //       icon: Layout,
    //       title: t<string>('NAVIGATION.PAGE'),
    //     },
    //     {
    //       href: ROUTE.BANNER_LIST,
    //       icon: Image,
    //       title: t<string>('NAVIGATION.BANNER'),
    //     },
    //   ],
    // },
    {
      pages: [
        // {
        //   href: ROUTE.EMAIL_TEMPLATE_LIST,
        //   icon: Mail,
        //   title: t<string>('NAVIGATION.EMAIL'),
        // },
        // {
        //   href: ROUTE.MODULE_LIST,
        //   icon: Trello,
        //   title: t<string>('NAVIGATION.MODULES'),
        //   children: [
        //     {
        //       href: ROUTE.MODULE_ZASILKOVNA,
        //       title: t<string>('NAVIGATION.ZASILKOVNA')
        //     }
        //   ]
        // },
        // {
        //   href: ROUTE.STATISTIC,
        //   icon: PieChart,
        //   title: t<string>('NAVIGATION.STATISTIC')
        // },
        {
          // href: ROUTE.SETTING,
          icon: Settings,
          title: t<string>('NAVIGATION.SETTING'),
          children: [
            {
              href: ROUTE.SETTING_BASIC,
              title: t<string>('SETTING.NAVIGATION'),
              disabled: !getAccess(RIGHTS.SETTING.ACCESS),
            },
            {
              href: ROUTE.USER_LIST,
              title: t<string>('USER.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.USER.LIST),
            },
            {
              href: ROUTE.USER_GROUP_LIST,
              title: t<string>('USER_GROUP.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.USER_GROUP.LIST),
            },
            // {
            //   href: ROUTE.LANGUAGE_LIST,
            //   title: t<string>('NAVIGATION.LANGUAGE'),
            // },
            {
              href: ROUTE.CURRENCY_LIST,
              title: t<string>('CURRENCY.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.CURRENCY.LIST),
            },
            {
              href: ROUTE.BANK_ACCOUNT_LIST,
              title: t<string>('BANK_ACCOUNT.MODEL_TITLE'),
              disabled: !getAccess(RIGHTS.BANK_ACCOUNT.LIST),
            },
            // {
            //   href: ROUTE.VAT_LIST,
            //   title: t<string>('NAVIGATION.VAT'),
            // },
            // {
            //   href: ROUTE.AVAILABILITY_LIST,
            //   title: t<string>('NAVIGATION.AVAILABILITY'),
            // },
            // {
            //   href: ROUTE.ORDER_STATUS_LIST,
            //   title: t<string>('NAVIGATION.ORDER_STATE'),
            // },
            // {
            //   href: ROUTE.UNIT_LIST,
            //   title: t<string>('NAVIGATION.UNIT'),
            // },
            // {
            //   href: ROUTE.ORDER_LABEL_LIST,
            //   title: t<string>('NAVIGATION.ORDER_LABEL'),
            // },
            // {
            //   href: ROUTE.DELIVERY_METHOD_LIST,
            //   title: t<string>('NAVIGATION.DELIVERY_METHOD'),
            // },
            // {
            //   href: ROUTE.PAYMENT_METHOD_LIST,
            //   title: t<string>('NAVIGATION.PAYMENT_METHOD'),
            // },
          ],
        },
      ],
    },
  ];

  return {
    items,
  };
};

// const docsSection: SidebarItemPageOptions[] = [
//   {
//     href: '/documentation/welcome',
//     icon: BookOpen,
//     title: 'Documentation',
//   },
//   {
//     href: '/changelog',
//     icon: List,
//     title: 'Changelog',
//     badge: 'v4.2.1',
//   },
// ];

export default useSidebarMenuItems;
