import gql from 'graphql-tag';

export const GetCommissionListQuery: any = gql`
  query commissionList(
    $where: CommissionListWhereInput
    $limit: LimitsInput
    $orderBy: OrderByInput
  ) {
    commissionList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        consultant {
          id
          number
          firstName
          lastName
          position {
            id
            code
            name
          }
        }
        currency {
          id
          code
        }
        periodFrom
        periodTo
        total
      }
      count
    }
  }
`;

export const GetCommissionPeriodListQuery: any = gql`
  query commissionPeriodList {
    commissionPeriodList {
      periodFrom
    }
  }
`;

export const GetCommissionQuery: any = gql`
  query commission($where: CommissionWhereInput!) {
    commission(where: $where) {
      id
      consultant {
        id
        avatarFile {
          id
          storageLink
        }
        number
        firstName
        lastName
        position {
          id
          code
          name
        }
        permanentAddress
        companyName
        companyIC
        companyICDPH
        companyDIC
        permanentAddress
        bankIBAN
        bankSWIFT
      }
      currency {
        id
        code
      }
      items {
        currency {
          id
          code
        }
        payDate
        registerConsultant {
          id
          number
          avatarFile {
            id
            storageLink
          }
          firstName
          lastName
        }
        registerConsultantPosition {
          id
          code
          name
        }
        directSubordinateConsultant {
          id
          number
          avatarFile {
            id
            storageLink
          }
          firstName
          lastName
        }
        directSubordinateConsultantPosition {
          id
          code
          name
        }
        amountPerPoint
        points
        amount
      }
      periodFrom
      periodTo
      total
    }
  }
`;

export const CalculateCommissionMutation: any = gql`
  mutation calculateCommission($data: CalculateCommissionInput) {
    calculateCommission(data: $data)
  }
`;

// export const CreateCommissionMutation: any = gql`
//   mutation createCommission($data: CreateOrUpdateCommissionInput) {
//     createCommission(data: $data) {
//       id
//     }
//   }
// `;

// export const UpdateCommissionMutation: any = gql`
//   mutation updateCommission($id: ID!, $data: CreateOrUpdateCommissionInput) {
//     updateCommission(id: $id, data: $data) {
//       id
//     }
//   }
// `;

export const DeleteCommissionMutation: any = gql`
  mutation deleteCommission($id: ID!) {
    deleteCommission(id: $id)
  }
`;
