import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  IconButton } from '_template';
import { selectLoggedUser } from 'stores/loggedUserSlice';
import useAuth from 'hooks/useAuth';

const UserAccount = () => {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const loggedUser = useSelector(selectLoggedUser);
  const { signOut } = useAuth();

  const toggleMenu = (event: any) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = () => {
    signOut();
    navigate('/auth/login');
  };

  return (
    <React.Fragment>
      <Tooltip title={loggedUser?.email}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="small"
          sx={{
            "& svg": {
              width: '24px',
              height: '24px',
            },
          }}
        >
          <Avatar
            sx={{
              width: '32px',
              height: '32px',
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        transitionDuration={0}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>
          <Trans>HEADER.PROFILE</Trans>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <Trans>HEADER.LOGOUT</Trans>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserAccount;
