import i18next from 'i18next';
import { displayAlert } from '_template';

export const notifySaveError = (error: any) => {
  const errorMessage = error.message
    .replace('GraphQL error: ', '');
  if (i18next.t(`ERROR.${errorMessage}`) !== `ERROR.${errorMessage}`) {
    displayAlert(i18next.t(`ERROR.${errorMessage}`));
  } else {
    displayAlert(i18next.t(`${errorMessage}`));
  }
};
