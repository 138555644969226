import gql from 'graphql-tag';

export const GetBankAccountListQuery: any = gql`
  query bankAccountList($limit: LimitsInput, $orderBy: OrderByInput) {
    bankAccountList(limit: $limit, orderBy: $orderBy) {
      rows {
        id
        name
        bankType
        accountNumber
        bankCode
        iban
        bic
        published
      }
      count
    }
  }
`;

export const GetBankAccountQuery: any = gql`
  query bankAccount($where: BankAccountWhereInput!) {
    bankAccount(where: $where) {
      id
      name
      bankType
      accountNumber
      bankCode
      iban
      bic
      authorizationProperty
      published
    }
  }
`;

export const CreateBankAccountMutation: any = gql`
  mutation createBankAccount($data: CreateOrUpdateBankAccountInput) {
    createBankAccount(data: $data) {
      id
    }
  }
`;

export const UpdateBankAccountMutation: any = gql`
  mutation updateBankAccount($id: ID!, $data: CreateOrUpdateBankAccountInput) {
    updateBankAccount(id: $id, data: $data) {
      id
    }
  }
`;

export const DeleteBankAccountMutation: any = gql`
  mutation deleteBankAccount($id: ID!) {
    deleteBankAccount(id: $id)
  }
`;
