import { useTranslation } from 'react-i18next';
import { SETTINGS } from 'constants/settings';
import SettingsForm from 'components/SettingsForm';
import TextField from 'components/TextField';
import { Grid } from '@mui/material';
import NumberField from 'components/NumberField';

const NumberSetting = () => {
  const { t } = useTranslation();

  return (
    <SettingsForm>
      {({ handleSubmit }) => (
        <>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.INVOICE_NUMBER_PREFIX}
                label={t('TAX_DOCUMENT.SETTING.INVOICE_NUMBERS_PREFIX')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberField
                name={SETTINGS.TAX_DOCUMENT.INVOICE_NUMBER_LENGTH}
                label={t('TAX_DOCUMENT.SETTING.INVOICE_NUMBERS_LENGTH')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
          </Grid>
        </>
      )}
    </SettingsForm>
  );
};

export default NumberSetting;
