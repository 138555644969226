import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { MenuItem, SelectProps } from '_template';
import { GetCurrencyListQuery } from 'modules/Currency/query';
import Select from 'components/Select';

type CurrencySelectProps = Omit<SelectProps, 'onChange'> & {
  onChange?: (e: React.ChangeEvent<any>, currency: GQL.ICurrency | null) => void;
};

const CurrencySelect = (props: CurrencySelectProps) => {
  const formikContext = useFormikContext() || {};

  const controlValue = props.value || (formikContext?.values && (formikContext as any)?.values[props.name!]);

  const { data } = useQuery(GetCurrencyListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (props.required && !controlValue) {
      const defaultCurrency = data?.currencyList?.rows?.find(({ primary }: GQL.ICurrency) => primary);
      if (defaultCurrency) {
        onChange({
          target: {
            name: props.name,
            value: defaultCurrency.id,
          },
        } as any);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const onChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    const currency: GQL.ICurrency = data?.currencyList?.rows?.find((c: GQL.ICurrency) => c.id === value);

    (props.onChange || formikContext?.handleChange)(
      {
        ...e,
        target: {
          ...e.target,
          value: value,
        },
      },
      currency || null
    );
  };

  return (
    <Select {...props} onChange={onChange}>
      {data?.currencyList?.rows?.map((currency: GQL.ICurrency) => (
        <MenuItem key={currency.id} value={currency.id}>
          {currency.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencySelect;
