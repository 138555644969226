import React from 'react';
import AppLayout from 'components/AppLayout';
import PaymentList from '../containers/PaymentList';
import PaymentDetail from '../containers/PaymentDetail';

const routes = {
  path: 'payment',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <PaymentList />,
    },
    {
      path: 'detail/:recordId?',
      element: <PaymentDetail />,
    },
  ],
};

export default routes;
