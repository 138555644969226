import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider, Typography } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import { RIGHTS } from 'constants/rights';
import LimitedAccess from 'components/LimitedAccess';
import Helmet from 'components/Helmet';
import { ROUTE } from 'constants/route';
import { useUserAccess } from 'hooks/useUserAccess';

const SettingRoute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAccess } = useUserAccess();

  useEffect(() => {
    if (getAccess(RIGHTS.SETTING.ACCESS)) {
      navigate(ROUTE.SETTING_BASIC);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet title={t<string>('SETTING.MODEL_TITLE')} />
      <ContentHeader
        title={t('SETTING.MODEL_TITLE')}
        breadcrumbs={<Breadcrumbs section={t('SETTING.MODEL_TITLE')} />}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.SETTING.ACCESS}>
        <Typography>Obsah se připravuje</Typography>
      </LimitedAccess>
    </>
  );
};

export default SettingRoute;
