import { useQuery } from '@apollo/client';
import useAuth from 'hooks/useAuth';
import { GetLoggedUserQuery } from 'modules/User/query';
import React, { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthToken } from 'services/AuthToken';

interface AuthProps {
  children: ReactNode;
}

const Auth = ({ children }: AuthProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const authToken = AuthToken.getToken();

  const { loading, error, data } = useQuery(GetLoggedUserQuery, {
    variables: {
      token: authToken,
    },
  });

  const logout = () => {
    auth.signOut();
    if (pathname !== '/auth/login') {
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    if (!auth.isLogged()) {
      logout();
    }
    // eslint-disable-next-line
  }, [auth, pathname]);

  useEffect(() => {
    if (!loading && !data?.loggedUser) {
      logout();
    }
    // eslint-disable-next-line
  }, [error, loading, data]);

  return <>{children}</>;
};

export default Auth;
