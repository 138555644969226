import { ModuleRightType } from 'constants/rights';

export const bankAccountRights: ModuleRightType = {
  BANK_ACCOUNT: {
    LIST: 'BANK_ACCOUNT.LIST',
    ADD: 'BANK_ACCOUNT.ADD',
    EDIT: 'BANK_ACCOUNT.EDIT',
    DELETE: 'BANK_ACCOUNT.DELETE',
  },
};
