import React from 'react';
import AppLayout from 'components/AppLayout';
import PresentationTypeList from '../containers/PresentationTypeList';
import PresentationTypeDetail from '../containers/PresentationTypeDetail';

const routes = {
  path: 'presentationType',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <PresentationTypeList />,
    },
    {
      path: 'detail/:recordId?',
      element: <PresentationTypeDetail />,
    },
  ],
};

export default routes;
