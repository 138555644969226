import { ModuleRightType } from 'constants/rights';

export const currencyRights: ModuleRightType = {
  CURRENCY: {
    LIST: 'CURRENCY.LIST',
    ADD: 'CURRENCY.ADD',
    EDIT: 'CURRENCY.EDIT',
    DELETE: 'CURRENCY.DELETE',
  },
};
