import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Actions from './components/Actions';
import PositionTable from './components/PositionTable';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';
import Helmet from 'components/Helmet';

const PositionList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('POSITION.MODEL_TITLE')} />
      <ContentHeader
        title={t('POSITION.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={<Breadcrumbs section={t('POSITION.MODEL_TITLE')} />}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.POSITION.LIST}>
        <PositionTable />
      </LimitedAccess>
    </>
  );
};

export default PositionList;
