import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '_template';
import { SettingRouteType, settingRoutes } from 'constants/settings';

const drawerWidth = 258;

const SideMenu = () => {
  const { pathname } = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        boxShadow: 'none',
        border: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          position: 'inherit',
          boxSizing: 'border-box',
          boxShadow: 'none',
          border: 0,
        },
      }}
    >
      <Box sx={{ overflow: 'auto', minHeight: 300 }}>
        <List>
          {settingRoutes.map((route: SettingRouteType) => (
            <ListItem key={route.path} disablePadding>
              <ListItemButton component={Link} to={route.path} selected={pathname === route.path}>
                <ListItemIcon sx={{ minWidth: '38px' }}>
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideMenu;
