import React, { ReactElement, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import TuneIcon from '@mui/icons-material/Tune';
import SettingBasic from 'modules/Setting/containers/Basic';
import { settingRouteConsultant } from 'modules/Consultant/constants/settings';
// import { settingRoutePosition } from 'modules/Position/constants/settings';
import { settingRouteRegistration } from 'modules/Registration/constants/settings';
import { settingRouteTaxDocument } from 'modules/TaxDocument/constants/settings';
import { ROUTE } from './route';

export const SETTINGS = {
  PROJECT_NAME: 'PROJECT_NAME',
  // TODO: extract to consultant constants
  CONSULTANT: {
    NUMBER_PREFIX: 'CONSULTANT_NUMBER_PREFIX',
    NUMBER_LENGTH: 'CONSULTANT_NUMBER_LENGTH',
    COUNT_FROM: 'CONSULTANT_COUNT_FROM',
  },
  // TODO: extract to registration constants
  REGISTRATION: {
    MODE: 'REGISTRATION_MODE',
  },
  // TODO: extract to taxDocument module
  TAX_DOCUMENT: {
    COMPANY_NAME: 'TAX_DOCUMENT_COMPANY_NAME',
    STREET: 'TAX_DOCUMENT_STREET',
    HOUSE_NUMBER: 'TAX_DOCUMENT_HOUSE_NUMBER',
    ZIP_CODE: 'TAX_DOCUMENT_ZIP_CODE',
    CITY: 'TAX_DOCUMENT_CITY',
    COUNTRY: 'TAX_DOCUMENT_COUNTRY',
    IC: 'TAX_DOCUMENT_IC',
    DIC: 'TAX_DOCUMENT_DIC',
    ICDPH: 'TAX_DOCUMENT_ICDPH',
    VAT_PAYER: 'TAX_DOCUMENT_VAT_PAYER',
    COMPANY_DESCRIPTION: 'TAX_DOCUMENT_COMPANY_DESCRIPTION',
    BANK_NAME: 'TAX_DOCUMENT_BANK_NAME',
    IBAN: 'TAX_DOCUMENT_IBAN',
    SWIFT: 'TAX_DOCUMENT_SWIFT',
    INVOICE_NUMBER_PREFIX: 'INVOICE_NUMBER_PREFIX',
    INVOICE_NUMBER_LENGTH: 'INVOICE_NUMBER_LENGTH'
  },
};

export interface SettingRouteType {
  path: string;
  label: ReactNode;
  icon: ReactElement;
  component: ReactElement;
  priority?: number;
}

export const settingRoutes: SettingRouteType[] = [
  {
    path: ROUTE.SETTING_BASIC,
    label: <Trans>SETTINGS.BASIC</Trans>,
    icon: <TuneIcon />,
    component: <SettingBasic />,
  },
  ...settingRouteTaxDocument,
  ...settingRouteRegistration,
  ...settingRouteConsultant,
  // ...settingRoutePosition,
];
