import React from 'react';
import AppLayout from 'components/AppLayout';
import UserList from '../containers/UserList';
import UserDetail from '../containers/UserDetail';

const routes = {
  path: 'user',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <UserList />,
    },
    {
      path: 'detail/:recordId?',
      element: <UserDetail />,
    },
  ],
};

export default routes;
