import React from 'react';
import { useFormikContext } from 'formik';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField, TextFieldProps } from '_template';

type NumberFieldProps = TextFieldProps &
  NumericFormatProps & {
    withoutFormik?: boolean;
    withoutHelperText?: boolean;
  };

const NumberField = ({ withoutFormik = false, onChange, ...props }: NumberFieldProps) => {
  // eslint-disable-next-line
  const formikContext = withoutFormik ? null : useFormikContext();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          name: props.name,
          value: parseFloat(value),
        },
      } as any);
    } else if (formikContext) {
      formikContext?.setFieldValue(props.name!, parseFloat(value));
    }
  };

  const controlValue =
    props.value || (props.name && formikContext?.values && (formikContext?.values as any)[props.name]);

  return (
    <NumericFormat
      allowNegative={false}
      allowedDecimalSeparators={[',']}
      thousandSeparator={' '}
      decimalScale={2}
      decimalSeparator={','}
      value={controlValue}
      {...props}
      customInput={TextField}
      onValueChange={(values, sourceInfo) => {
        onInputChange({
          ...sourceInfo?.event,
          target: {
            ...sourceInfo?.event?.target,
            // @ts-ignore
            value: values.floatValue,
          },
        });
      }}
    />
  );
};

export default NumberField;
