import React from 'react';
import { Plus } from 'react-feather';
import Button, { ButtonProps } from '../Button';

const ButtonAdd = ({ children, ...props }: ButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    startIcon={<Plus />}
    {...props}
  >
    {children}
  </Button>
);

export default ButtonAdd;
