import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider, Loader } from '_template';
import NotFound from '_template/pages/NotFound';
import Breadcrumbs from 'components/Breadcrumbs';
import { GetUserGroupQuery } from 'modules/UserGroup/query';
import { ROUTE } from 'constants/route';
import UserGroupForm from './UserGroupForm';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';
import Helmet from 'components/Helmet';

const UserGroupDetail = () => {
  const { t } = useTranslation();
  const { recordId } = useParams();

  const userGroupQuery = useQuery(GetUserGroupQuery, {
    variables: {
      where: {
        id: recordId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { loading, error } = userGroupQuery;

  const data = userGroupQuery?.data?.userGroup;

  const isNew = recordId === 'new';

  if (loading) {
    return <Loader />;
  }

  if (error && !isNew) {
    return <NotFound />;
  }

  const title = isNew ? t('USER_GROUP.NEW') : t('USER_GROUP.EDIT');

  return (
    <>
      <Helmet title={title} />
      <ContentHeader title={t('USER_GROUP.MODEL_TITLE')} />
      <Breadcrumbs
        links={{
          [ROUTE.USER_GROUP_LIST]: t('USER_GROUP.MODEL_TITLE'),
        }}
        section={title}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={isNew ? RIGHTS.USER_GROUP.ADD : RIGHTS.USER_GROUP.EDIT}>
        <UserGroupForm recordId={recordId} data={data} />
      </LimitedAccess>
    </>
  );
};

export default UserGroupDetail;
