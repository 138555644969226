import { useTranslation } from 'react-i18next';
import { SETTINGS } from 'constants/settings';
import SettingsForm from 'components/SettingsForm';
import TextField from 'components/TextField';
import { Grid } from '@mui/material';

const BankAccountSetting = () => {
  const { t } = useTranslation();

  return (
    <SettingsForm>
      {({ handleSubmit }) => (
        <>
          <TextField
            name={SETTINGS.TAX_DOCUMENT.BANK_NAME}
            label={t('TAX_DOCUMENT.SETTING.BANK_NAME')}
            withoutHelperText
            onBlur={() => handleSubmit()}
          />
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.IBAN}
                label={t('TAX_DOCUMENT.SETTING.IBAN')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.SWIFT}
                label={t('TAX_DOCUMENT.SETTING.SWIFT')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
          </Grid>
        </>
      )}
    </SettingsForm>
  );
};

export default BankAccountSetting;
