import csCZ from './translation.json';
// import { cs as availabilityCs } from 'modules/Availability/i18n';
import { cs as bankAccountCs } from 'modules/BankAccount/i18n';
// import { cs as categoryCs } from 'modules/Category/i18n';
import { cs as commissionCs } from 'modules/Commission/i18n';
import { cs as consultantCs } from 'modules/Consultant/i18n';
import { cs as currencyCs } from 'modules/Currency/i18n';
// import { cs as customerCs } from 'modules/Customer/i18n';
// import { cs as deliveryMethodCs } from 'modules/DeliveryMethod/i18n';
import { cs as emailCs } from 'modules/Email/i18n';
import { cs as emailTemplateCs } from 'modules/EmailTemplate/i18n';
import { cs as fileCs } from 'modules/File/i18n';
// import { cs as languageCs } from 'modules/Language/i18n';
// import { cs as orderCs } from 'modules/Order/i18n';
// import { cs as orderLabelCs } from 'modules/OrderLabel/i18n';
// import { cs as orderStatusCs } from 'modules/OrderStatus/i18n';
// import { cs as paymentMethodCs } from 'modules/PaymentMethod/i18n';
// import { cs as taxDocumentCs } from 'modules/TaxDocument/i18n';
// import { cs as unitCs } from 'modules/Unit/i18n';
import { cs as paymentCs } from 'modules/Payment/i18n';
import { cs as positionCs } from 'modules/Position/i18n';
import { cs as presentationTypeCs } from 'modules/PresentationType/i18n';
import { cs as registrationCs } from 'modules/Registration/i18n';
import { cs as settingCs } from 'modules/Setting/i18n';
import { cs as userCs } from 'modules/User/i18n';
import { cs as userGroupCs } from 'modules/UserGroup/i18n';
import { cs as taxDocumentCs } from 'modules/TaxDocument/i18n';
// import { cs as vatCs } from 'modules/Vat/i18n';

const csTranslation = {
  ...csCZ,
  // ...availabilityCs,
  ...bankAccountCs,
  // ...categoryCs,
  ...commissionCs,
  ...consultantCs,
  ...currencyCs,
  // ...customerCs,
  // ...deliveryMethodCs,
  ...emailCs,
  ...emailTemplateCs,
  ...fileCs,
  // ...languageCs,
  // ...orderCs,
  // ...orderLabelCs,
  // ...orderStatusCs,
  // ...paymentMethodCs,
  // ...taxDocumentCs,
  // ...unitCs,
  ...paymentCs,
  ...positionCs,
  ...presentationTypeCs,
  ...registrationCs,
  ...settingCs,
  ...userCs,
  ...userGroupCs,
  ...taxDocumentCs,
  // ...vatCs,
};

export default csTranslation;
