import { Trans } from 'react-i18next';
import React, { ReactNode } from 'react';
import { FormGroup, Grid } from '@mui/material';
import Button from '../Button';
import ButtonSubmit from '../ButtonSubmit';

interface FormProps {
  disabled?: boolean;
  disableButtons?: boolean;
  submitButtonDisabled?: boolean;
  submitButtonLoading?: boolean;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
  children?: ReactNode;
}

const Form: React.FC<FormProps> = ({
  disabled = false,
  disableButtons = false,
  submitButtonDisabled = false,
  submitButtonLoading = false,
  onSubmit,
  onCancel,
  children
}) => (
  <form
    noValidate
    method="post"
    className="form"
    onSubmit={onSubmit}
  >
    {children}
    {disableButtons !== true && (
      <Grid container>
        <Grid item sx={{ ml: 'auto', mt: 4 }}>
          <FormGroup row sx={{ "& button": { ml: 2 } }}>
            <ButtonSubmit
              loading={submitButtonLoading}
              variant="contained"
              color="primary"
              disabled={submitButtonDisabled || submitButtonLoading}
            >
              <Trans>FORM.SAVE</Trans>
            </ButtonSubmit>
            {/* <ButtonSubmit variant="contained" color="primary" disabled={submitButtonDisabled === true}>
              <Trans>FORM.SAVE_AND_CONTINUE</Trans>
            </ButtonSubmit> */}
            <Button variant="outlined" onClick={onCancel}>
              <Trans>FORM.CANCEL</Trans>
            </Button>
          </FormGroup>
        </Grid>
      </Grid>
    )}
  </form>
);

export default Form;
