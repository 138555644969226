import React from 'react';
import { ButtonProps as MuiButtonProps, default as MuiButton } from '@mui/material/Button';
import { default as MuiLink } from '@mui/material/Link';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

export type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

export type ButtonProps = MuiButtonProps & {
  to?: string;
  loading?: boolean;
};

const Button = ({ children, to, loading, ...props }: ButtonProps) => {
  const loadingColor = props.color === 'primary'
    ? 'white'
    : 'primary';

  const buttonProps = {
    ...props,
    startIcon: loading && props.startIcon
      ? <CircularProgress size={20} sx={{ color: loadingColor }} />
      : props.startIcon,
    endIcon: loading && props.endIcon
      ? <CircularProgress size={20} sx={{ color: loadingColor }} />
      : props.endIcon,
  };

  if (to && !props.disabled) {
    return (
      <MuiLink
        component={Link}
        to={to}
        sx={{
          "&:hover, &:active": {
            textDecoration: 'none !important',
          },
        }}
      >
        <MuiButton {...buttonProps}>
          {children}
        </MuiButton>
      </MuiLink>
    );
  }

  return (
    <MuiButton {...buttonProps}>
      {children}
    </MuiButton>
  );
};

export default Button;
