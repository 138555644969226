import React, { ReactNode } from 'react';
import AlertLimitedAccess from 'components/AlertLimitedAccess';
import { useUserAccess } from 'hooks/useUserAccess';

interface LimitedAccessProps {
  right: string;
  children: ReactNode;
}

const LimitedAccess = ({ right, children }: LimitedAccessProps) => {
  const { getAccess } = useUserAccess();

  if (!getAccess(right)) {
    return (
      <AlertLimitedAccess />
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default LimitedAccess;
