import { ModuleRightType } from 'constants/rights';

export const paymentRights: ModuleRightType = {
  PAYMENT: {
    LIST: 'PAYMENT.LIST',
    ADD: 'PAYMENT.ADD',
    EDIT: 'PAYMENT.EDIT',
    DELETE: 'PAYMENT.DELETE',
  },
};
