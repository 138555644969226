import { useQuery } from '@apollo/client';
import { ContentHeader, Divider, Loader } from '_template';
import NotFound from '_template/pages/NotFound';
import Breadcrumbs from 'components/Breadcrumbs';
import Helmet from 'components/Helmet';
import LimitedAccess from 'components/LimitedAccess';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface ContainerDetailWrapperProps {
  query: any;
  parseQueryData: (queryResponse: any) => any;
  languagePrefix: string;
  routeList: string;
  rights: { [key: string]: string };
  children: (data: any) => ReactNode;
};

const ContainerDetailWrapper = ({
  query,
  parseQueryData,
  languagePrefix,
  routeList,
  rights,
  children
}: ContainerDetailWrapperProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();

  const { data, loading, error } = useQuery(query, {
    variables: {
      where: {
        id: recordId,
      }
    },
    fetchPolicy: 'network-only',
  });

  const isNew = recordId === 'new';

  if (loading) {
    return <Loader />;
  }

  if (error && !isNew) {
    return <NotFound />;
  }

  const title = isNew ? t(`${languagePrefix}.NEW`) : t(`${languagePrefix}.EDIT`);

  return (
    <>
      <Helmet title={title} />
      <ContentHeader
        title={t(`${languagePrefix}.MODEL_TITLE`)}
        breadcrumbs={(
          <Breadcrumbs
            links={{
              [routeList]: t(`${languagePrefix}.MODEL_TITLE`)
            }}
            section={title}
          />
        )}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={isNew ? rights['ADD'] : rights['EDIT']}>
        {children({
          recordId,
          data: parseQueryData(data),
        })}
      </LimitedAccess>
    </>
  );
};

export default ContainerDetailWrapper;
