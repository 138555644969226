import React, { useState } from 'react';
import { Alert, Box, Typography } from '_template';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CodeDocumentations from './CodeDocumentations';

const BodyHelp = () => {
  const [opened, setOpened] = useState(false);

  return (
    <Alert severity="info" sx={{ mb: 4, py: 3 }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
        onClick={() => setOpened((prev) => !prev)}
      >
        <Typography variant="h6">Dokumentace zástupných kódů</Typography>{' '}
        {opened ? (
          <KeyboardArrowUpIcon fontSize="small" sx={{ ml: 2 }} />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" sx={{ ml: 2 }} />
        )}
      </Box>
      {opened && <CodeDocumentations />}
    </Alert>
  );
};

export default BodyHelp;
