import React from 'react';
import { Box, TextFieldProps } from '_template';
import AutoComplete from 'components/AutoComplete';
import { bankAccountTypeName } from 'modules/BankAccount/constants/bankAccountType';

type BankAccountTypeSelectProps = TextFieldProps & {
  readOnly?: boolean;
};

const BankAccountTypeSelect = (props: BankAccountTypeSelectProps) => {
  const options = Object.keys(bankAccountTypeName).map((type: string) => ({
    label: bankAccountTypeName[type],
    value: type,
  }));

  return (
    <AutoComplete
      {...props}
      options={options}
      autocompleteProps={{
        disableClearable: true,
        renderOption: (props: any, option: any) => (
          <Box component='li' {...props}>
            {option.label}
          </Box>
        ),
      }}
    />
  );
};

export default BankAccountTypeSelect;
