import React from 'react';
import { Check, X } from 'react-feather';

interface CheckIconProps {
  value: boolean;
  withColor?: boolean;
  size?: string | number;
}

const CheckIcon = ({ value, withColor = false, size }: CheckIconProps) => {
  if (value) {
    return <Check color={withColor ? 'green' : undefined} size={size} />;
  }

  return <X color={withColor ? 'red' : undefined} size={size} />;
};

export default CheckIcon;
