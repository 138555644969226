import React, { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import { default as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material/Radio';

interface RadioProps extends MuiRadioProps {
  label: React.ReactElement | string;
  disabled?: boolean;
};

const Radio = ({
  label,
  disabled = false,
  ...props
}: RadioProps) => {
  const formikContext = useFormikContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name, value } = e.target as HTMLInputElement;
    if (formikContext) {
      formikContext.setFieldValue(name, value);
    }
    if (props.onChange) {
      props.onChange(e, checked);
    }
  };

  return (
    <FormControlLabel
      control={<MuiRadio {...props} onChange={onChange} />}
      label={label}
      disabled={disabled}
    />
  );
};

export default Radio;
