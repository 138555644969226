import React from 'react';
import { GetPresentationTypeQuery } from 'modules/PresentationType/query/PresentationType';
import ContainerDetailWrapper from 'components/ContainerDetailWrapper';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';
import PresentationTypeForm from './PresentationTypeForm';

const PresentationTypeDetail = () => (
  <ContainerDetailWrapper
    query={GetPresentationTypeQuery}
    parseQueryData={(data) => data?.presentationType || null}
    languagePrefix="PRESENTATION_TYPE"
    routeList={ROUTE.PRESENTATION_TYPE_LIST}
    rights={RIGHTS.PRESENTATION_TYPE}
  >
    {({ recordId, data }) => (
      <PresentationTypeForm
        recordId={recordId}
        data={{
          ...data,
          emailTemplateId: data?.emailTemplate?.id ?? null,
          confirmEmailTemplateId: data.confirmEmailTemplate?.id ?? null,
          published: data ? data.published : true,
        }}
      />
    )}
  </ContainerDetailWrapper>
);

export default PresentationTypeDetail;
