import React, { createContext, useEffect, useState } from 'react';

import { THEMES } from '../theme/constants';

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: (theme: THEMES) => {},
};

const ThemeContext = createContext(initialState);

interface ThemeProviderProps {
  children: any;
}

function ThemeContextProvider({ children }: ThemeProviderProps) {
  const [theme, _setTheme] = useState(initialState.theme);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme) {
      _setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const setTheme = (theme: THEMES) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    _setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContextProvider, ThemeContext };
