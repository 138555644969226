import React from 'react';
import { useMutation } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { Confirm, MenuItem, displayAlert, displaySuccess } from '_template';
import { ResendPaymentInstructionsMessageMutation } from 'modules/Consultant/query';
import { DropDownButtonSendActionsProps } from './DropDownButtonSendActions';

const MenuItemSendPaymentInstructions = (props: DropDownButtonSendActionsProps) => {
  const { t } = useTranslation();
  const [activateMutation] = useMutation(ResendPaymentInstructionsMessageMutation);

  const onClick = () => {
    Confirm({
      title: t('CONSULTANT.RESENT_MESSAGE.PAYMENT_INSTRUCTIONS.REALLY_TO_ACTIVATE'),
      labelSubmit: t('GENERAL.YES'),
      labelSubmitVariant: 'primary',
      labelCancel: t('GENERAL.NO'),
    })
      .then(() => {
        activateMutation({
          variables: {
            consultantId: props.consultant.id,
          },
          refetchQueries: [
            'consultantDetail',
          ],
        })
        .then(() => {
          displaySuccess(t('CONSULTANT.MESSAGE_SEND_SUCCESS'));
          props.refetch();
        })
        .catch(() => displayAlert(t('CONSULTANT.MESSAGE_SEND_FAILED')));
      })
      .catch(() => {});
  };

  return (
    <MenuItem sx={{ fontSize: 12 }} onClick={() => onClick()}>
      <Trans>CONSULTANT.RESENT_MESSAGE.PAYMENT_INSTRUCTIONS.LABEL</Trans>
    </MenuItem>
  );
};

export default MenuItemSendPaymentInstructions;