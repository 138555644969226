import { ModuleRightType } from 'constants/rights';

export const consultantRights: ModuleRightType = {
  CONSULTANT: {
    LIST: 'CONSULTANT.LIST',
    ADD: 'CONSULTANT.ADD',
    EDIT: 'CONSULTANT.EDIT',
    DELETE: 'CONSULTANT.DELETE',
  },
};
