import { ModuleRightType } from 'constants/rights';

export const userRights: ModuleRightType = {
  USER: {
    LIST: 'USER.LIST',
    ADD: 'USER.ADD',
    EDIT: 'USER.EDIT',
    DELETE: 'USER.DELETE',
  },
};
