import * as React from 'react';
import moment from 'moment';

interface DateTimeProps {
  value?: string | null;
  hideSameYear?: boolean;
  onlyDate?: boolean;
}

class DateTime extends React.Component<DateTimeProps> {

  static formatDateTime(date: any, hideSameYear: boolean = false) {
    const format = (hideSameYear && moment(new Date(date)).format('Y') === moment().format('Y')
      ? 'D.M. H:mm'
      : 'D.M.Y H:mm');
    return moment(new Date(date)).format(format);
  }

  static formatDate(date: any, hideSameYear: boolean = false) {
    const format = (hideSameYear && moment(new Date(date)).format('Y') === moment().format('Y')
      ? 'D.M.'
      : 'D.M.Y');
    return moment(new Date(date)).format(format);
  }

  public render() {
    if (!this.props.value) {
      return null;
    }

    if (this.props.onlyDate === true) {
      return (
        DateTime.formatDate(this.props.value, this.props.hideSameYear)
      );
    }

    return (
      <span>{DateTime.formatDateTime(this.props.value, this.props.hideSameYear)}</span>
    );
  }
};

export default DateTime;