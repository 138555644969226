import * as Yup from 'yup';
import React from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, FormLabel } from '_template';
import { CreateUserGroupMutation, UpdateUserGroupMutation } from 'modules/UserGroup/query';
import { handleSaveData } from 'helpers/data/handleSaveData';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import { UserGroupData } from '../../interfaces/userGroup';
import UserGroupRights from './UserGroupRights';

interface UserGroupFormProps {
  recordId?: string;
  data?: UserGroupData;
}

const UserGroupForm = ({ recordId, data }: UserGroupFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreateUserGroupMutation);
  const [updateMutation] = useMutation(UpdateUserGroupMutation);

  const onCancel = () => navigate(ROUTE.USER_GROUP_LIST);

  const validationSchema = Yup.object({
    name: Yup.string().required(t<string>(`USER_GROUP.NAME_REQUIRED`)),
  });

  const onSubmit = (newData: UserGroupData) => {
    const variables = {
      id: recordId,
      data: {
        name: newData.name,
        rights: newData.rights?.includes('ALL') ? ['ALL'] : newData.rights,
      },
    };

    handleSaveData(
      (isNew ? createMutation : updateMutation),
      variables,
      onCancel
    );
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          onCancel={onCancel}
        >
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    fullWidth
                    name="name"
                    label={t('USER_GROUP.NAME')}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <FormLabel>{t('USER_GROUP.RIGHTS_SETTING')}</FormLabel>
                  <UserGroupRights />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default UserGroupForm;
