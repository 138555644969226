import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface SettingState {
  settingList: GQL.ISetting[] | null;
}

const initialState: SettingState = {
  settingList: null,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setSettingList: (state, action: PayloadAction<GQL.ISetting[]>) => {
      state.settingList = action.payload;
    },
  },
});

export const {
  setSettingList
} = settingSlice.actions;

export const selectSettingList = (state: RootState) => state.setting.settingList;

export default settingSlice.reducer;
