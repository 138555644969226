import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import { useSetting } from 'hooks/useSetting';
import { SETTINGS } from 'constants/settings';
// @ts-ignore
import favicon from 'assets/favicon.png';

interface HelmetProps {
  title?: string;
}

const Helmet = ({ title }: HelmetProps) => {
  const { getSetting } = useSetting();

  const projectName = getSetting(SETTINGS.PROJECT_NAME)?.value || 'MLM';

  return (
    <ReactHelmet>
      <title>{projectName} - Administrace{title ? ` - ${title}` : ''}</title>
      <link rel="icon" type="image/x-icon" href={favicon} />
    </ReactHelmet>
  );
};

export default Helmet;
