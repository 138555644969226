import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import {
  TableContainer,
  Table,
  TableColumn,
  TableActions,
  Typography,
  FilterContainer,
  Card,
} from '_template';
import ButtonDelete from 'components/ButtonDelete';
import { ROUTE } from 'constants/route';
import Link from 'components/Link';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { RIGHTS } from 'constants/rights';
import {
  DeleteCommissionMutation,
  GetCommissionListQuery,
} from 'modules/Commission/query';
import Filter from './Filter';

const CommissionTable = () => {
  const { t } = useTranslation();
  const selectPeriodFromStorage = localStorage.getItem('commissionPeriod');
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(
    selectPeriodFromStorage || null
  );
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs(
    'commission',
    {
      sortField: 'primary',
      sortBy: 'asc',
    }
  );

  let where: any = {};
  if (selectedPeriod) {
    where = {
      periodFrom: moment(selectedPeriod).format('YYYY-MM-DD'),
      periodTo: moment(selectedPeriod).endOf('month').format('YYYY-MM-DD'),
    };
  }

  const commissionListQuery = useQuery(GetCommissionListQuery, {
    variables: {
      where,
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, error } = commissionListQuery;

  const refetch = () => commissionListQuery.refetch();

  const [deleteRegistration] = useMutation(DeleteCommissionMutation);

  const handleChangeSelectedPeriod = (period: string) => {
    localStorage.setItem('commissionPeriod', period);
    setSelectedPeriod(period);
  };

  return (
    <>
      <FilterContainer>
        <Filter
          selected={selectedPeriod}
          onChange={handleChangeSelectedPeriod}
        />
      </FilterContainer>
      {selectedPeriod && (
        <TableContainer component={Card}>
          <Table<GQL.ICommission>
            dataSource={data?.commissionList?.rows}
            recordsCount={data?.commissionList?.count}
            loading={loading}
            error={error}
            {...tableProps}
          >
            <TableColumn
              label={t('COMMISSION.CONSULTANT')}
              dataIndex="consultant"
              render={(row: GQL.ICommission) => (
                <Link
                  component={NavLink}
                  to={ROUTE.COMMISSION_DETAIL.replace(':recordId', row.id)}
                  right={RIGHTS.COMMISSION.EDIT}
                >
                  <Typography>
                    {row.consultant?.number}: {row.consultant?.lastName}{' '}
                    {row.consultant?.firstName}
                  </Typography>
                </Link>
              )}
            />
            <TableColumn
              label={t('COMMISSION.TOTAL')}
              dataIndex="total"
              align="right"
              sx={{
                width: '100px',
              }}
              render={(row: GQL.ICommission) => (
                <Typography>
                  {row.total} {row.currency?.code}
                </Typography>
              )}
            />
            <TableActions
              render={(row: GQL.ICommission) => {
                return (
                  <ButtonDelete
                    message={t('COMMISSION.REALLY_TO_DELETE')}
                    right={RIGHTS.COMMISSION.DELETE}
                    onDelete={() =>
                      deleteRegistration({ variables: { id: row.id } })
                    }
                    refetch={refetch}
                  />
                );
              }}
            />
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CommissionTable;
