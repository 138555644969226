import gql from 'graphql-tag';

export const GetUserGroupListQuery: any = gql`
  query userGroupList($limit: LimitsInput, $orderBy: OrderByInput) {
    userGroupList(limit: $limit, orderBy: $orderBy) {
      rows {
        id
        name
        rights
      }
      count
    }
  }
`;

export const GetUserGroupQuery: any = gql`
  query userGroup($where: UserGroupWhereInput!) {
    userGroup(where: $where) {
      id
      name
      rights
    }
  }
`;

export const CreateUserGroupMutation: any = gql`
  mutation createUserGroup($data: CreateUserGroupInput!) {
    createUserGroup(data: $data) {
      id
    }
  }
`;

export const UpdateUserGroupMutation: any = gql`
  mutation updateUserGroup($id: ID!, $data: UpdateUserGroupInput!) {
    updateUserGroup(id: $id, data: $data) {
      id
    }
  }
`;

export const DeleteUserGroupMutation: any = gql`
  mutation deleteUserGroup($id: ID!) {
    deleteUserGroup(id: $id)
  }
`;
