import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider, Loader } from '_template';
import NotFound from '_template/pages/NotFound';
import Breadcrumbs from 'components/Breadcrumbs';
import { GetRegistrationQuery } from 'modules/Registration/query/Registration';
import { ROUTE } from 'constants/route';
import RegistrationForm from './RegistrationForm';
import { RIGHTS } from 'constants/rights';
import LimitedAccess from 'components/LimitedAccess';
import Helmet from 'components/Helmet';

const RegistrationDetail = () => {
  const { t } = useTranslation();
  const { recordId } = useParams();

  const registrationQuery = useQuery(GetRegistrationQuery, {
    variables: {
      where: {
        id: recordId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { loading, error } = registrationQuery;

  const data = registrationQuery?.data?.registration;

  const isNew = recordId === 'new';

  if (loading) {
    return <Loader />;
  }

  if (error && !isNew) {
    return <NotFound />;
  }

  const title = isNew ? t('REGISTRATION.NEW') : t('REGISTRATION.EDIT');

  return (
    <>
      <Helmet title={title} />
      <ContentHeader title={t('REGISTRATION.MODEL_TITLE')} />
      <Breadcrumbs
        links={{
          [ROUTE.REGISTRATION_LIST]: t('REGISTRATION.MODEL_TITLE'),
        }}
        section={title}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={isNew ? RIGHTS.REGISTRATION.ADD : RIGHTS.REGISTRATION.EDIT}>
        <RegistrationForm
          recordId={recordId}
          data={{
            ...data,
            parentId: data && data.parent ? data.parent.id : null,
            positionId: data && data.position ? data.position.id : null,
            currencyId: data && data.currency ? data.currency.id : null,
          }}
        />
      </LimitedAccess>
    </>
  );
};

export default RegistrationDetail;
