export const parseFloatValue = (value: string | number | null | undefined) => {
  if (typeof value === 'number') {
    value = value.toString();
  }
  if (value) {
    value = value.replace(',', '.');
    return parseFloat(value);
  }

  return value;
};
