import React from 'react';
import { Trans } from 'react-i18next';
import ButtonAdd from 'components/ButtonAdd';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';

const Actions = () => (
  <ButtonAdd
    size="small"
    to={ROUTE.CURRENCY_DETAIL.replace(':recordId', 'new')}
    right={RIGHTS.CURRENCY.ADD}
  >
    <Trans>CURRENCY.NEW</Trans>
  </ButtonAdd>
);

export default Actions;
