import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Divider } from '_template';
import { CreateRegistrationMutation, UpdateRegistrationMutation } from 'modules/Registration/query';
import { RegistrationData } from 'modules/Registration/interfaces/registration';
import { PositionSelect } from 'modules/Position/components';
import { handleSaveData } from 'helpers/data/handleSaveData';
import PhoneField from 'components/PhoneField';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import { CurrencySelect } from 'modules/Currency/components';
import { ConsultantSelect } from 'modules/Consultant/components';

interface RegistrationFormProps {
  recordId?: string;
  data?: RegistrationData;
}

const RegistrationForm = ({
  recordId,
  data
}: RegistrationFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreateRegistrationMutation);
  const [updateMutation] = useMutation(UpdateRegistrationMutation);

  const onCancel = () => navigate(ROUTE.REGISTRATION_LIST);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t<string>(`REGISTRATION.FIRST_NAME_REQUIRED`)),
    lastName: Yup.string().required(t<string>(`REGISTRATION.LAST_NAME_REQUIRED`)),
    email: Yup.string().email(t<string>(`VALIDATION.EMAIL_NOT_VALID`)).required(t<string>(`REGISTRATION.EMAIL_REQUIRED`)),
  });

  const onSubmit = (newData: RegistrationData) => {
    const variables = {
      id: recordId,
      data: {
        parentId: newData.parentId,
        positionId: newData.positionId,
        currencyId: newData.currencyId,
        companyName: newData.companyName,
        companyIC: newData.companyIC,
        companyDIC: newData.companyDIC,
        lastName: newData.lastName,
        firstName: newData.firstName,
        titleBefore: newData.titleBefore,
        titleAfter: newData.titleAfter,
        mobilePrefix: newData.mobilePrefix,
        mobileNumber: newData.mobileNumber,
        email: newData.email,
      },
    };

    handleSaveData(
      (isNew ? createMutation : updateMutation),
      variables,
      onCancel
    );
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          onCancel={onCancel}
        >
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <ConsultantSelect
                    fullWidth
                    required
                    name="parentId"
                    label={t('REGISTRATION.PARENT')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <PositionSelect
                    fullWidth
                    name="positionId"
                    label={t('REGISTRATION.POSITION')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CurrencySelect
                    fullWidth
                    name="currencyId"
                    label={t('REGISTRATION.CURRENCY')}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mb: 4 }} />

              <Grid container spacing={4}>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="titleBefore"
                    label={t('REGISTRATION.TITLE_BEFORE')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    name="firstName"
                    label={t('REGISTRATION.FIRST_NAME')}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    name="lastName"
                    label={t('REGISTRATION.LAST_NAME')}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="titleAfter"
                    label={t('REGISTRATION.TITLE_AFTER')}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <PhoneField
                    name="mobile"
                    label={t(`REGISTRATION.MOBILE`)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    type="email"
                    name="email"
                    label={t(`REGISTRATION.EMAIL`)}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm;
