import React, { ReactNode } from 'react';
import { Grid, Typography } from '_template';

interface SettingValueProps {
  label: string;
  description?: string;
  children?: ReactNode;
}

const SettingValue = ({ label, description, children }: SettingValueProps) => {
  return (
    <Grid container sx={{ py: 1, pb: 3 }}>
      <Grid item xs={12} sx={{ textAlign: { sm: 'left' }, mb: 3 }}>
        <Typography component="strong" sx={{ fontSize: 15 }}>
          {label}
        </Typography>
        {description && <Typography component="small">{description}</Typography>}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default SettingValue;
