import React from 'react';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { MenuItem, SelectProps } from '_template';
import { GetBankAccountListQuery } from 'modules/BankAccount/query';
import Select from 'components/Select';

type BankAccountSelectProps = Omit<SelectProps, 'onChange'> & {
  onChange?: (e: React.ChangeEvent<any>, bankAccount: GQL.IBankAccount | null) => void;
};

const BankAccountSelect = (props: BankAccountSelectProps) => {
  const formikContext = useFormikContext() || {};

  // const controlValue = props.value || (formikContext?.values && (formikContext as any)?.values[props.name!]);

  const { data } = useQuery(GetBankAccountListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  // useEffect(() => {
  //   if (props.required && !controlValue) {
  //     const defaultBankAccount = data?.bankAccountList?.rows?.find(({ primary }: GQL.IBankAccount) => primary);
  //     if (defaultBankAccount) {
  //       onChange({
  //         target: {
  //           name: props.name,
  //           value: defaultBankAccount.id,
  //         },
  //       } as any);
  //     }
  //   }
  // }, [data]);

  const onChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    (props.onChange || formikContext?.handleChange)(e,
      data?.bankAccountList?.rows?.find((c: GQL.IBankAccount) => c.id === value) || null
    );
  };

  return (
    <Select {...props} onChange={onChange}>
      {data?.bankAccountList?.rows?.map((bankAccount: GQL.IBankAccount) => (
        <MenuItem key={bankAccount.id} value={bankAccount.id}>
          {bankAccount.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BankAccountSelect;
