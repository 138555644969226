import { useTranslation } from 'react-i18next';
import { SETTINGS } from 'constants/settings';
import SettingsForm from 'components/SettingsForm';
import TextField from 'components/TextField';
import { Grid } from '@mui/material';
import { Box, Checkbox } from '_template';

const CompanySetting = () => {
  const { t } = useTranslation();

  return (
    <SettingsForm>
      {({ values, handleSubmit }) => (
        <>
          <TextField
            name={SETTINGS.TAX_DOCUMENT.COMPANY_NAME}
            label={t('TAX_DOCUMENT.SETTING.COMPANY_NAME')}
            withoutHelperText
            onBlur={() => handleSubmit()}
          />
          <Grid container spacing={4}>
            <Grid item xs={9}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.STREET}
                label={t('TAX_DOCUMENT.SETTING.STREET')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.HOUSE_NUMBER}
                label={t('TAX_DOCUMENT.SETTING.HOUSE_NUMBER')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.ZIP_CODE}
                label={t('TAX_DOCUMENT.SETTING.ZIP_CODE')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.CITY}
                label={t('TAX_DOCUMENT.SETTING.CITY')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
          </Grid>
          {/* TODO: nahradit za CountrySelect komponenetu */}
          <TextField
            name={SETTINGS.TAX_DOCUMENT.COUNTRY}
            label={t('TAX_DOCUMENT.SETTING.COUNTRY')}
            withoutHelperText
            onBlur={() => handleSubmit()}
          />
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.IC}
                label={t('TAX_DOCUMENT.SETTING.IC')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.DIC}
                label={t('TAX_DOCUMENT.SETTING.DIC')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name={SETTINGS.TAX_DOCUMENT.ICDPH}
                label={t('TAX_DOCUMENT.SETTING.ICDPH')}
                withoutHelperText
                onBlur={() => handleSubmit()}
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }}>
            <Checkbox
              name={SETTINGS.TAX_DOCUMENT.VAT_PAYER}
              label={t('TAX_DOCUMENT.SETTING.VAT_PAYER')}
              checked={values.TAX_DOCUMENT_VAT_PAYER === 'true'}
              onBlur={() => handleSubmit()}
            />
          </Box>
          <TextField
            name={SETTINGS.TAX_DOCUMENT.COMPANY_DESCRIPTION}
            label={t('TAX_DOCUMENT.SETTING.COMPANY_DESCRIPTION')}
            helperText={t('TAX_DOCUMENT.SETTING.COMPANY_DESCRIPTION_HELPER_TEXT')}
            multiline
            rows={4}
            onBlur={() => handleSubmit()}
          />
        </>
      )}
    </SettingsForm>
  );
};

export default CompanySetting;
