import React from 'react';
import { Box, Button, Typography } from '_template';
import { ErrorOutline, Refresh } from '@mui/icons-material';
import { Trans } from 'react-i18next';

interface ErrorProps {
  onRefetch?: () => void;
}

const Error = ({ onRefetch }: ErrorProps) => {
  console.log('error onRefetch', onRefetch);
  return (
    <Box flexDirection="column" textAlign="center">
      <ErrorOutline fontSize="large" />
      <Typography sx={{ mt: 3 }}>
        <Trans>TABLE.SOMETHING_WENT_WRONG</Trans>
      </Typography>
      {!!onRefetch && (
        <Button sx={{ mt: 3 }} onClick={onRefetch}>
          <Refresh sx={{ mr: 2 }} /> <Trans>TABLE.REFRESH</Trans>
        </Button>
      )}
    </Box>
  );
};

export default Error;
