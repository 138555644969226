import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { useMutation } from '@apollo/client';
import { ActivateConsultantMutation } from 'modules/Consultant/query';
import { Confirm, displayAlert, displaySuccess } from '_template';

interface ButtonActivateConsultantProps {
  consultant: GQL.IConsultant;
  refetch: () => void;
}

const ButtonActivateConsultant = ({ consultant, refetch }: ButtonActivateConsultantProps) => {
  const { t } = useTranslation();
  const [activateMutation] = useMutation(ActivateConsultantMutation);

  const onClick = () => {
    Confirm({
      title: t('CONSULTANT.REALLY_TO_ACTIVATE'),
      labelSubmit: t('GENERAL.YES'),
      labelSubmitVariant: 'primary',
      labelCancel: t('GENERAL.NO'),
    })
      .then(() => {
        activateMutation({
          variables: {
            id: consultant.id,
          },
          refetchQueries: [
            'consultantDetail',
          ],
        })
        .then(() => {
          displaySuccess(t('CONSULTANT.ACTIVATE_SUCCESS'));
          refetch();
        })
        .catch(() => displayAlert(t('CONSULTANT.ACTIVATE_FAILED')));
      })
      .catch(() => {});
  };

  return (
    <Button variant="outlined" size="small" disabled={!!consultant?.number} onClick={onClick}>
      <Trans>CONSULTANT.ACTIVATE_CONSULTANT</Trans>
    </Button>
  );
};

export default ButtonActivateConsultant;