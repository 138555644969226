import React, { ReactNode } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import { Box, ContentHeader, Divider } from '_template';
import Helmet from 'components/Helmet';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'components/Breadcrumbs';

interface SettingLayoutProps {
  children?: ReactNode;
}

const SettingLayout = ({ children }: SettingLayoutProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('SETTING.MODEL_TITLE')} />
      <ContentHeader
        title={t('SETTING.MODEL_TITLE')}
      />
      <Breadcrumbs
        section={t('SETTING.MODEL_TITLE')}
      />
      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
          <SideMenu />
        </Box>
        <Box sx={{ width: '100%', px: 6, py: 4 }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default SettingLayout;
