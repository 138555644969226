import { useSelector } from 'react-redux';
import { selectUserRights } from 'stores/loggedUserSlice';

export const useUserAccess = () => {
  const userRights = useSelector(selectUserRights);

  const getAccess = (right: string) => (
    userRights && (userRights?.includes('ALL') || userRights?.includes(right))
  );

  return {
    getAccess,
  };
};
