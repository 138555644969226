import React from 'react';
import AppLayout from 'components/AppLayout';
import NotFound from '_template/pages/NotFound';
import { SimpleLayout } from '_template';

import EmptyScene from 'scenes/Empty';
import Dashboard from 'scenes/Dashboard';
import Login from 'scenes/Login';

import bankAccountRoutes from 'modules/BankAccount/constants/routes';
import commissionRoutes from 'modules/Commission/constants/routes';
import consultantRoutes from 'modules/Consultant/constants/routes';
import currencyRoutes from 'modules/Currency/constants/routes';
import emailRoutes from 'modules/Email/constants/routes';
import emailTemplateRoutes from 'modules/EmailTemplate/constants/routes';
import paymentRoutes from 'modules/Payment/constants/routes';
import positionRoutes from 'modules/Position/constants/routes';
import presentationTypeRoutes from 'modules/PresentationType/constants/routes';
import registrationRoutes from 'modules/Registration/constants/routes';
import settingRoutes from 'modules/Setting/constants/routes';
import taxDocumentRoutes from 'modules/TaxDocument/constants/routes';
import userRoutes from 'modules/User/constants/routes';
import userGroupRoutes from 'modules/UserGroup/constants/routes';

const routes = [
  {
    path: '*',
    element: <AppLayout />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: 'auth',
    element: <SimpleLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'forgotten-password',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '',
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
    ],
  },
  {
    path: 'invoice',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  bankAccountRoutes,
  commissionRoutes,
  consultantRoutes,
  currencyRoutes,
  // deliveryMethodRoutes,
  // emailTemplateRoutes,
  {
    path: 'product',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  {
    path: 'attribute',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  {
    path: 'parameter',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  {
    path: 'product-tag',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  {
    path: 'brand',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  {
    path: 'customer',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  {
    path: 'page',
    element: <AppLayout />,
    children: [
      {
        path: 'list',
        element: <EmptyScene />
      },
      {
        path: 'detail/:recordId?',
        element: <EmptyScene />
      }
    ]
  },
  emailRoutes,
  emailTemplateRoutes,
  paymentRoutes,
  positionRoutes,
  presentationTypeRoutes,
  registrationRoutes,
  settingRoutes,
  taxDocumentRoutes,
  userRoutes,
  userGroupRoutes,

  // {
  //   path: 'private',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: '',
  //       element: <ProtectedPage />,
  //     },
  //   ],
  // },
];

export default routes;

