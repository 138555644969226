import { Box } from '_template';
import React from 'react';
import ConsultantAvatar from '../ConsultantAvatar/ConsultantAvatar';
import { consultantName } from 'modules/Consultant/helpers/consultant';

interface ConsultantInListProps {
  consultant: GQL.IConsultant;
  withNumber?: boolean;
}

const ConsultantInList = ({ consultant, withNumber = true }: ConsultantInListProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {consultant.avatarFile?.storageLink && (
        <ConsultantAvatar
          url={consultant.avatarFile.storageLink}
          sx={{ mr: 2 }}
        />
      )}
      <Box>
        {withNumber && consultant.number && <Box>{consultant.number}</Box>}
        {consultantName(consultant)}
      </Box>
    </Box>
  );
};

export default ConsultantInList;
