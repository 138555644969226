import { ModuleRightType } from 'constants/rights';

export const registrationRights: ModuleRightType = {
  REGISTRATION: {
    LIST: 'REGISTRATION.LIST',
    ADD: 'REGISTRATION.ADD',
    EDIT: 'REGISTRATION.EDIT',
    DELETE: 'REGISTRATION.DELETE',
  },
};
