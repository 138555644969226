import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  TableContainer,
  Table,
  TableColumn,
  TableActions,
  CheckIcon,
  Typography,
  Card,
} from '_template';
import ButtonDelete from 'components/ButtonDelete';
import { ROUTE } from 'constants/route';
import Link from 'components/Link';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { RIGHTS } from 'constants/rights';
import { getPresentationCategoryNameTranslateKey } from 'modules/Presentation/helpers/presentation';
import {
  DeletePresentationTypeMutation,
  GetPresentationTypeListQuery,
} from 'modules/PresentationType/query';

const PresentationTypeTable = () => {
  const { t } = useTranslation();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs(
    'presentationType',
    {
      sortField: 'primary',
      sortBy: 'asc',
    }
  );

  const presentationTypeListQuery = useQuery(GetPresentationTypeListQuery, {
    variables: {
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, error } = presentationTypeListQuery;

  const refetch = () => presentationTypeListQuery.refetch();

  const [deleteMutation] = useMutation(DeletePresentationTypeMutation);

  return (
    <TableContainer component={Card}>
      <Table<GQL.IPresentationType>
        dataSource={data?.presentationTypeList?.rows}
        recordsCount={data?.presentationTypeList?.count}
        loading={loading}
        error={error}
        {...tableProps}
      >
        <TableColumn
          label={t('PRESENTATION_TYPE.NAME')}
          dataIndex="name"
          render={(row: GQL.IPresentationType) => (
            <Link
              component={NavLink}
              to={ROUTE.PRESENTATION_TYPE_DETAIL.replace(':recordId', row.id)}
              right={RIGHTS.PRESENTATION_TYPE.EDIT}
            >
              <Typography>{row.name}</Typography>
            </Link>
          )}
        />
        <TableColumn
          label={t('PRESENTATION_TYPE.EMAIL_TEMPLATES')}
          dataIndex="emailTemplates"
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
          render={(row: GQL.IPresentationType) => {
            const emails = [
              row.emailTemplate?.name,
              row.confirmEmailTemplate?.name,
            ];
            return (
              <Typography>{emails.filter((f) => !!f).join(', ')}</Typography>
            );
          }}
        />
        <TableColumn
          label={t('PRESENTATION_TYPE.CATEGORY')}
          dataIndex="category"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '100px',
            },
          }}
          render={(presentationType: GQL.IPresentationType) => (
            <Typography>
              {t(
                getPresentationCategoryNameTranslateKey(
                  presentationType.category
                )
              )}
            </Typography>
          )}
        />
        <TableColumn
          label={t(`PRESENTATION_TYPE.PUBLISHED`)}
          dataIndex="published"
          align="center"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '70px',
            },
          }}
          render={({ published }: GQL.IPresentationType) => (
            <CheckIcon value={published === true} withColor />
          )}
        />
        <TableActions
          render={(row: GQL.IPresentationType) => {
            return (
              <ButtonDelete
                message={t('PRESENTATION_TYPE.REALLY_TO_DELETE')}
                right={RIGHTS.PRESENTATION_TYPE.DELETE}
                onDelete={() => deleteMutation({ variables: { id: row.id } })}
                refetch={refetch}
              />
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default PresentationTypeTable;
