import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Helmet from 'components/Helmet';
import Actions from './components/Actions';
import PresentationTypeTable from './components/PresentationTypeTable';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';

const PresentationTypeList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('PRESENTATION_TYPE.MODEL_TITLE')} />
      <ContentHeader
        title={t('PRESENTATION_TYPE.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={
          <Breadcrumbs section={t('PRESENTATION_TYPE.MODEL_TITLE')} />
        }
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.PRESENTATION_TYPE.LIST}>
        <PresentationTypeTable />
      </LimitedAccess>
    </>
  );
};

export default PresentationTypeList;
