import { ModuleRightType } from 'constants/rights';

export const emailTemplateRights: ModuleRightType = {
  EMAIL_TEMPLATE: {
    LIST: 'EMAIL_TEMPLATE.LIST',
    ADD: 'EMAIL_TEMPLATE.ADD',
    EDIT: 'EMAIL_TEMPLATE.EDIT',
    DELETE: 'EMAIL_TEMPLATE.DELETE',
  },
};
