import gql from 'graphql-tag';

export const InitContextQuery = gql`
  query initBackofficeContext($token: String) {
    initBackofficeContext(token: $token) {
      project {
        id
        index
        name
        domain
      }
      languages {
        id
        code
        name
        primary
      }
      currencies {
        id
        code
        primary
      }
      currencyRates {
        id
        currencyLeft {
          id
          code
        }
        currencyRight {
          id
          code
        }
        exchangeRate
      }
      loggedUser {
        id
        userGroup {
          id
          name
          rights
        }
        userName
        email
      }
      settings {
        id
        name
        value
      }
    }
  }
`;
