import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface LanguageState {
  language: GQL.ILanguage | null;
  languageList: GQL.ILanguage[] | null;
}

const initialState: LanguageState = {
  language: null,
  languageList: null,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<GQL.ILanguage>) => {
      state.language = action.payload;
    },
    setLanguageList: (state, action: PayloadAction<GQL.ILanguage[]>) => {
      state.languageList = action.payload;
    },
  },
});

export const {
  setLanguage,
  setLanguageList
} = languageSlice.actions;

export const selectLanguage = (state: RootState) => state.language.language;
export const selectLanguageList = (state: RootState) => state.language.languageList;

export default languageSlice.reducer;
