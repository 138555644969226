import React from 'react';
import { SortBy } from '../../Table';

export interface ColumnProps {
  label?: string | React.ReactNode;
  dataIndex?: string;
  sortable?: boolean;
  defaultSortBy?: SortBy;
  className?: string;
  width?: number | string;
  align?: 'left' | 'center' | 'right';
  sx?: any;
  render?: (data: any, index: number) => void;
}

class Column extends React.Component<ColumnProps> {}

export default Column;
