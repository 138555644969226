import gql from 'graphql-tag';

export const GetPaymentListQuery: any = gql`
  query paymentList($limit: LimitsInput, $orderBy: OrderByInput) {
    paymentList(limit: $limit, orderBy: $orderBy) {
      rows {
        id
        bankAccount {
          id
        }
        paymentType
        externalObject
        externalId
        external
        taxDocument {
          id
          number
        }
        transactionId
        date
        payment
        currencyCode
        accountNumber
        accountName
        bankCode
        bankName
        vs
        ss
        ks
        message
        type
        bic
      }
      count
    }
  }
`;

export const GetPaymentQuery: any = gql`
  query payment($where: PaymentWhereInput!) {
    payment(where: $where) {
      id
      bankAccount {
        id
      }
      paymentType
      externalObject
      externalId
      transactionId
      date
      payment
      currencyCode
      accountNumber
      accountName
      bankCode
      bankName
      vs
      ss
      ks
      message
      type
      bic
    }
  }
`;

export const CreatePaymentMutation: any = gql`
  mutation createPayment($data: CreateOrUpdatePaymentInput) {
    createPayment(data: $data) {
      id
    }
  }
`;

export const UpdatePaymentMutation: any = gql`
  mutation updatePayment($id: ID!, $data: CreateOrUpdatePaymentInput) {
    updatePayment(id: $id, data: $data) {
      id
    }
  }
`;

export const DeletePaymentMutation: any = gql`
  mutation deletePayment($id: ID!) {
    deletePayment(id: $id)
  }
`;

export const ImportPaymentsMutation: any = gql`
  mutation importPayments {
    importPayments
  }
`;

export const CreatePaymentTaxDocumentMutation: any = gql`
  mutation createPaymentTaxDocument($paymentId: ID!) {
    createPaymentTaxDocument(paymentId: $paymentId)
  }
`;
