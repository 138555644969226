import gql from 'graphql-tag';

export const GetPositionListQuery: any = gql`
  query positionList($where: PositionListWhereInput, $limit: LimitsInput, $orderBy: OrderByInput) {
    positionList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        parent {
          id
          code
          name
        }
        currency {
          id
          code
        }
        code
        name
        primary
        priority
        public
        published
        progressive
        registrationFee
        commissionPerPoint
        commissionPoints
      }
      count
    }
  }
`;

export const GetPositionQuery: any = gql`
  query position($where: PositionWhereInput!) {
    position(where: $where) {
      id
      parent {
        id
        code
        name
      }
      currency {
        id
        code
      }
      conditions {
        id
        firstLevelSize
        firstLevelOnPositions
        teamSize
        sumAmount
      }
      code
      name
      primary
      priority
      published
      public
      progressive
      registrationFee
      commissionPerPoint
      commissionPoints
    }
  }
`;

export const CreatePositionMutation: any = gql`
  mutation createPosition($data: CreateOrUpdatePositionInput!) {
    createPosition(data: $data) {
      id
    }
  }
`;

export const UpdatePositionMutation: any = gql`
  mutation updatePosition($id: ID!, $data: CreateOrUpdatePositionInput!) {
    updatePosition(id: $id, data: $data) {
      id
    }
  }
`;

export const DeletePositionMutation: any = gql`
  mutation deletePosition($id: ID!) {
    deletePosition(id: $id)
  }
`;
