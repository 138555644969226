import React from 'react';
import AppLayout from 'components/AppLayout';
import BankAccountList from '../containers/BankAccountList';
import BankAccountDetail from '../containers/BankAccountDetail';

const routes = {
  path: 'bankAccount',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <BankAccountList />,
    },
    {
      path: 'detail/:recordId?',
      element: <BankAccountDetail />,
    },
  ],
};

export default routes;
