import React from 'react';
import { Breadcrumbs, BreadcrumbsProps } from '_template';
import { SETTINGS } from 'constants/settings';
import { useSetting } from 'hooks/useSetting';

interface MyBreadcrumbsProps extends Omit<BreadcrumbsProps, 'prefix'> {};

const MyBreadcrumbs = (props: MyBreadcrumbsProps) => {
  const { getSetting } = useSetting();

  const projectName = getSetting(SETTINGS.PROJECT_NAME);

  return (
    <Breadcrumbs
      {...props}
      prefix={projectName?.value || 'MLM'}
    />
  );
};

export default MyBreadcrumbs;
