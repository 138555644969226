import React from 'react';
import AppLayout from 'components/AppLayout';
import RegistrationList from '../containers/RegistrationList';
import RegistrationDetail from '../containers/RegistrationDetail';

const routes = {
  path: 'registration',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <RegistrationList />,
    },
    {
      path: 'detail/:recordId?',
      element: <RegistrationDetail />,
    },
  ],
};

export default routes;
