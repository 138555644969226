import React from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider, Loader, Stack } from '_template';
import NotFound from '_template/pages/NotFound';
import Breadcrumbs from 'components/Breadcrumbs';
import { GetConsultantQuery } from 'modules/Consultant/query/Consultant';
import { ROUTE } from 'constants/route';
import ConsultantForm from './ConsultantForm';
import { RIGHTS } from 'constants/rights';
import LimitedAccess from 'components/LimitedAccess';
import Helmet from 'components/Helmet';
import { consultantNumberAndName } from 'modules/Consultant/helpers/consultant';
import ButtonActivateConsultant from './components/ButtonActivateConsultant';
import DropDownButtonSendActions from './components/DropDownButtonSendActions';

const ConsultantDetail = () => {
  const { t } = useTranslation();
  const { recordId } = useParams();

  const consultantQuery = useQuery(GetConsultantQuery, {
    variables: {
      where: {
        id: recordId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { loading, error } = consultantQuery;

  const data = consultantQuery?.data?.consultant;

  const isNew = recordId === 'new';

  if (loading) {
    return <Loader />;
  }

  if (error && !isNew) {
    return <NotFound />;
  }

  if (!data) {
    // TODO: lepší zobrazení, že hledaný poradce neexistuje
    return <NotFound />;
  }

  const title = isNew
    ? t('CONSULTANT.NEW')
    : t('CONSULTANT.EDIT', { name: consultantNumberAndName(data) });

  return (
    <>
      <Helmet title={title} />
      <ContentHeader
        title={t('CONSULTANT.MODEL_TITLE')}
        actions={
          <Stack direction="row" spacing={1}>
            <DropDownButtonSendActions
              consultant={data}
              refetch={consultantQuery.refetch}
            />
            <ButtonActivateConsultant
              consultant={data}
              refetch={consultantQuery.refetch}
            />
          </Stack>
        }
        breadcrumbs={
          <Breadcrumbs
            links={{
              [ROUTE.CONSULTANT_LIST]: t('CONSULTANT.MODEL_TITLE'),
            }}
            section={title}
          />
        }
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess
        right={isNew ? RIGHTS.CONSULTANT.ADD : RIGHTS.CONSULTANT.EDIT}
      >
        <ConsultantForm
          recordId={recordId}
          data={{
            ...data,
            parentId: data && data.parent ? data.parent.id : null,
            positionId: data && data.position ? data.position.id : null,
            registrationDate: moment(data?.registrationDate).format(
              'YYYY-MM-DD'
            ),
            active: data ? data.active : true,
          }}
        />
      </LimitedAccess>
    </>
  );
};

export default ConsultantDetail;
