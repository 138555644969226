import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SETTINGS } from 'constants/settings';
import SettingsForm from 'components/SettingsForm';
import TextField from 'components/TextField';

const ProjectSetting = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    [SETTINGS.PROJECT_NAME]: Yup.string().required(t<string>('GENERAL.FIELD_REQUIRED')),
  });

  return (
    <SettingsForm validationSchema={validationSchema}>
      {({ handleSubmit }) => (
        <>
          <TextField
            name={SETTINGS.PROJECT_NAME}
            label={t('SETTING.PROJECT_NAME')}
            withoutHelperText
            onBlur={() => handleSubmit()}
          />
        </>
      )}
    </SettingsForm>
  );
};

export default ProjectSetting;
