import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import {
  Alert,
  Button,
  Checkbox,
  Card,
  CardContent,
  FormControlLabel,
  TextField,
} from '_template';
import useAuth from 'hooks/useAuth';

const LoginForm = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const onSubmit = async (values: any, { setErrors, setStatus, setSubmitting, setTouched }: FormikHelpers<any>) => {
    try {
      await signIn(values.userName, values.password);
      navigate('/');
    } catch (error: any) {
      const message = error.message || 'Something went wrong';

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
      setTouched({}, false);
    }
  };

  return (
    <Formik
      initialValues={{
        userName: '',
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string().max(255).required('User name is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card>
          <CardContent sx={{ px: 6, pt: 6 }}>
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert severity="error" sx={{ mb: 6 }}>
                  {errors.submit}
                </Alert>
              )}
              <TextField
                name="userName"
                label="User name"
                value={values.userName}
                error={Boolean(touched.userName && errors.userName)}
                fullWidth
                autoFocus
                helperText={touched.userName && errors.userName}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ my: 2 }}
              />
              <TextField
                type="password"
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ my: 2 }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
                sx={{ mb: 2 }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Sign in
              </Button>
              <Button
                to="/auth/reset-password"
                fullWidth
                color="primary"
                sx={{ mt: 3 }}
              >
                Forgot password
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default LoginForm;
