// import { availabilityRoute } from 'modules/Availability/constants/route';
import { bankAccountRoute } from 'modules/BankAccount/constants/route';
// import { categoryRoute } from 'modules/Category/constants/route';
import { commissionRoute } from 'modules/Commission/constants/route';
import { consultantRoute } from 'modules/Consultant/constants/route';
import { currencyRoute } from 'modules/Currency/constants/route';
// import { deliveryMethodRoute } from 'modules/DeliveryMethod/constants/route';
import { emailRoute } from 'modules/Email/constants/route';
import { emailTemplateRoute } from 'modules/EmailTemplate/constants/route';
// import { languageRoute } from 'modules/Language/constants/route';
// import { orderRoute } from 'modules/Order/constants/route';
// import { orderLabelRoute } from 'modules/OrderLabel/constants/route';
// import { orderStatusRoute } from 'modules/OrderStatus/constants/route';
// import { paymentMethodRoute } from 'modules/PaymentMethod/constants/route';
// import { unitRoute } from 'modules/Unit/constants/route';
import { paymentRoute } from 'modules/Payment/constants/route';
import { positionRoute } from 'modules/Position/constants/route';
import { presentationTypeRoute } from 'modules/PresentationType/constants/route';
import { registrationRoute } from 'modules/Registration/constants/route';
import { settingRoute } from 'modules/Setting/constants/route';
import { taxDocumentRoute } from 'modules/TaxDocument/constants/route';
import { userRoute } from 'modules/User/constants/route';
import { userGroupRoute } from 'modules/UserGroup/constants/route';
// import { vatRoute } from 'modules/Vat/constants/route';

export const ROUTE = {
  DASHBOARD: '/',
  // ...availabilityRoute,
  ...bankAccountRoute,
  // ...categoryRoute,
  ...commissionRoute,
  ...consultantRoute,
  ...currencyRoute,
  // ...deliveryMethodRoute,
  ...emailRoute,
  ...emailTemplateRoute,
  // ...languageRoute,
  // ...orderRoute,
  // ...orderLabelRoute,
  // ...orderStatusRoute,
  // ...paymentMethodRoute,
  // ...unitRoute,
  ...paymentRoute,
  ...positionRoute,
  ...presentationTypeRoute,
  ...registrationRoute,
  ...settingRoute,
  ...taxDocumentRoute,
  ...userRoute,
  ...userGroupRoute,
  // ...vatRoute,
};
