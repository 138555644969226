import enUS from './translation.json';
// import { en as availabilityEn } from 'modules/Availability/i18n';
import { en as bankAccountEn } from 'modules/BankAccount/i18n';
// import { en as categoryEn } from 'modules/Category/i18n';
import { en as commissionEn } from 'modules/Commission/i18n';
import { en as consultantEn } from 'modules/Consultant/i18n';
import { en as currencyEn } from 'modules/Currency/i18n';
// import { en as customerEn } from 'modules/Customer/i18n';
// import { en as deliveryMethodEn } from 'modules/DeliveryMethod/i18n';
import { en as emailEn } from 'modules/Email/i18n';
import { en as emailTemplateEn } from 'modules/EmailTemplate/i18n';
import { en as fileEn } from 'modules/File/i18n';
// import { en as languageEn } from 'modules/Language/i18n';
// import { en as orderEn } from 'modules/Order/i18n';
// import { en as orderLabelEn } from 'modules/OrderLabel/i18n';
// import { en as orderStatusEn } from 'modules/OrderStatus/i18n';
// import { en as paymentMethodEn } from 'modules/PaymentMethod/i18n';
// import { en as taxDocumentEn } from 'modules/TaxDocument/i18n';
// import { en as unitEn } from 'modules/Unit/i18n';
import { en as paymentEn } from 'modules/Payment/i18n';
import { en as positionEn } from 'modules/Position/i18n';
import { cs as presentationTypeEn } from 'modules/PresentationType/i18n';
import { en as registrationEn } from 'modules/Registration/i18n';
import { en as settingEn } from 'modules/Setting/i18n';
import { en as userEn } from 'modules/User/i18n';
import { en as userGroupEn } from 'modules/UserGroup/i18n';
import { en as taxDocumentEn } from 'modules/TaxDocument/i18n';
// import { en as vatEn } from 'modules/Vat/i18n';

const enTranslation = {
  ...enUS,
  // ...availabilityEn,
  ...bankAccountEn,
  // ...categoryEn,
  ...commissionEn,
  ...consultantEn,
  ...currencyEn,
  // ...customerEn,
  // ...deliveryMethodEn,
  ...emailEn,
  ...emailTemplateEn,
  ...fileEn,
  // ...languageEn,
  // ...orderEn,
  // ...orderLabelEn,
  // ...orderStatusEn,
  // ...paymentMethodEn,
  // ...taxDocumentEn,
  // ...unitEn,
  ...paymentEn,
  ...positionEn,
  ...presentationTypeEn,
  ...registrationEn,
  ...settingEn,
  ...userEn,
  ...userGroupEn,
  ...taxDocumentEn,
  // ...vatEn,
};

export default enTranslation;
