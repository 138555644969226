import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Loader } from '_template';
import { useMutation } from '@apollo/client';
import { CreatePaymentTaxDocumentMutation } from 'modules/Payment/query';

interface LinkTaxDocumentProps {
  payment: GQL.IPayment;
}

const LinkTaxDocument = ({ payment }: LinkTaxDocumentProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [createTaxDocument] = useMutation(CreatePaymentTaxDocumentMutation);

  const handleCreateTaxDocument = () => {
    setLoading(true);
    createTaxDocument({
      variables: {
        paymentId: payment.id,
      },
      refetchQueries: ['paymentList'],
    }).then(() => {
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <Loader
        center={false}
        sx={{ my: -2 }}
        iconProps={{ size: 'small', sx: { width: 22, height: 22 } }}
      />
    );
  }

  return (
    <Link onClick={handleCreateTaxDocument}>
      {t('PAYMENT.CREATE_TAX_DOCUMENT')}
    </Link>
  );
};

export default LinkTaxDocument;
