import gql from 'graphql-tag';

export const GetEmailTemplateListQuery: any = gql`
  query emailTemplateList($limit: LimitsInput, $orderBy: OrderByInput) {
    emailTemplateList(limit: $limit, orderBy: $orderBy) {
      rows {
        id
        name
        article
        bodyHtml
      }
      count
    }
  }
`;

export const GetEmailTemplateQuery: any = gql`
  query emailTemplate($where: EmailTemplateWhereInput!) {
    emailTemplate(where: $where) {
      id
      name
      article
      bodyHtml
    }
  }
`;

export const CreateEmailTemplateMutation: any = gql`
  mutation createEmailTemplate($data: CreateOrUpdateEmailTemplateInput) {
    createEmailTemplate(data: $data) {
      id
    }
  }
`;

export const UpdateEmailTemplateMutation: any = gql`
  mutation updateEmailTemplate($id: ID!, $data: CreateOrUpdateEmailTemplateInput) {
    updateEmailTemplate(id: $id, data: $data) {
      id
    }
  }
`;

export const DeleteEmailTemplateMutation: any = gql`
  mutation deleteEmailTemplate($id: ID!) {
    deleteEmailTemplate(id: $id)
  }
`;
