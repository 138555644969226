import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import reduceChildRoutes from './reduceChildRoutes';
import { SidebarItemPageOptions } from './types';

interface SidebarNavListProps {
  // @ts-ignore()
  pages: SidebarItemPageOptions[];
  depth: number;
}

const SidebarNavList = ({
  pages,
  depth = 0
}: SidebarNavListProps) => {
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce<ReactElement[]>(
    (items: ReactElement[], page: SidebarItemPageOptions) => reduceChildRoutes({
      items, page, currentRoute, depth
    }),
    []
  );

  return (
    <React.Fragment>
      {childRoutes}
    </React.Fragment>
  );
};

export default SidebarNavList;
