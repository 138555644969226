import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ErrorBoundary, Provider, ThemeProvider } from '_template';
// import { changeLocale, selectLocale } from 'stores/appSlice';
// import { localizationsCode } from 'constants/localizations';
import Helmet from 'components/Helmet';
import routes from 'constants/routes';
import Auth from 'components/Auth';

const App = () => {
  // const dispatch = useDispatch();
  const content = useRoutes(routes);
  // const locale = useSelector(selectLocale);

  // useEffect(() => {
  //   const lng = localStorage.getItem('locale');
  //   if (lng && lng !== locale) {
  //     if (Object.values(localizationsCode).find((locale) => locale === lng)) {
  //       dispatch(changeLocale(lng));
  //     }
  //   };
  // }, []);

  return (
    <Provider>
      <ThemeProvider>
        <ErrorBoundary>
          <Auth>
            <Helmet />
            {content}
          </Auth>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
