import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SidebarNavSection from './SidebarNavSection';
import { SidebarItemOptions } from './types';
import Box from '../Box';

// TODO: obnovit
// import '../../vendor/perfect-scrollbar.css';

const baseScrollbar = (props: any) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

interface SidebarNavProps {
  items?: SidebarItemOptions[];
}

const SidebarNav = ({ items }: SidebarNavProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Box
          sx={{
            py: 2.5,
          }}
        >
          {items &&
            items.map((item: SidebarItemOptions, index: number) => (
              <SidebarNavSection
                component="div"
                key={`sidebar-nav-${item.title || index}`}
                pages={item.pages}
                title={item.title}
              />
            ))}
        </Box>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
