import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import GlobalStyle from '../../helpers/GlobalStyle';

interface SimpleLayoutProps {
  children?: ReactNode;
}

const SimpleLayout = ({ children }: SimpleLayoutProps) => {
  return (
    <Box
      sx={{
        maxWidth: '520px',
        mx: 'auto',
        my: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column',
      }}
    >
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Box>
  );
};

export default SimpleLayout;
