import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { LayoutByAuth } from '_template';
import { selectLoggedUser } from 'stores/loggedUserSlice';
import useSidebarMenuItems from 'constants/sidebarMenuItems';
import Context from 'components/Context';
import UserAccount from './UserAccount';
import Languages from './Languages';
import { SETTINGS } from 'constants/settings';
import { useSetting } from 'hooks/useSetting';

interface AppLayoutProps {
  byAuth?: boolean;
  children?: ReactNode;
};

const AppLayout = ({ byAuth = false, ...props }: AppLayoutProps) => {
  const { items } = useSidebarMenuItems();
  const { getSetting } = useSetting();

  const projectName = getSetting(SETTINGS.PROJECT_NAME);

  const loggedUser = useSelector(selectLoggedUser);

  return (
    <Context>
      <LayoutByAuth
        isAuthorized={!!loggedUser}
        sidebar={{
          header: projectName?.value || 'MLM',
          items,
        }}
        header={(
          <>
            <Languages />
            <UserAccount />
          </>
        )}
      >
        {props.children}
      </LayoutByAuth>
    </Context>
  );
};

export default AppLayout;
