import React from 'react';
import { Box, TableCell, TableCellProps, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ColumnProps } from '../Column';
import Actions, { ActionColumnSx } from '../Actions';
import { SortBy, SortProps } from '../../Table';

interface HeaderContentProps extends TableCellProps {
  sortable?: boolean;
  sortField?: string;
  sortBy?: SortBy;
  currentSort?: SortProps;
  defaultSortBy?: SortBy;
  align?: 'left' | 'center' | 'right';
  children?: any;
  handleSortChange?: (sort: SortProps) => void;
}

interface HeaderProps extends HeaderContentProps {
  column: React.Component<ColumnProps>;
  width?: number | string;
  sx?: any;
}

const HeaderContent = ({
  align,
  sortable,
  sortField,
  currentSort,
  defaultSortBy = 'desc',
  children,
  handleSortChange
}: HeaderContentProps) => {
  if (sortable && !!handleSortChange) {
    const sortBy = currentSort?.sortField === sortField
      // směr třídění vybraného sloupce tabulky
      ? currentSort?.sortBy
      // opak výchozího směru třídění jiného než vybraného sloupce
      : defaultSortBy === 'asc'
        ? 'desc'
        : 'asc';

    const icons = (
      <>
        <ArrowDropDownIcon
          fontSize="small"
          className={sortBy === 'desc' ? 'actual' : 'reverse'}
        />
        <ArrowDropUpIcon
          fontSize="small"
          className={sortBy === 'desc' ? 'reverse' : 'actual'}
        />
      </>
    );

    const onClick = (_: React.MouseEvent<HTMLDivElement>) => {
      if (sortField) {
        if (currentSort?.sortField === sortField) {
          handleSortChange({
            sortField,
            sortBy: currentSort?.sortBy === 'asc' ? 'desc' : 'asc',
          });
        } else {
          handleSortChange({
            sortField,
            sortBy: defaultSortBy === 'asc' ? 'asc' : 'desc',
          });
        }
      }
    };

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: align,
          cursor: 'pointer',
          '& .actual': {
            visibility: currentSort?.sortField === sortField ? 'visible' : 'hidden',
          },
          '& .reverse': {
            display: 'none',
          },
          '&:hover': {
            '& .actual': { display: 'none' },
            '& .reverse': { display: 'block' },
          },
        }}
        onClick={onClick}
      >
        {align === 'right' && icons}
        <Typography sx={{ textDecoration: 'underline' }}>
          {children}
        </Typography>
        {align !== 'right' && icons}
      </Box>
    );
  }

  return (
    <Typography>
      {children}
    </Typography>
  );
};

const Header = ({
  column,
  sortable,
  sortField,
  currentSort,
  defaultSortBy = 'desc',
  align = 'left',
  sx,
  children,
  handleSortChange,
  ...props
}: HeaderProps) => {
  return (
    <TableCell
      component="th"
      // @ts-ignore
      sx={{
        ...((column as any).type === Actions
          ? ActionColumnSx
          : { textAlign: align }),
        ...(typeof sx === 'function' ? sx() : sx),
      }}
      {...props}
    >
      <HeaderContent
        align={align}
        sortable={sortable}
        sortField={sortField}
        currentSort={currentSort}
        defaultSortBy={defaultSortBy}
        handleSortChange={handleSortChange}
      >
        {children}
      </HeaderContent>
    </TableCell>
  );
};

export default Header;
