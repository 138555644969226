import React, { ChangeEvent, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { PositionConditionData } from 'modules/Position/interfaces/position';
import { ButtonAdd, Grid, IconButton, Table, TableActions, TableColumn, Tooltip, Typography } from '_template';
import ButtonDelete from 'components/ButtonDelete';
import { GetPositionListQuery } from 'modules/Position/query';
import PositionConditionDialog from './PositionConditionDialog';

interface PositionConditionTableProps {
  data: PositionConditionData[];
  onChange: (e: ChangeEvent<any>) => void;
}

interface PositionConditionShowForm {
  index?: number;
  data: PositionConditionData | null;
}

const PositionConditionTable = ({ data, ...props }: PositionConditionTableProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<PositionConditionShowForm | null>(null);

  const positionQuery = useQuery(GetPositionListQuery);

  const { data: { positionList } } = positionQuery;

  const onChange = (newData: PositionConditionData[]) => {
    props.onChange({
      target: {
        name: 'conditions',
        value: newData,
      },
    } as any);
  };

  const handleSubmit = (conditionData: PositionConditionData) => {
    if (showModal) {
      let newData = [...data];
      if (showModal.index === null || showModal.index === undefined) {
        newData.push(conditionData);
      } else {
        newData = [...data].map((d: PositionConditionData, index: number) => {
          if (index === showModal.index) {
            return conditionData;
          }
          return d;
        });
      }
      onChange(newData);
      setShowModal(null);
    }
  };

  const onClickDelete = (index: number) => onChange([...data].filter((_, i: number) => i !== index));

  const getPositionName = useCallback((positionId: string) => {
    const position = positionList.rows.find((position: GQL.IPosition) => position.id === positionId);
    if (position) {
      return position.name;
    }
  }, [positionList]);

  return (
    <>
      {showModal && (
        <PositionConditionDialog
          data={showModal?.data}
          onSubmit={handleSubmit}
          onClose={() => setShowModal(null)}
        />
      )}
      <Grid container>
        <Grid item xs={12} mb={3}>
          <Typography>
            <Trans>POSITION.LIST_OF_CONDITIONS_DESCRIPTION</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3, textAlign: 'right' }}>
          <ButtonAdd variant="contained" onClick={() => setShowModal({ data: null })}>
            <Trans>POSITION_CONDITION.ADD</Trans>
          </ButtonAdd>
        </Grid>
      </Grid>
      <Table
        dataSource={data}
        handleRecordsOnPageChange={() => {}}
      >
        <TableColumn
          label={t('POSITION_CONDITION.FIRST_LEVEL_SIZE')}
          dataIndex="firstLevelSize"
          width="55%"
          render={(row: PositionConditionData) => (
            row.firstLevelSize ? (
              <Typography>
                min {row.firstLevelSize} poradců
                {(row?.firstLevelOnPositions || []).length > 0 && (
                  <>, na pozicích ({row?.firstLevelOnPositions?.map((positionId: string) => getPositionName(positionId)).join(', ')})</>
                )}
              </Typography>
            ) : '-'
          )}
        />
        <TableColumn
          label={t('POSITION_CONDITION.TEAM_SIZE')}
          dataIndex="teamSize"
          render={(row: PositionConditionData) => (
            row.teamSize ? (
              <Typography>
                min {row.teamSize} poradců
              </Typography>
            ) : '-'
          )}
        />
        <TableColumn
          label={t('POSITION_CONDITION.SUM_AMOUNT')}
          dataIndex="sumAmount"
          render={(row: PositionConditionData) => (
            row.sumAmount ? (
              // TODO: nutnost doplnit měnu
              <Typography>
                min {row.sumAmount}
              </Typography>
            ) : '-'
          )}
        />
        <TableActions
          render={(row: PositionConditionData, index: number) => (
            <>
              <Tooltip title={t<string>('GENERAL.EDIT')}>
                <IconButton onClick={() => setShowModal({ index, data: row })}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <ButtonDelete
                quickEffect
                disabled={false}
                onClick={() => onClickDelete(index)}
                refetch={() => {}}
              />
            </>
          )}
        />
      </Table>
    </>
  );
};

export default PositionConditionTable;
