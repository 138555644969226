import React from 'react';
import AppLayout from 'components/AppLayout';
import PositionList from '../containers/PositionList';
import PositionDetail from '../containers/PositionDetail';

const routes = {
  path: 'position',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <PositionList />,
    },
    {
      path: 'detail/:recordId?',
      element: <PositionDetail />,
    },
  ],
};

export default routes;
