import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import DownloadIcon from '@mui/icons-material/Download';
import CachedIcon from '@mui/icons-material/Cached';
import {
  TableContainer,
  Table,
  TableColumn,
  TableActions,
  Typography,
  Card,
  IconButton,
  FilterContainer,
  CheckIcon,
  Box,
} from '_template';
import ButtonDelete from 'components/ButtonDelete';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { RIGHTS } from 'constants/rights';
import {
  DeleteTaxDocumentMutation,
  GetTaxDocumentListQuery,
  RefreshTaxDocumentMutation,
} from 'modules/TaxDocument/query';
import Price from 'components/Price';
import Button from 'components/Button';
import DateTime from 'components/DateTime';
import useDataFilter from 'helpers/table/useDataFilter';

const TaxDocumentTable = () => {
  const { t } = useTranslation();
  const { searchQuery, filterProps } = useDataFilter();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs(
    'taxDocument',
    {
      sortField: 'createdAt',
      sortBy: 'desc',
    }
  );

  const taxDocumentListQuery = useQuery(GetTaxDocumentListQuery, {
    variables: {
      where: {
        searchQuery,
      },
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, error } = taxDocumentListQuery;

  const [refreshTaxDocumentMutation] = useMutation(RefreshTaxDocumentMutation);

  const [deleteMutation] = useMutation(DeleteTaxDocumentMutation);

  const refetch = () => taxDocumentListQuery.refetch();

  const handleDownload = (document: GQL.ITaxDocument) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/tax-document/generate-invoice/${document.id}`,
      '_blank',
      'noreferrer'
    );
  };

  const handleRefreshData = (document: GQL.ITaxDocument) => {
    refreshTaxDocumentMutation({
      variables: {
        id: document.id,
      },
    }).then(refetch);
  };

  return (
    <>
      <FilterContainer {...filterProps} />
      <TableContainer component={Card}>
        <Table<GQL.ITaxDocument>
          dataSource={data?.taxDocumentList?.rows}
          recordsCount={data?.taxDocumentList?.count}
          loading={loading}
          error={error}
          onRefetch={refetch}
          {...tableProps}
        >
          <TableColumn
            label={t('TAX_DOCUMENT.NUMBER')}
            dataIndex="number"
            sortable
            sx={{
              width: 130,
            }}
            render={(row: GQL.ITaxDocument) => (
              // <Link
              //   component={NavLink}
              //   to={ROUTE.TAX_DOCUMENT_DETAIL.replace(':recordId', row.id)}
              //   right={RIGHTS.TAX_DOCUMENT.EDIT}
              // >
              <Typography>{row.number}</Typography>
              // </Link>
            )}
          />
          <TableColumn
            label={t('TAX_DOCUMENT.CREATED_AT')}
            dataIndex="createdAt"
            sortable
            sx={{
              width: 110,
            }}
            render={(row: GQL.ITaxDocument) => (
              <DateTime value={row.dateCreated} onlyDate />
            )}
          />
          <TableColumn
            label={t('TAX_DOCUMENT.SUBSCRIBER')}
            dataIndex="subscriber"
            sortable
            render={(row: GQL.ITaxDocument) => (
              <>
                {row.subscriberCompanyName && (
                  <div>
                    <Typography component="strong">
                      {row.subscriberCompanyName}
                    </Typography>
                  </div>
                )}
                <Typography>
                  {row.subscriberLastName} {row.subscriberFirstName} /{' '}
                  {row.subscriberCity}
                </Typography>
              </>
            )}
          />
          <TableColumn
            label={t('TAX_DOCUMENT.VARIABLE_SYMBOL')}
            dataIndex="variableSymbol"
            sortable
            sx={{
              display: {
                xs: 'none',
                md: 'table-cell',
                width: '200px',
              },
            }}
            render={(row: GQL.ITaxDocument) => (
              <Box display="flex">
                <Typography>{row.variableSymbol}</Typography>
                <Box sx={{ ml: 1, mt: 0.4 }}>
                  <CheckIcon value={!!row.payment} size={16} />
                </Box>
              </Box>
            )}
          />
          <TableColumn
            label={t('TAX_DOCUMENT.TOTAL')}
            dataIndex="totalPrice"
            sortable
            align="right"
            sx={{
              display: {
                xs: 'none',
                md: 'table-cell',
                width: '100px',
              },
            }}
            render={(row: GQL.ITaxDocument) => (
              <Typography component="strong">
                <Price value={row.totalPrice} currency={row.currency} />
              </Typography>
            )}
          />
          <TableActions
            render={(row: GQL.ITaxDocument) => {
              return (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(row)}
                  >
                    <Trans>TAX_DOCUMENT.DOWNLOAD</Trans>
                  </Button>
                  <IconButton
                    size="small"
                    sx={{ ml: 2 }}
                    onClick={() => handleRefreshData(row)}
                  >
                    <CachedIcon fontSize="small" />
                  </IconButton>
                  <ButtonDelete
                    message={t('TAX_DOCUMENT.REALLY_TO_DELETE')}
                    right={RIGHTS.TAX_DOCUMENT.DELETE}
                    onDelete={() =>
                      deleteMutation({ variables: { id: row.id } })
                    }
                    refetch={refetch}
                  />
                </>
              );
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default TaxDocumentTable;
