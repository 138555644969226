import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Switch } from '_template';
import PresentationCategorySelect from 'modules/Presentation/components/PresentationCategorySelect';
import { CreatePresentationTypeMutation, UpdatePresentationTypeMutation } from 'modules/PresentationType/query';
import { handleSaveData } from 'helpers/data/handleSaveData';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import { PresentationTypeData } from '../../interfaces/presentationType';
import EmailTemplateSelect from 'modules/EmailTemplate/components/EmailTemplateSelect';

interface PresentationTypeFormProps {
  recordId?: string;
  data?: PresentationTypeData;
}

const PresentationTypeForm = ({ recordId, data }: PresentationTypeFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreatePresentationTypeMutation);
  const [updateMutation] = useMutation(UpdatePresentationTypeMutation);

  const onCancel = () => navigate(ROUTE.PRESENTATION_TYPE_LIST);

  const validationSchema = Yup.object({
    category: Yup.string().required(t<string>('PRESENTATION_TYPE.CATEGORY_REQUIRED')),
    name: Yup.string().required(t<string>('PRESENTATION_TYPE.NAME_REQUIRED')),
  });

  const onSubmit = (newData: PresentationTypeData) => {
    const variables = {
      id: recordId,
      data: {
        emailTemplateId: newData.emailTemplateId || 'null',
        confirmEmailTemplateId: newData.confirmEmailTemplateId || 'null',
        name: newData.name,
        category: newData.category,
        published: !!newData.published,
      },
    };

    handleSaveData(isNew ? createMutation : updateMutation, variables, onCancel);
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} onCancel={onCancel}>
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item sm={6}>
                  <TextField
                    name="name"
                    label={t('PRESENTATION_TYPE.NAME')}
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <PresentationCategorySelect
                    name="category"
                    label={t('PRESENTATION_TYPE.CATEGORY')}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <EmailTemplateSelect
                    name="emailTemplateId"
                    label={t('PRESENTATION_TYPE.EMAIL_TEMPLATE')}
                    helperText={t<string>('PRESENTATION_TYPE.EMAIL_TEMPLATE_DESCRIPTION')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <EmailTemplateSelect
                    name="confirmEmailTemplateId"
                    label={t('PRESENTATION_TYPE.CONFIRM_EMAIL_TEMPLATE')}
                    helperText={t<string>('PRESENTATION_TYPE.CONFIRM_EMAIL_TEMPLATE_DESCRIPTION')}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Switch
                    name="published"
                    label={t('PRESENTATION_TYPE.PUBLISHED')}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default PresentationTypeForm;
