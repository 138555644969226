import React, { ReactElement } from 'react';
import { Formik } from 'formik';
import { LoaderScreen } from '_template';
import useSettingContext from 'modules/Setting/context';

interface SettingsFormChildrenProps {
  values: any;
  handleSubmit: () => void;
}

interface SettingsFormProps {
  validationSchema?: any;
  children: (props: SettingsFormChildrenProps) => ReactElement;
}

const SettingsForm = ({ validationSchema, children }: SettingsFormProps) => {
  const {
    values: initialValues,
    loading,
    submitSetting
  } = useSettingContext();

  if (loading) {
    return <LoaderScreen />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={submitSetting}
    >
      {({ values, handleSubmit }) => children({ values, handleSubmit })}
    </Formik>
  );
};

export default SettingsForm;
