import React from 'react';
import AppLayout from 'components/AppLayout';
import UserGroupList from '../containers/UserGroupList';
import UserGroupDetail from '../containers/UserGroupDetail';

const routes = {
  path: 'user-group',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <UserGroupList />,
    },
    {
      path: 'detail/:recordId?',
      element: <UserGroupDetail />,
    },
  ],
};

export default routes;
