import React from 'react';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { MenuItem, SelectProps } from '_template';
import { GetEmailTemplateListQuery } from 'modules/EmailTemplate/query';
import Select from 'components/Select';

type EmailTemplateSelectProps = Omit<SelectProps, 'onChange'> & {
  onChange?: (e: React.ChangeEvent<any>, template: GQL.IEmailTemplate | null) => void;
};

const EmailTemplateSelect = (props: EmailTemplateSelectProps) => {
  const formikContext = useFormikContext() || {};

  const { data } = useQuery(GetEmailTemplateListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const onChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    const template: GQL.IEmailTemplate = data?.emailTemplateList?.rows?.find((c: GQL.IEmailTemplate) => c.id === value);

    (props.onChange || formikContext?.handleChange)(
      {
        ...e,
        target: {
          ...e.target,
          value: value,
        },
      },
      template || null
    );
  };

  return (
    <Select {...props} onChange={onChange}>
      {data?.emailTemplateList?.rows?.map((template: GQL.IEmailTemplate) => (
        <MenuItem key={template.id} value={template.id}>
          {template.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EmailTemplateSelect;
