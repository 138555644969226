import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  CheckIcon,
  Table,
  TableContainer,
  TableColumn,
  TableActions,
} from '_template';
import {
  DeletePositionMutation,
  GetPositionListQuery,
} from 'modules/Position/query';
import { positionNumberAndName } from 'modules/Position/helpers/position';
import ButtonDelete from 'components/ButtonDelete';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { ROUTE } from 'constants/route';
import Link from 'components/Link';
import { RIGHTS } from 'constants/rights';
import Price from 'components/Price';

interface PositionData extends GQL.IPosition {
  level: number;
}

const PositionTable = () => {
  const { t } = useTranslation();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs('position');

  const positionListQuery = useQuery(GetPositionListQuery, {
    variables: {
      where: {},
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, error, data } = positionListQuery;

  const refetch = () => positionListQuery.refetch();

  const [deletePosition] = useMutation(DeletePositionMutation);

  const treeData = useCallback(
    (
      parentId: string | null = null,
      level: number = 0
    ): PositionData[] | undefined => {
      if (!data) {
        return undefined;
      }

      return (data?.positionList?.rows || [])
        .filter(
          (position: GQL.IPosition) =>
            (position.parent?.id || null) === parentId
        )
        .reduce((acc: PositionData[], position: GQL.IPosition) => {
          acc.push({
            ...position,
            level,
          });
          treeData(position.id, level + 1)?.forEach(
            (subPosition: PositionData) => {
              acc.push(subPosition);
            }
          );
          return acc;
        }, []);
    },
    [data]
  );

  return (
    <TableContainer component={Card}>
      <Table<GQL.IPosition>
        disablePaginate
        dataSource={treeData()}
        recordsCount={data?.positionList?.count}
        loading={loading}
        error={error}
        {...tableProps}
      >
        <TableColumn
          label={t('POSITION.POSITION')}
          dataIndex="position"
          render={(row: PositionData) => (
            <Link
              component={NavLink}
              to={ROUTE.POSITION_DETAIL.replace(':recordId', row.id)}
              right={RIGHTS.POSITION.EDIT}
              sx={{ ml: row.level * 6 }}
            >
              {positionNumberAndName(row)}
            </Link>
          )}
        />
        <TableColumn
          label={t(`POSITION.REGISTRATION_FEE`)}
          dataIndex="registrationFee"
          align="right"
          sx={{
            display: {
              xs: 'none',
              sm: 'table-cell',
              width: '90px',
            },
          }}
          render={(position: GQL.IPosition) => (
            <Price
              value={position.registrationFee}
              currency={position.currency}
            />
          )}
        />
        <TableColumn
          label={t(`POSITION.COMMISSION_POINTS_SMALL`)}
          dataIndex="registrationFee"
          align="right"
          sx={{
            display: {
              xs: 'none',
              sm: 'table-cell',
              width: '90px',
            },
          }}
          render={(position: GQL.IPosition) => (position.commissionPoints || 0) > 0 && `${position.commissionPoints} b`}
        />
        <TableColumn
          label={t(`POSITION.COMMISSION_PER_POINT`)}
          dataIndex="registrationFee"
          align="right"
          sx={{
            display: {
              xs: 'none',
              sm: 'table-cell',
              width: '90px',
            },
          }}
          render={(position: GQL.IPosition) => (
            <Price
              value={position.commissionPerPoint}
              currency={position.currency}
            />
          )}
        />
        <TableColumn
          label={t(`POSITION.PUBLISHED`)}
          dataIndex="published"
          align="center"
          sx={{
            display: {
              xs: 'none',
              sm: 'table-cell',
              width: '70px',
            },
          }}
          render={({ published }: GQL.IPosition) => (
            <CheckIcon value={published === true} withColor />
          )}
        />
        <TableColumn
          label={t(`POSITION.PRIMARY`)}
          dataIndex="primary"
          align="center"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '70px',
            },
          }}
          render={({ primary }: GQL.IPosition) => (
            <CheckIcon value={primary === true} withColor />
          )}
        />
        <TableColumn
          label={t(`POSITION.PUBLIC`)}
          dataIndex="public"
          align="center"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '70px',
            },
          }}
          render={(row: GQL.IPosition) => (
            <CheckIcon value={row.public === true} withColor />
          )}
        />
        <TableColumn
          label={t(`POSITION.PROGRESSIVE`)}
          dataIndex="progressive"
          align="center"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '70px',
            },
          }}
          render={({ progressive }: GQL.IPosition) => (
            <CheckIcon value={progressive === true} withColor />
          )}
        />
        <TableActions
          render={(row: GQL.IPosition) => {
            return (
              <ButtonDelete
                message={t('POSITION.REALLY_TO_DELETE')}
                right={RIGHTS.POSITION.DELETE}
                onDelete={() => deletePosition({ variables: { id: row.id } })}
                refetch={refetch}
              />
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default PositionTable;
