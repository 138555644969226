import { createConfirmation } from 'react-confirm';
import MyConfirm, { ConfirmOptions } from './MyConfirm';

const confirm = createConfirmation(MyConfirm, 0);

const confirmComponent = function(options: ConfirmOptions) {
  return confirm({ options });
}

export default confirmComponent;
