import React, { ChangeEvent } from 'react';
import { FormControl, FormHelperText, RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { textControlError } from '_template/helpers/forms/controlError';

interface RadioGroupProps extends MuiRadioGroupProps {
  withoutHelperText?: boolean;
  helperText?: string;
  error?: string;
}

const RadioGroup = ({
  withoutHelperText = false,
  error,
  ...props
}: RadioGroupProps) => {
  const formikContext = useFormikContext();

  // TODO: fix uncontrolled change value in this component!

  const onChange = (e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (formikContext) {
      formikContext?.setFieldValue(name, value);
    }
  }

  const controlValue = props.value
    || (props.name && formikContext?.values && (formikContext?.values as any)[props.name]);

  const controlError = error
    || (props.name && formikContext?.errors && (formikContext?.errors as any)[props.name]);

  const helperText = !withoutHelperText ? ' ' : '';

  return (
    <FormControl>
      <MuiRadioGroup 
        {...props} 
        value={controlValue}
        onChange={onChange}
      />
      <FormHelperText error={!!error}>
        {textControlError(controlError) || props.helperText || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default RadioGroup;
