import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICommissionItem } from 'modules/Commission/interfaces';
import CommisssionItemsTable from './CommisssionItemsTable';

interface CommisssionItemsProps {
  items: ICommissionItem[];
}

interface CommissionLineType {
  consultant: GQL.IConsultant;
  position?: GQL.IPosition;
}

const CommisssionItems = ({ items }: CommisssionItemsProps) => {
  const { t } = useTranslation();

  const listConsultants = useMemo(() => {
    return items.reduce((acc: CommissionLineType[], item: ICommissionItem) => {
      if (
        item.directSubordinateConsultant &&
        !acc.find(
          (i: CommissionLineType) =>
            i.consultant.id === item.directSubordinateConsultant?.id
        )
      ) {
        acc.push({
          consultant: item.directSubordinateConsultant,
          position: item.directSubordinateConsultantPosition || undefined,
        });
      }
      return acc;
    }, []);
  }, [items]);

  return (
    <>
      <CommisssionItemsTable
        title={t('COMMISSION_ITEM.FIRST_LINE')}
        items={items.filter((i) => !i.directSubordinateConsultant)}
      />
      {listConsultants.map((line: CommissionLineType) => (
        <CommisssionItemsTable
          title={
            <Trans
              values={{
                consultant: `${line.consultant.number}: ${line.consultant.firstName} ${line.consultant.lastName} (${line.position?.name})`,
              }}
            >
              COMMISSION_ITEM.NEXT_LINE
            </Trans>
          }
          items={items.filter(
            (i) => i.directSubordinateConsultant?.id === line.consultant.id
          )}
        />
      ))}
    </>
  );
};

export default CommisssionItems;
