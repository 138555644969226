import React, { useEffect } from 'react';
// import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { InitContextQuery } from 'query/Context';
import { AuthToken } from 'services/AuthToken';
import { selectProject, selectProjectIndex, setProject } from 'stores/projectSlice';
import { setLanguageList } from 'stores/languageSlice';
import { setCurrencyList, setCurrencyRateList } from 'stores/currencySlice';
import { setLoggedUser } from 'stores/loggedUserSlice';
import { setSettingList } from 'stores/settingSlice';
import { Loader } from '_template';
import { useQuery } from '@apollo/client';

export interface ContextParams {
  projectIndex?: string;
}

interface ContextProps {
  children?: React.ReactNode;
}

const Context = ({ children }: ContextProps) => {
  const dispatch = useDispatch();

  const project = useSelector(selectProject);
  const projectIndex = useSelector(selectProjectIndex);

  // useEffect(() => {
  //   if (i18next && adminLanguage !== i18next.language) {
  //     i18next.changeLanguage(adminLanguage);
  //   }
  // }, [adminLanguage]);

  const initContextResult = useQuery(InitContextQuery, {
    variables: {
      token: AuthToken.getToken(),
    },
    fetchPolicy: 'cache-first',
  });

  const { data, loading } = initContextResult;

  useEffect(() => {
    if (data?.initBackofficeContext) {
      const context = data.initBackofficeContext;
      dispatch(setLanguageList(context.languages));
      // TODO: přesunout do nějakého helperu
      // dispatch(setSelectedLanguage(context.languages.find((l: GQL.ILanguage) => l.primary)));
      dispatch(setCurrencyList(context.currencies));
      dispatch(setCurrencyRateList(context.currencyRates));
      dispatch(setLoggedUser(context.loggedUser));
      dispatch(setSettingList(context.settings));
      dispatch(setProject(context.project));
    }
  }, [dispatch, data, projectIndex]);

  if (loading && !data) {
    return <Loader />;
  }

  // if (error) {
  //   return (
  //     <ErrorScene code={404} />
  //   );
  // }

  if (project && projectIndex) {
    return <>{children}</>;
  }

  return null;
};

export default Context;
