import gql from 'graphql-tag';

export const GetTaxDocumentListQuery: any = gql`
  query taxDocumentList(
    $where: TaxDocumentListWhereInput
    $limit: LimitsInput
    $orderBy: OrderByInput
  ) {
    taxDocumentList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        number
        payment {
          id
          vs
        }
        currency {
          id
          code
        }
        dateCreated
        dateTaxableSupply
        dateDue
        variableSymbol
        constantSymbol
        paymentForm
        subscriberCompanyName
        subscriberFirstName
        subscriberLastName
        subscriberStreet
        subscriberHouseNumber
        subscriberCity
        subscriberZipCode
        subscriberCountry
        subscriberIc
        subscriberDic
        subscriberIcDph
        sumPrice
        vat
        totalPrice
      }
      count
    }
  }
`;

export const RefreshTaxDocumentMutation: any = gql`
  mutation refreshTaxDocument($id: ID!) {
    refreshTaxDocument(id: $id)
  }
`;

export const DeleteTaxDocumentMutation: any = gql`
  mutation deleteTaxDocument($id: ID!) {
    deleteTaxDocument(id: $id)
  }
`;
