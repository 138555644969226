import React from 'react';
import { confirmable } from 'react-confirm';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColor } from '../Button';

export interface ConfirmOptions {
  title: string;
  disableButtons?: boolean;
  hideCrossClose?: boolean;
  description?: string;
  labelSubmit?: string | null;
  labelSubmitVariant?: ButtonColor;
  hideSubmit?: boolean;
  labelCancel?: string | null;
  hideCancel?: boolean;
}

interface MyConfirmProps {
  options: ConfirmOptions;
  cancel: () => void;
  proceed: () => void;
  dismiss: () => void;
}

const MyConfirm = ({
  options = {
    title: 'Message',
    disableButtons: false,
    hideCrossClose: false,
    description: undefined,
    labelSubmit: undefined,
    labelSubmitVariant: 'primary',
    hideSubmit: false,
    labelCancel: undefined,
    hideCancel: false,
  },
  proceed,
  cancel,
  ...props
}: MyConfirmProps) => {
  const { t } = useTranslation();

  const onGloballyPress = (e: any) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      proceed();
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.keyCode === 27) {
      cancel && cancel();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', onGloballyPress, false);

    return () => {
      document.removeEventListener('keydown', onGloballyPress, false);
    };
  });

  const handleSubmit = () => proceed();

  const handleCancel = () => {
    if (cancel) {
      cancel();
    }
  };

  return (
    <Dialog
      fullScreen={false}
      open
      maxWidth="sm"
      fullWidth
      onClose={props.dismiss}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" sx={{ p: 3, pb: 2 }}>
        {options.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {options.description}
        </DialogContentText>
      </DialogContent>
      {options.disableButtons !== true && (
        <DialogActions sx={{ p: 3, pt: 2 }}>
          {options.hideCancel !== true && (
            <Button variant="outlined" autoFocus onClick={handleCancel}>
              {options.labelCancel || t('GENERAL.CANCEL')}
            </Button>
          )}
          {!!proceed && options.hideSubmit !== true && (
            <Button variant="outlined" onClick={handleSubmit} autoFocus color={options.labelSubmitVariant}>
              {options.labelSubmit || t('GENERAL.YES')}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default confirmable(MyConfirm);
