import React from 'react';
import { Badge, Box, Grid } from '@mui/material';

// import useAuth from '../../hooks/useAuth';

const SidebarFooter = ({ ...rest }) => {
  // const { user } = useAuth();
  const user = null;

  if (!user) {
    return null;
  }

  return (
    <Box
      sx={(theme: any) => ({
        backgroundColor: `${theme.sidebar.footer.background} !important`,
        px: 4,
        py: 2.75,
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      })}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            sx={(theme: any) => ({
              mr: 1,
              "& span": {
                backgroundColor: theme.sidebar.footer.online.background,
                border: `1.5px solid ${theme.palette.common.white}`,
                height: '12px',
                width: '12px',
                borderRadius: '50%',
              }
            })}
          >
            {/* {!!user && <Avatar alt={user.displayName} src={user.avatar} />}
            {/* Demo data */}
            {/* {!user && (
              <Avatar
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            )} */}
          </Badge>
        </Grid>
        <Grid item>
          {/* {!!user && (
            <FooterText variant="body2">{user.displayName}</FooterText>
          )} */}
          {/* Demo data */}
          {/* {!user && <FooterText variant="body2">Lucy Lavender</FooterText>}
          <FooterSubText variant="caption">UX Designer</FooterSubText> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SidebarFooter;
