import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import currencyReducer from './currencySlice';
import languageReducer from './languageSlice';
import projectReducer from './projectSlice';
import settingReducer from './settingSlice';
import loggedUserReducer from './loggedUserSlice';

export const rootReducer = {
  app: appReducer,
  currency: currencyReducer,
  language: languageReducer,
  project: projectReducer,
  setting: settingReducer,
  loggedUser: loggedUserReducer,
};

export const store = configureStore({
  reducer: {
    ...rootReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


