import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Card, Table, TableContainer, TableColumn, TableActions } from '_template';
import { DeleteUserGroupMutation, GetUserGroupListQuery } from 'modules/UserGroup/query';
import ButtonDelete from 'components/ButtonDelete';
import { ROUTE } from 'constants/route';
import { GetUserListQuery } from 'modules/User/query';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import Link from 'components/Link';
import { RIGHTS } from 'constants/rights';

const UserGroupTable = () => {
  const { t } = useTranslation();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs('userGroup', {
    sortField: 'name',
    sortBy: 'asc',
  });

  const userGroupListQuery = useQuery(GetUserGroupListQuery, {
    variables: {
      where: {
      },
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network'
  });

  const { loading, error, data } = userGroupListQuery;

  const userListQuery = useQuery(GetUserListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const refetch = () => userGroupListQuery.refetch();

  const [deleteMutation] = useMutation(DeleteUserGroupMutation);

  const getUsersCount = useCallback((userGroupId: string) => {
    return (userListQuery.data?.userList?.rows || []).filter((u: GQL.IUser) => u.userGroup.id === userGroupId).length;
  }, [userListQuery]);

  return (
    <TableContainer component={Card}>
      <Table<GQL.IUserGroup>
        dataSource={data?.userGroupList?.rows}
        recordsCount={data?.userGroupList?.rows?.length}
        loading={loading}
        error={error}
        {...tableProps}
      >
        <TableColumn
          label={t('USER_GROUP.NAME')}
          dataIndex="name"
          sortable
          defaultSortBy="asc"
          render={(row: GQL.IUserGroup) => (
            <Link
              component={NavLink}
              to={ROUTE.USER_GROUP_DETAIL.replace(':recordId', row.id)}
              right={RIGHTS.USER_GROUP.EDIT}
            >
              {row.name}
            </Link>
          )}
        />
        <TableActions
          render={(row: GQL.IUserGroup) => {
            return (
              <ButtonDelete
                message={t('USER_GROUP.REALLY_TO_DELETE')}
                disabled={getUsersCount(row.id) > 0}
                right={RIGHTS.USER_GROUP.DELETE}
                onDelete={() => deleteMutation({ variables: { id: row.id } })}
                refetch={refetch}
              />
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default UserGroupTable;
