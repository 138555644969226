import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Download } from '@mui/icons-material';
import ButtonAdd from 'components/ButtonAdd';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';
import { useMutation } from '@apollo/client';
import {
  GetPaymentListQuery,
  ImportPaymentsMutation,
} from 'modules/Payment/query';

const Actions = () => {
  const [importLoading, setImportLoading] = useState(false);
  const [importMutation] = useMutation(ImportPaymentsMutation);

  const onImportPayments = () => {
    setImportLoading(true);
    importMutation({
      refetchQueries: [GetPaymentListQuery],
    }).finally(() => setImportLoading(false));
  };

  return (
    <>
      <ButtonAdd
        size="small"
        to={ROUTE.PAYMENT_DETAIL.replace(':recordId', 'new')}
        right={RIGHTS.PAYMENT.ADD}
        sx={{ mr: 2 }}
      >
        <Trans>PAYMENT.NEW</Trans>
      </ButtonAdd>
      <ButtonAdd
        size="small"
        loading={importLoading}
        disabled={importLoading}
        startIcon={<Download />}
        onClick={() => onImportPayments()}
        right={RIGHTS.PAYMENT.ADD}
      >
        <Trans>PAYMENT.IMPORT</Trans>
      </ButtonAdd>
    </>
  );
};

export default Actions;
