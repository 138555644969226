import gql from 'graphql-tag';

export const GetSettingListQuery: any = gql`
  query settingList {
    settingList {
      id
      name
      value
    }
  }
`;

export const UpdateSettingsMutation: any = gql`
  mutation updateSettings($settings: JSON!) {
    updateSettings(settings: $settings)
  }
`;
