const components = {
  MuiGrid: {
    styleOverrides: {
      root: {
        marginTop: 0,
        '&.MuiGrid-item': {
          paddingTop: 0,
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '& .MuiButton-startIcon svg': {
          width: '20px',
          height: '20px',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        '&:is(strong)': {
          fontWeight: '700 !important',
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        "& > span": {
          fontWeight: "500",
        },
      },
    },
  },
  MuiImageListItem: {
    styleOverrides: {
      root: {
        border: '1px solid #EFEFEF',
        padding: '8px',
      },
    },
  },
  MuiImageListItemBar: {
    styleOverrides: {
      root: {
        margin: '6px 2px 1px',
      },
      actionIcon: {
        display: 'flex',
      },
      titleWrap: {
        padding: 0,
      },
      title: {
        paddingTop: '7px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
        boxShadow: 'none',
        borderRadius: '2px',
        border: '1px solid #eee',
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        "&:last-child": {
          paddingBottom: '6px',
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "&:last-child td, &:last-child th": {
          borderBottom: '1px solid #e0e0e0 !important',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        ":not(:first-of-type):not(:last-of-type)": {
          paddingLeft: '5px',
          paddingRight: '5px',
        },
        "& > svg": {
          width: '20px',
          height: '20px',
          marginTop: '2px',
          marginBottom: '-2px',
        },
      },
      sizeMedium: {
        lineHeight: '1rem',
        padding: '15px',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
        boxShadow:
          "rgba(50, 50, 93, 0.035) 0px 1px 5px 1px, rgba(0, 0, 0, 0.07) 0px 1px 3px 1px",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        marginBottom: '2px',
        fontWeight: 500,
        fontSize: 'small',
        color: 'black',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginTop: '0px',
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          backgroundColor: 'rgba(200, 20, 20, .1)',
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      color: 'secondary',
      variant: 'outlined',
      size: 'small',
      autoComplete: 'off',
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      'smallSize': {
        paddingTop: '2px',
        paddingLeft: '2px',
        paddingBottom: '2px',
      },
      'tag': {
        margin: '1px',
        padding: 0,
        height: '26px',
        borderRadius: '3px',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiSwitch: {
    styleOverrides: {
      'thumb': {
        boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: '20px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '20px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        textAlign: 'right',
        paddingRight: '16px',
        paddingBottom: '16px',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
        boxShadow: 'none',
        borderRadius: '2px',
      },
    },
  },
};

export default components;
