import React from 'react';
import Currency from 'react-currency-formatter';

interface PriceProps {
  value?: number | null;
  currency?: GQL.ICurrency | null;
  displayEmpty?: boolean;
}

const Price = ({ value, currency, displayEmpty = false }: PriceProps) => {
  if (!value && !displayEmpty) {
    return null;
  }

  return (
    // @ts-ignore
    <Currency
      decimal=","
      quantity={value || 0}
      currency={currency ? currency.code : undefined}
      pattern={
        currency && currency.code.toUpperCase() === 'CZK'
          ? '#,##0.00 !'
          : undefined
      }
    />
  );
};

export default Price;
