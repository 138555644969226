import React from 'react';
import { ColumnProps } from '../Column';

export const ActionColumnSx = {
  width: '50px !important',
  textAlign: 'right',
  padding: '4px 10px',
  textWrap: 'nowrap',
};

class Actions extends React.Component<ColumnProps> {}

export default Actions;
