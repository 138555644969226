import React from 'react';
import {
  Grid,
  List,
  ListItemText,
  ListItemButton,
  Box,
} from '@mui/material';

interface FooterProps {
  links: { [key: string]: string };
}

const Footer = ({ links }: FooterProps) => {
  return (
    <Box
      sx={(theme: any) => ({
        px: 4,
        py: 0.25,
        position: 'relative',
        background: theme.footer.background,
      })}
    >
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: 'none', md: 'block' } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            {Object.keys(links).map((url: string) => (
              <ListItemButton
                key={url}
                component="a"
                href={url}
                sx={{
                  display: 'inline-block',
                  width: 'auto',
                  px: 2,
                }}
              >
                <ListItemText
                  primary={links[url]}
                  sx={(theme: any) => ({
                    '& span': {
                      color: theme.footer.color,
                    },
                  })}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton
              component="a"
              sx={{
                display: 'inline-block',
                width: 'auto',
                px: 2,
              }}
            >
              <ListItemText
                primary={`© ${new Date().getFullYear()}`} // - Shopito
                sx={(theme: any) => ({
                  '& span': {
                    color: theme.footer.color,
                  },
                })}
              />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
