
import React from 'react';
import { ButtonProps } from '@mui/material';
import Button from '../Button';

const SmallButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      size="small"
      {...props}
      sx={{
        p: '4px',
        minWidth: 0,
        '& > svg': {
          width: '0.9em',
          height: '0.9em',
        },
        ...props.sx,
      }}
    >
      {children}
    </Button>
  );
};

export default SmallButton;
