import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Helmet from 'components/Helmet';
import Actions from './components/Actions';
import CommissionTable from './components/CommissionTable';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';

const CommissionList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('COMMISSION.MODEL_TITLE')} />
      <ContentHeader
        title={t('COMMISSION.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={<Breadcrumbs section={t('COMMISSION.MODEL_TITLE')} />}
      />

      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.COMMISSION.LIST}>
        <CommissionTable />
      </LimitedAccess>
    </>
  );
};

export default CommissionList;
