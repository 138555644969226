import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Box,
  FileSize,
  Link,
  Table,
  TableActions,
  TableColumn,
  TableContainer,
  Typography,
} from '_template';
import { DeleteFileMutation, GetFileListQuery } from 'modules/File/query';
import DateTime from 'components/DateTime';
import ButtonDelete from 'components/ButtonDelete';

interface ConsultantFilesListProps {
  filesId?: string;
}

interface FileInList extends GQL.IFile {
  level: number;
}

const ConsultantFilesList = ({ filesId }: ConsultantFilesListProps) => {
  const { t } = useTranslation();

  const fileListQuery = useQuery(GetFileListQuery, {
    variables: {
      where: {
        filesId,
        isSystem: false,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data, loading, error, refetch } = fileListQuery;

  const [deleteFile] = useMutation(DeleteFileMutation);

  const getFilesIn = useCallback(
    (parentId: string | null, level: number): FileInList[] => {
      return (data?.fileList?.rows || [])
        .filter(
          (f: GQL.IFile) => f.parent === parentId || f.parent?.id === parentId
        )
        .reduce((acc: FileInList[], f: GQL.IFile) => {
          acc.push({
            ...f,
            level,
          });

          const subFiles = getFilesIn(f.id, level + 1);
          if (subFiles.length > 0) {
            subFiles.forEach((f: FileInList) => {
              acc.push(f);
            });
          }

          return acc;
        }, []);
    },
    [data?.fileList?.rows]
  );

  const files = useMemo<FileInList[]>(() => getFilesIn(null, 0), [getFilesIn]);

  return (
    <>
      <TableContainer>
        <Table<GQL.IFile>
          dataSource={files}
          recordsCount={files.filter((f) => f.id).length}
          loading={loading}
          error={error}
          disablePaginate
        >
          <TableColumn
            label={t('FILE.FILE_NAME')}
            dataIndex="fileName"
            sx={{
              py: 0,
              height: '47px',
              width: '40%',
            }}
            render={(row: FileInList) => (
              <Box sx={{ display: 'flex', pl: `${(row?.level || 0) * 30}px` }}>
                {row.fileType === 'folder' ? (
                  <FolderIcon fontSize="small" sx={{ mr: 2 }} />
                ) : (
                  <DescriptionIcon fontSize="small" sx={{ mr: 2 }} />
                )}
                {row.fileType !== 'folder' && row.storageLink ? (
                  <Link
                    component={NavLink}
                    to={row.storageLink}
                    target="_blank"
                  >
                    <Typography>{row.fileName}</Typography>
                  </Link>
                ) : (
                  <Typography>{row.fileName}</Typography>
                )}
              </Box>
            )}
          />
          <TableColumn
            label={t('FILE.FILE_TYPE')}
            dataIndex="fileType"
            sx={{
              width: '20%',
            }}
            render={(row: GQL.IFile) => {
              return row.fileType === 'folder'
                ? t('FILE.FOLDER')
                : row.fileType;
            }}
          />
          <TableColumn
            label={t('FILE.FILE_SIZE')}
            dataIndex="fileSize"
            sx={{
              width: '20%',
            }}
            render={(row: GQL.IFile) => {
              return (
                row.fileType !== 'folder' && <FileSize value={row.fileSize} />
              );
            }}
          />
          <TableColumn
            label={t('FILE.CREATED_AT')}
            dataIndex="createdAt"
            align="right"
            render={({ createdAt }: GQL.IFile) => (
              <DateTime value={createdAt} />
            )}
          />
          <TableActions
            render={(row: GQL.IFile) => {
              return (
                <ButtonDelete
                  message={t('FILE.REALLY_TO_DELETE')}
                  onDelete={() => deleteFile({ variables: { id: row.id } })}
                  refetch={refetch}
                />
              );
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default ConsultantFilesList;
