import gql from 'graphql-tag';

export const GetCurrencyListQuery: any = gql`
  query currencyList($where: CurrencyListWhereInput, $limit: LimitsInput, $orderBy: OrderByInput) {
    currencyList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        name
        code
        exchangeRate
        iban
        bic
        primary
        priority
        published
      }
      count
    }
  }
`;

export const GetCurrencyQuery: any = gql`
  query currency($where: CurrencyWhereInput) {
    currency(where: $where) {
      id
      name
      code
      exchangeRate
      iban
      bic
      primary
      priority
      published
    }
  }
`;

export const CreateCurrencyMutation: any = gql`
  mutation createCurrency($data: CreateOrUpdateCurrencyInput) {
    createCurrency(data: $data) {
      id
    }
  }
`;

export const UpdateCurrencyMutation: any = gql`
  mutation updateCurrency($id: ID!, $data: CreateOrUpdateCurrencyInput) {
    updateCurrency(id: $id, data: $data) {
      id
    }
  }
`;

export const DeleteCurrencyMutation: any = gql`
  mutation deleteCurrency($id: ID!) {
    deleteCurrency(id: $id)
  }
`;
