import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface CurrencyState {
  currency: GQL.ICurrency | null;
  currencyList: GQL.ICurrency[] | null;
  currencyRateList: GQL.ICurrencyRate[] | null;
}

const initialState: CurrencyState = {
  currency: null,
  currencyList: null,
  currencyRateList: null,
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<GQL.ICurrency>) => {
      state.currency = action.payload;
    },
    setCurrencyList: (state, action: PayloadAction<GQL.ICurrency[]>) => {
      state.currencyList = action.payload;
    },
    setCurrencyRateList: (state, action: PayloadAction<GQL.ICurrencyRate[]>) => {
      state.currencyRateList = action.payload;
    },
  },
});

export const {
  setCurrency,
  setCurrencyList,
  setCurrencyRateList
} = currencySlice.actions;

export const selectCurrency = (state: RootState) => state.currency.currency;
export const selectCurrencyList = (state: RootState) => state.currency.currencyList;

export default currencySlice.reducer;
