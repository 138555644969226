import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GetSettingListQuery, UpdateSettingsMutation } from '../query/Setting';

interface ValueType {
  [key: string]: string | null;
}

const useSettingContext = () => {
  const { loading, ...settingQuery } = useQuery(GetSettingListQuery);
  const [updateSettingsMutation] = useMutation(UpdateSettingsMutation);

  const submitSetting = (settings: any) => {
    // list only changed values
    const newSettings = Object.keys(settings).reduce((acc: any, key: string) => {
      if (settings[key] !== values[key]) {
        acc[key] = settings[key];
      }
      return acc;
    }, {});

    // is anything to save
    if (Object.keys(newSettings).length > 0) {
      updateSettingsMutation({
        variables: {
          settings: newSettings,
        },
        refetchQueries: [
          'settingList',
        ],
      });
    }
  };

  const values = useMemo(() => {
    const v: ValueType = {};
    settingQuery?.data?.settingList?.forEach((setting: GQL.ISetting) => {
      v[setting.name] = setting.value;
    });
    return v;
  }, [settingQuery?.data?.settingList]);

  return {
    values,
    loading,
    submitSetting,
  };
};

export default useSettingContext;
