import React from 'react';
import prettyBytes from 'pretty-bytes';

interface FileSizeProps {
  value?: number | null;
}

const FileSize = ({ value }: FileSizeProps) => {
  if (!value) {
    return null;
  }

  return <>{prettyBytes(value)}</>;
};

export default FileSize;