import { ModuleRightType } from 'constants/rights';

export const taxDocumentRights: ModuleRightType = {
  TAX_DOCUMENT: {
    LIST: 'TAX_DOCUMENT.LIST',
    // ADD: 'TAX_DOCUMENT.ADD',
    // EDIT: 'TAX_DOCUMENT.EDIT',
    DELETE: 'TAX_DOCUMENT.DELETE',
  },
};
