import React from 'react';
import { ButtonAdd as TemplateButtonAdd, ButtonProps } from '_template';
import { useUserAccess } from 'hooks/useUserAccess';

interface ButtonAddProps extends ButtonProps {
  right?: string;
}

const ButtonAdd = ({ right, ...props }: ButtonAddProps) => {
  const { getAccess } = useUserAccess();

  return (
    <TemplateButtonAdd
      {...props}
      disabled={right ? !getAccess(right) : props.disabled}
    />
  );
};

export default ButtonAdd;
