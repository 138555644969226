import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { ColumnProps } from '../Column';
import Actions, { ActionColumnSx } from '../Actions';

interface DataProps {
  column: React.Component<ColumnProps>;
  data: any;
  index: number;
  sx?: any;
  onClick: (e: any) => void;
  onDoubleClick?: () => void;
}

const Data = ({
  column,
  data,
  index,
  sx = {},
  ...props
}: DataProps) => {
  const { props: columnProps } = column;

  const onClick = () => {
    if (!(column && column instanceof Actions)) {
      props.onClick(data);
    }
  };

  const onDoubleClick = () => {
    if (!(column && column instanceof Actions) && props.onDoubleClick) {
      props.onDoubleClick();
    }
  };

  return (
    <TableCell
      {...props}
      sx={{
        textAlign: columnProps.align,
        ...((column as any).type === Actions ? ActionColumnSx : {}),
        ...(typeof sx === 'function' ? sx(data) : sx),
      }}
      onDoubleClick={onDoubleClick}
      onClick={onClick}
    >
      {/* @ts-ignore */}
      {columnProps.render
        ? columnProps.render(data, index)
        : columnProps.dataIndex && data && columnProps.dataIndex in data && typeof data[columnProps.dataIndex] !== 'object'
          ? <Typography>{data[columnProps.dataIndex]}</Typography>
          : <i />}
    </TableCell>
  );
};

export default Data;
