import { ModuleRightType } from 'constants/rights';

export const userGroupRights: ModuleRightType = {
  USER_GROUP: {
    LIST: 'USER_GROUP.LIST',
    ADD: 'USER_GROUP.ADD',
    EDIT: 'USER_GROUP.EDIT',
    DELETE: 'USER_GROUP.DELETE',
  },
};
