import Cookies from './Cookies';

export class AuthToken {

  public static storeName = 'authBackofficeToken';

  static getToken = (): string | null => {
    return Cookies.get(AuthToken.storeName);
  }

  static setToken = (token: string) => {
    return Cookies.set(AuthToken.storeName, token, 60 * 60 * 24 * 365);
  }

  static removeToken = () => {
    console.log('AuthToken.removeToken()');
    Cookies.remove(AuthToken.storeName);
  }
}
