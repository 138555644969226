import gql from 'graphql-tag';

export const GetPresentationTypeListQuery: any = gql`
  query presentationTypeList($limit: LimitsInput, $orderBy: OrderByInput) {
    presentationTypeList(limit: $limit, orderBy: $orderBy) {
      rows {
        id
        emailTemplate {
          id
          name
        }
        confirmEmailTemplate {
          id
          name
        }
        category
        name
        published
      }
      count
    }
  }
`;

export const GetPresentationTypeQuery: any = gql`
  query presentationType($where: PresentationTypeWhereInput!) {
    presentationType(where: $where) {
      id
      emailTemplate {
        id
        name
      }
      confirmEmailTemplate {
        id
        name
      }
      category
      name
      published
    }
  }
`;

export const CreatePresentationTypeMutation: any = gql`
  mutation createPresentationType($data: CreateOrUpdatePresentationTypeInput) {
    createPresentationType(data: $data) {
      id
    }
  }
`;

export const UpdatePresentationTypeMutation: any = gql`
  mutation updatePresentationType($id: ID!, $data: CreateOrUpdatePresentationTypeInput) {
    updatePresentationType(id: $id, data: $data) {
      id
    }
  }
`;

export const DeletePresentationTypeMutation: any = gql`
  mutation deletePresentationType($id: ID!) {
    deletePresentationType(id: $id)
  }
`;
