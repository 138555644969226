import { CurrencyFormat } from '../interfaces';

export const currencyCodes: { [key: string]: CurrencyFormat } = {
  'CZK': {
    name: 'Czech koruna',
    prefix: '',
    suffix: ' Kč',
    thousandSeparator: ' ',
    decimalSeparator: ','
  },
  'EUR': {
    name: 'Euro',
    prefix: '€ ',
    suffix: '',
    thousandSeparator: ',',
    decimalSeparator: '.'
  },
  'USD': {
    name: 'American dollar',
    prefix: '$ ',
    suffix: '',
    thousandSeparator: ',',
    decimalSeparator: '.'
  }
};

export const DefaultCurrencyFormat: CurrencyFormat = {
  name: '',
  prefix: '',
  suffix: '',
  thousandSeparator: ' ',
  decimalSeparator: ','
};

