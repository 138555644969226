import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  TableContainer,
  Table,
  TableColumn,
  TableActions,
  CheckIcon,
  Typography,
  Card,
} from '_template';
import {
  DeleteCurrencyMutation,
  GetCurrencyListQuery,
} from 'modules/Currency/query';
import ButtonDelete from 'components/ButtonDelete';
import { ROUTE } from 'constants/route';
import Link from 'components/Link';
import IBAN from 'iban';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { RIGHTS } from 'constants/rights';

const CurrencyTable = () => {
  const { t } = useTranslation();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs('currency', {
    sortField: 'primary',
    sortBy: 'asc',
  });

  const currencyListQuery = useQuery(GetCurrencyListQuery, {
    variables: {
      where: {},
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, error } = currencyListQuery;

  const refetch = () => currencyListQuery.refetch();

  const [deleteMutation] = useMutation(DeleteCurrencyMutation);

  return (
    <TableContainer component={Card}>
      <Table<GQL.ICurrency>
        dataSource={data?.currencyList?.rows}
        recordsCount={data?.currencyList?.count}
        loading={loading}
        error={error}
        {...tableProps}
      >
        <TableColumn
          label={t('CURRENCY.CURRENCY')}
          dataIndex="name"
          sortable
          render={(row: GQL.ICurrency) => (
            <Link
              component={NavLink}
              to={ROUTE.CURRENCY_DETAIL.replace(':recordId', row.id)}
              right={RIGHTS.CURRENCY.EDIT}
            >
              <Typography>
                {row.code} - {row.name}
              </Typography>
            </Link>
          )}
        />
        <TableColumn
          label={t('CURRENCY.ACCOUNT_NUMBER')}
          dataIndex="iban"
          sortable
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
          render={(currency: GQL.ICurrency) => {
            const iban = IBAN.printFormat(currency.iban || '', ' ');
            return <Typography>{`${iban} / ${currency.bic || ''}`}</Typography>;
          }}
        />
        <TableColumn
          label={t('CURRENCY.EXCHANGE_RATE')}
          dataIndex="exchangeRate"
          align="right"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '100px',
            },
          }}
        />
        <TableColumn
          label={t(`CURRENCY.PUBLISHED`)}
          dataIndex="published"
          align="center"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '70px',
            },
          }}
          render={({ published }: GQL.ICurrency) => (
            <CheckIcon value={published === true} withColor />
          )}
        />
        <TableColumn
          label={t(`CURRENCY.PRIMARY`)}
          dataIndex="primary"
          sortable
          align="center"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '70px',
            },
          }}
          render={({ primary }: GQL.ICurrency) => (
            <CheckIcon value={primary === true} withColor />
          )}
        />
        <TableActions
          render={(row: GQL.ICurrency) => {
            return (
              <ButtonDelete
                message={t('CURRENCY.REALLY_TO_DELETE')}
                disabled={!!row.primary}
                right={RIGHTS.CURRENCY.DELETE}
                onDelete={() => deleteMutation({ variables: { id: row.id } })}
                refetch={refetch}
              />
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default CurrencyTable;
