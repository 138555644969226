import React from 'react';
import { GetBankAccountQuery } from 'modules/BankAccount/query/BankAccount';
import ContainerDetailWrapper from 'components/ContainerDetailWrapper';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';
import BankAccountForm from './BankAccountForm';

const BankAccountDetail = () => (
  <ContainerDetailWrapper
    query={GetBankAccountQuery}
    parseQueryData={(data) => data?.bankAccount || null}
    languagePrefix="BANK_ACCOUNT"
    routeList={ROUTE.BANK_ACCOUNT_LIST}
    rights={RIGHTS.BANK_ACCOUNT}
  >
    {({ recordId, data }) => (
      <BankAccountForm
        recordId={recordId}
        data={{
          ...data,
          published: data ? data.published : true,
        }}
      />
    )}
  </ContainerDetailWrapper>
);

export default BankAccountDetail;
