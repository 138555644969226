import React from 'react';
import { useFormikContext } from 'formik';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField, TextFieldProps } from '_template';
import { currencyCodes, DefaultCurrencyFormat } from 'modules/Currency/constants/currencyCodes';
import { CurrencyFormat } from 'modules/Currency/interfaces';

type PriceFieldProps = TextFieldProps & NumericFormatProps & {
  currency?: GQL.ICurrency;
};

const PriceField = ({ currency, ...props }: PriceFieldProps) => {
  /* eslint-disable-next-line */
  const formikContext = useFormikContext();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    if (formikContext) {
      formikContext?.setFieldValue(props.name!, value);
    }
  };

  const controlValue = props.value
    || (props.name && formikContext?.values && (formikContext?.values as any)[props.name]);

  const currencyFormatting: CurrencyFormat = currency?.code && currencyCodes[currency?.code]
    ? currencyCodes[currency?.code]
    : DefaultCurrencyFormat;

  return (
    <NumericFormat
      allowNegative={false}
      allowedDecimalSeparators={[',']}
      thousandSeparator={currencyFormatting.thousandSeparator}
      decimalScale={2}
      decimalSeparator={currencyFormatting.decimalSeparator}
      prefix={currencyFormatting.prefix}
      suffix={currencyFormatting.suffix}
      value={controlValue}
      {...props}
      customInput={TextField}
      onValueChange={(values, sourceInfo) => {
        onChange({
          ...sourceInfo?.event,
          target: {
            ...sourceInfo?.event?.target,
            // @ts-ignore
            value: values.floatValue,
          },
        });
      }}
    />
  );
};

export default PriceField;
