import { ModuleRightType } from 'constants/rights';

export const presentationTypeRights: ModuleRightType = {
  PRESENTATION_TYPE: {
    LIST: 'PRESENTATION_TYPE.LIST',
    ADD: 'PRESENTATION_TYPE.ADD',
    EDIT: 'PRESENTATION_TYPE.EDIT',
    DELETE: 'PRESENTATION_TYPE.DELETE',
  },
};
