import React, { ReactNode, SyntheticEvent, useState } from 'react';
import { Tabs as MuiTabs, Tab, Card, CardProps, CardContent, Grid } from '@mui/material';
import TabPanel from './TabPanel';
import Box from '../Box';

interface TabsProps extends CardProps {
  defaultTab?: number;
  onTabChange?: (tabIndex: number) => Promise<boolean>;
  panelsToCard?: boolean;
  children?: ReactNode;
}

const Tabs = ({
  panelsToCard = true,
  onTabChange,
  ...props
}: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  const onTabChangeLocal = async (event: SyntheticEvent, value: number) => {
    if (!onTabChange || (!!onTabChange && await onTabChange(value))) {
      setSelectedTab(value);
    }
  };

  const tabsContent = (
    <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
      {/* @ts-ignore */}
      <MuiTabs value={selectedTab} onChange={onTabChangeLocal} {...props}>
        {(children as Array<any>)
          .filter((child) => child.type === TabPanel)
          .map((child, index) => (
            <Tab key={`tab-${index}`} label={child.props.label} value={index} />
          ))}
      </MuiTabs>
    </Box>
  );

  const tabPanelsContent = (
    <>
      {(children as Array<any>)
        .filter((child) => child.type === TabPanel)
        .map((child, index) => (
          <TabPanel key={`tab-panel-${index}`} label={child.props.label} value={selectedTab} index={index}>
            {child.props.children}
          </TabPanel>
        ))}
    </>
  );

  return (
    <>
      {panelsToCard ? (
        <>
          <Card sx={props.sx}>
            <CardContent sx={{ pt: 1 }}>
              {tabsContent}
              {tabPanelsContent}
            </CardContent>
          </Card>
        </>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            {tabsContent}
            {tabPanelsContent}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Tabs;
