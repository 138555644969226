import React, { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from '_template';
import { PositionConditionData } from 'modules/Position/interfaces/position';
import NumberField from 'components/NumberField';
import { PositionSelect } from 'modules/Position/components';

interface PositionConditionDialogProps {
  data: PositionConditionData | null;
  onSubmit: (data: PositionConditionData) => void;
  onClose: () => void;
}

const PositionConditionDialog = ({ data, onSubmit, onClose }: PositionConditionDialogProps) => {
  const { t } = useTranslation();

  const [alert, setAlert] = useState<boolean>(false);
  const [formData, setFormData] = useState<PositionConditionData>(data || {});

  const isNew = !data;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setAlert(false);
    if (!formData.firstLevelSize && !formData.teamSize && !formData.sumAmount) {
      setAlert(true);
    } else {
      onSubmit(formData);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        {isNew ? <Trans>POSITION_CONDITION.NEW</Trans> : <Trans>POSITION_CONDITION.EDIT</Trans>}
      </DialogTitle>
      <DialogContent>
        {alert && (
          <Alert severity='error' sx={{ mb: 4 }}>
            <Trans>POSITION_CONDITION.ENTER_ANY_CONDITION_VALUE</Trans>
          </Alert>
        )}
        <DialogContentText mb={1}>
          <Trans>POSITION_CONDITION.TEAM_FIRST_LEVEL_SIZE_CONDITIONS</Trans>
        </DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <NumberField
              autoFocus
              label={t('POSITION_CONDITION.FIRST_LEVEL_SIZE')}
              name='firstLevelSize'
              value={formData?.firstLevelSize}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PositionSelect
              multiple
              disabled={!((formData?.firstLevelSize || 0) > 0)}
              label={t('POSITION_CONDITION.FIRST_LEVEL_ON_POSITIONS')}
              name='firstLevelOnPositions'
              value={formData?.firstLevelOnPositions}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mb: 4 }} />
        <DialogContentText mb={1}>
          <Trans>POSITION_CONDITION.TEAM_SIZE_CONDITIONS</Trans>
        </DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <NumberField
              label={t('POSITION_CONDITION.TEAM_SIZE')}
              name='teamSize'
              value={formData?.teamSize}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mb: 4 }} />
        <DialogContentText mb={1}>
          <Trans>POSITION_CONDITION.AMOUNT_CONDITIONS</Trans>
        </DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <NumberField
              label={t('POSITION_CONDITION.SUM_AMOUNT')}
              name='sumAmount'
              value={formData?.sumAmount}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleSubmit}>
          <Trans>FORM.SAVE</Trans>
        </Button>
        <Button variant='outlined' onClick={onClose}>
          <Trans>FORM.CANCEL</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PositionConditionDialog;
