import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PatternIcon from '@mui/icons-material/Pattern';
import { Card, CardContent } from '_template';
import SettingTitle from 'modules/Setting/components/SettingTitle';
import SettingLayout from 'modules/Setting/components/SettingLayout';
import SettingValue from 'modules/Setting/components/SettingValue';
import CompanySetting from './CompanySetting';
import NumberSetting from './NumberSetting';
import BankAccountSetting from './BankAccountSetting';

const TaxDocumentSetting = () => {
  const { t } = useTranslation();

  return (
    <SettingLayout>
      <SettingTitle icon={<PatternIcon />}>
        <Trans>TAX_DOCUMENT.MODEL_TITLE</Trans>
      </SettingTitle>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <SettingValue
            label={t('TAX_DOCUMENT.SETTING.COMPANY')}
          >
            <CompanySetting />
          </SettingValue>
        </CardContent>
      </Card>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <SettingValue
            label={t('TAX_DOCUMENT.SETTING.BANK_ACCOUNT')}
          >
            <BankAccountSetting />
          </SettingValue>
        </CardContent>
      </Card>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <SettingValue
            label={t('TAX_DOCUMENT.SETTING.NUMBERS')}
          >
            <NumberSetting />
          </SettingValue>
        </CardContent>
      </Card>
    </SettingLayout>
  );
};

export default TaxDocumentSetting;
