import gql from 'graphql-tag';

export const GetPresentationListQuery: any = gql`
  query presentationList($where: PresentationListWhereInput, $limit: LimitsInput, $orderBy: OrderByInput) {
    presentationList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        consultant {
          id
        }
        participants {
          id
          confirmed
        }
        name
        category
        location
        deadline
        end
        limit
      }
      count
    }
  }
`;

// export const GetPresentationQuery: any = gql`
//   query position($where: PresentationWhereInput!) {
//     position(where: $where) {
//       id
//       parent {
//         id
//         code
//         name
//       }
//       currency {
//         id
//         code
//       }
//       conditions {
//         id
//         firstLevelSize
//         firstLevelOnPresentations
//         teamSize
//         sumAmount
//       }
//       code
//       name
//       primary
//       priority
//       published
//       progressive
//       registrationFee
//       commissionPerPoint
//       commissionPoints
//     }
//   }
// `;

export const CreatePresentationMutation: any = gql`
  mutation createPresentation($data: CreateOrUpdatePresentationInput!) {
    createPresentation(data: $data) {
      id
    }
  }
`;

export const UpdatePresentationMutation: any = gql`
  mutation updatePresentation($id: ID!, $data: CreateOrUpdatePresentationInput!) {
    updatePresentation(id: $id, data: $data) {
      id
    }
  }
`;

export const DeletePresentationMutation: any = gql`
  mutation deletePresentation($id: ID!) {
    deletePresentation(id: $id)
  }
`;

export const ResendInviationToPresentationMutation: any = gql`
  mutation resendInviationToPresentation($id: ID!) {
    resendInviationToPresentation(id: $id)
  }
`;
