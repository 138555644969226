import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDelete as TempButtonDelete, ButtonProps, Confirm } from '_template';
import { displayAlert, displaySuccess } from '_template/utils/alerts';
import { useUserAccess } from 'hooks/useUserAccess';

interface ButtonDeleteProps extends ButtonProps {
  message?: string | null;
  dialogDescription?: string;
  active?: boolean;
  quickEffect?: boolean;
  right?: string;
  onSubmit?: () => void;
  onDelete?: () => Promise<any>;
  refetch: () => void;
}

const ButtonDelete = ({
  message,
  dialogDescription,
  active,
  quickEffect,
  right,
  onSubmit,
  onDelete,
  refetch,
  ...props
}: ButtonDeleteProps) => {
  const { t } = useTranslation();
  const { getAccess } = useUserAccess();

  const disabled = props.disabled || !!(right && !getAccess(right));

  const onProcessDelete = (): void => {
    if (disabled) {
      return;
    }
    if (!!onDelete) {
      onDelete()
        .then(() => {
          displaySuccess(t('GENERAL.RECORD_DELETED'));
          refetch();
        });
    }
  };

  const onClickDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }

    if (quickEffect === true && !!onDelete) {
      onProcessDelete();
      return;
    }

    if (active !== false) {
      const confirmMessage = message
        ? message
        : t('GENERAL.REALLY_TO_DELETE');

      Confirm({
        title: confirmMessage,
        labelSubmit: t('GENERAL.DELETE'),
        labelSubmitVariant: 'error',
        labelCancel: t('GENERAL.CANCEL'),
        description: dialogDescription,
      })
        .then(() => {
          onProcessDelete();
        })
        .catch((error: any) => {
          if (error) {
            displayAlert(t('ERROR.DELETING_FAILED'));
            console.log('error', error);
          }
        });

    } else {
      if (props.onClick) {
        props.onClick(e);
      }
    }
  };

  return (
    <TempButtonDelete
      tooltip={t('GENERAL.DELETE')}
      onClick={onClickDelete}
      {...props}
      disabled={disabled}
    />
  );
};

export default ButtonDelete;
