import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableColumn, Typography } from '_template';
import { ICommissionItem } from 'modules/Commission/interfaces';
import ConsultantInList from 'modules/Consultant/components/ConsultantInList/ConsultantInList';
import Price from 'components/Price';

interface CommisssionItemsTableProps {
  title: ReactNode;
  items: ICommissionItem[];
}

const CommisssionItemsTable = ({
  title,
  items,
}: CommisssionItemsTableProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="strong">{title}</Typography>
      <Table<ICommissionItem>
        dataSource={items}
        size="small"
        recordsCount={items.length}
        disableSummary
        sx={{ mt: 1, mb: 6 }}
      >
        <TableColumn
          label={t('COMMISSION_ITEM.INDEX')}
          dataIndex="index"
          align="right"
          width={50}
          render={(data: ICommissionItem, index: number) => index + 1}
        />
        <TableColumn
          label={t('COMMISSION_ITEM.REGISTER_CONSULTANT')}
          dataIndex="consultant"
          width="30%"
          render={(data: ICommissionItem) =>
            data.registerConsultant && (
              <ConsultantInList consultant={data.registerConsultant} />
            )
          }
        />
        <TableColumn
          label={t('COMMISSION_ITEM.ACTUAL_POSITION')}
          dataIndex="actualPosition"
          render={(data: ICommissionItem) =>
            data.registerConsultantPosition &&
            data.registerConsultantPosition.name
          }
        />
        {/* <TableColumn
          label={t('COMMISSION_ITEM.NEW_CONSULTANTS')}
          dataIndex="newConsultants"
          align="center"
          width={100}
          render={(data: ICommissionItem) => ''}
        /> */}
        <TableColumn
          label={t('COMMISSION_ITEM.POINTS')}
          dataIndex="points"
          align="right"
          width={110}
          render={(data: ICommissionItem) => `${data.points} b`}
        />
        <TableColumn
          label={t('COMMISSION_ITEM.AMOUNT_PER_POINT')}
          dataIndex="points"
          align="right"
          width={110}
          render={(data: ICommissionItem) => (
            <Price
              value={data.amountPerPoint}
              currency={data.currency}
              displayEmpty
            />
          )}
        />
        <TableColumn
          label={t('COMMISSION_ITEM.COMMISSION')}
          dataIndex="points"
          align="right"
          width={110}
          render={(data: ICommissionItem) => (
            <strong>
              <Price
                value={data.amount}
                currency={data.currency}
                displayEmpty
              />
            </strong>
          )}
        />
      </Table>
    </>
  );
};

export default CommisssionItemsTable;
