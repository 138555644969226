import NotFound from '_template/pages/NotFound';
import React, { ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // It will update the state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // It will catch error in any component below. We can also log the error to an error reporting service.
    // logErrorToMyService(error, info);
    console.error(error);
    console.error(info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <NotFound
          onClick={() => {
            this.setState({ hasError: false });
          }}
        />
      );
    }

    return this.props.children;
  }
};

export default ErrorBoundary;
