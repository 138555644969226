import React from 'react';
import SettingRoute from '../containers/Route';
import AppLayout from 'components/AppLayout';
import { settingRoutes } from 'constants/settings';

const routes = {
  path: 'setting',
  element: <AppLayout />,
  children: [
    {
      path: '',
      element: <SettingRoute />,
    },
    ...settingRoutes.map((route) => ({
      path: route.path.replace('/setting/', ''),
      element: route.component,
    })),
  ],
};

export default routes;
