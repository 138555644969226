import React from 'react';
import styled from '@emotion/styled';

const RootComponent = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${(props) => props.theme.palette.background.default}
`;

const Root: React.FC<any> = ({ children }) => {
  return (
    <RootComponent>
      <div>
        {children}
      </div>
    </RootComponent>
  );
};

export default Root;
