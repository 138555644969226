import { ModuleRightType } from 'constants/rights';

export const commissionRights: ModuleRightType = {
  COMMISSION: {
    LIST: 'COMMISSION.LIST',
    ADD: 'COMMISSION.ADD',
    EDIT: 'COMMISSION.EDIT',
    DELETE: 'COMMISSION.DELETE',
  },
};
