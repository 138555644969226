import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import {
  TableContainer,
  Table,
  TableColumn,
  TableActions,
  Typography,
  Card,
} from '_template';
import ButtonDelete from 'components/ButtonDelete';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { RIGHTS } from 'constants/rights';
import DateTime from 'components/DateTime';
import {
  DeletePaymentMutation,
  GetPaymentListQuery,
} from 'modules/Payment/query';
import ExternalObjectInList from 'components/ExternalObjectInList';
import LinkTaxDocument from './LinkTaxDocument';

const PaymentTable = () => {
  const { t } = useTranslation();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs('payment', {
    sortField: 'primary',
    sortBy: 'asc',
  });

  const paymentListQuery = useQuery(GetPaymentListQuery, {
    variables: {
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, error } = paymentListQuery;

  const refetch = () => paymentListQuery.refetch();

  const [deleteMutation] = useMutation(DeletePaymentMutation);

  return (
    <TableContainer component={Card}>
      <Table<GQL.IPayment>
        dataSource={data?.paymentList?.rows}
        recordsCount={data?.paymentList?.count}
        loading={loading}
        error={error}
        {...tableProps}
      >
        <TableColumn
          label={t('PAYMENT.DATE')}
          dataIndex="date"
          align="right"
          sx={{
            width: '110px',
          }}
          render={(row: GQL.IPayment) => (
            <Typography>
              {row.date ? <DateTime value={row.date} onlyDate /> : ''}
            </Typography>
          )}
        />
        <TableColumn
          label={t('PAYMENT.PAYMENT')}
          dataIndex="payment"
          align="right"
          sx={{
            width: '100px',
          }}
          render={(row: GQL.IPayment) => {
            const color = (row?.payment || 0) < 0 ? 'red' : 'black';
            return (
              <Typography component="strong" sx={{ color }}>
                {row.payment} {row.currencyCode}
              </Typography>
            );
          }}
        />
        <TableColumn
          label={t('PAYMENT.ACCOUNT')}
          dataIndex="account"
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
          render={(row: GQL.IPayment) => (
            <Typography>
              {row.accountNumber
                ? `${row.accountNumber} / ${row.bankCode || row.bic}`
                : ''}
            </Typography>
          )}
        />
        <TableColumn
          label={t('PAYMENT.SYMBOLS')}
          dataIndex="vs"
          sx={{
            width: '220px',
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
          render={(row: GQL.IPayment) => {
            const symbols = [];
            if (row.vs) {
              symbols.push(`${t('PAYMENT.VS')}: ${row.vs}`);
            }
            if (row.ss) {
              symbols.push(`${t('PAYMENT.SS')}: ${row.ss}`);
            }
            if (row.ks) {
              symbols.push(`${t('PAYMENT.KS')}: ${row.ks}`);
            }
            return <Typography>{symbols.join(', ')}</Typography>;
          }}
        />
        <TableColumn
          label={t(`PAYMENT.EXTERNAL_OBJECT`)}
          dataIndex="externalObject"
          // sx={{
          //   display: {
          //     xs: 'none',
          //     md: 'table-cell',
          //   },
          // }}
          render={(row: GQL.IPayment) => {
            if ((row.payment || 0) > 0 && row.externalObject && row.external) {
              return (
                <ExternalObjectInList
                  type={row.externalObject}
                  data={row.external}
                />
              );
            }

            return;
          }}
        />
        <TableColumn
          label={t(`PAYMENT.TAX_DOCUMENT`)}
          dataIndex="taxDocument"
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
          render={(row: GQL.IPayment) => {
            if (row.taxDocument) {
              return <strong>{row.taxDocument.number}</strong>;
            }

            if (row.external) {
              return <LinkTaxDocument payment={row} />;
            }

            return;
          }}
        />
        <TableColumn
          label={t(`PAYMENT.MESSAGE`)}
          dataIndex="message"
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
        />
        <TableActions
          render={(row: GQL.IPayment) => {
            return (
              <ButtonDelete
                message={t('PAYMENT.REALLY_TO_DELETE')}
                right={RIGHTS.PAYMENT.DELETE}
                onDelete={() => deleteMutation({ variables: { id: row.id } })}
                refetch={refetch}
              />
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default PaymentTable;
