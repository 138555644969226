import gql from 'graphql-tag';

export const GetEmailListQuery: any = gql`
  query emailList($where: EmailListWhereInput, $limit: LimitsInput, $orderBy: OrderByInput) {
    emailList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        sender
        recipient
        subject
        bodyHtml
        sendedAt
        sendResult
        sendError
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const EmailAttachmentsListQuery: any = gql`
  query emailAttachmentList($where: EmailAttachmentListWhereInput!) {
    emailAttachmentList(where: $where) {
      fileName
      mimeType
      base64content
      fileSize
      order
    }
  }
`;

// export const DeleteEmailMutation: any = gql`
//   mutation deleteEmail($id: ID!) {
//     deleteEmail(id: $id)
//   }
// `;