import React from 'react';
import AppLayout from 'components/AppLayout';
import CommissionList from '../containers/CommissionList';
import CommissionDetail from '../containers/CommissionDetail';

const routes = {
  path: 'commission',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <CommissionList />,
    },
    {
      path: 'detail/:recordId?',
      element: <CommissionDetail />,
    },
  ],
};

export default routes;
