import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  TableContainer,
  Table,
  TableColumn,
  TableActions,
  Typography,
  CheckIcon,
  Link,
  Card,
} from '_template';
import ButtonDelete from 'components/ButtonDelete';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { RIGHTS } from 'constants/rights';
import { GetEmailListQuery } from 'modules/Email/query';
import DateTime from 'components/DateTime';
import EmailDetail from 'modules/Email/components/EmailDetail';

const EmailTable = () => {
  const { t } = useTranslation();
  const [emailDetail, setEmailDetail] = useState<GQL.IEmail | null>(null);
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs('email', {
    sortField: 'primary',
    sortBy: 'asc',
  });

  const emailListQuery = useQuery(GetEmailListQuery, {
    variables: {
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, error } = emailListQuery;

  const refetch = () => emailListQuery.refetch();

  const handleClick = (data: GQL.IEmail) => {
    setEmailDetail(data);
  };

  return (
    <>
      {emailDetail && (
        <EmailDetail data={emailDetail} onClose={() => setEmailDetail(null)} />
      )}
      <TableContainer component={Card}>
        <Table<GQL.IEmail>
          dataSource={data?.emailList?.rows}
          recordsCount={data?.emailList?.count}
          loading={loading}
          error={error}
          {...tableProps}
        >
          <TableColumn
            label={t('EMAIL.CREATED_AT')}
            dataIndex="createdAt"
            sx={{ width: 160 }}
            align="center"
            render={({ createdAt }: GQL.IEmail) => (
              <DateTime value={createdAt} />
            )}
          />
          <TableColumn
            label={t('EMAIL.RECIPIENT')}
            dataIndex="recipient"
            sx={{ width: 300 }}
            render={(row: GQL.IEmail) => (
              <Typography>{row.recipient?.join(', ')}</Typography>
            )}
          />
          <TableColumn
            label={t('EMAIL.SUBJECT')}
            dataIndex="subject"
            render={(row: GQL.IEmail) => (
              <Link onClick={() => handleClick(row)}>{row.subject}</Link>
            )}
          />
          <TableColumn
            label={t('EMAIL.SENDED')}
            dataIndex="sended"
            align="center"
            sx={{
              display: {
                xs: 'none',
                sm: 'table-cell',
                width: '70px',
              },
            }}
            render={({ sendedAt }: GQL.IEmail) => (
              <CheckIcon value={!!sendedAt} withColor />
            )}
          />
          <TableActions
            render={(row: GQL.IEmail) => {
              return (
                <>
                  <ButtonDelete
                    message={t('EMAIL.REALLY_TO_DELETE')}
                    right={RIGHTS.EMAIL.DELETE}
                    disabled
                    // onDelete={() => deleteMutation({ variables: { id: row.id } })}}
                    refetch={refetch}
                  />
                </>
              );
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default EmailTable;
