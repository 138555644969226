import React, { ReactElement, ReactNode } from 'react';
import { Grid, Typography } from '_template';

interface SettingTitleProps {
  icon?:  ReactElement;
  children?: ReactNode;
}

const SettingTitle = ({ icon, children }: SettingTitleProps) => (
  <Grid container direction="row" alignItems="center" sx={{ pb: 5 }}>
    {icon && (
      <Grid item mr={3}>
        {icon}
      </Grid>
    )}
    <Grid item>
      <Typography variant="h4" gutterBottom>
        {children}
      </Typography>
    </Grid>
  </Grid>
);

export default SettingTitle;
