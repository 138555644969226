import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Switch } from '_template';
import { CreateBankAccountMutation, UpdateBankAccountMutation } from 'modules/BankAccount/query';
import { BankAccountTypeSelect } from 'modules/BankAccount/components';
import { handleSaveData } from 'helpers/data/handleSaveData';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import { BankAccountData } from '../../interfaces/bankAccount';
import IBAN from 'iban';
import { BankAccountType } from 'modules/BankAccount/constants/bankAccountType';
import AuthorizationFioInputs from './components/AuthorizationFioInputs';

interface BankAccountFormProps {
  recordId?: string;
  data?: BankAccountData;
}

const BankAccountForm = ({ recordId, data }: BankAccountFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreateBankAccountMutation);
  const [updateMutation] = useMutation(UpdateBankAccountMutation);

  const onCancel = () => navigate(ROUTE.BANK_ACCOUNT_LIST);

  const validationSchema = Yup.object({
    name: Yup.string().required(t<string>('BANK_ACCOUNT.NAME_REQUIRED')),
    bankType: Yup.string().required(t<string>('BANK_ACCOUNT.BANK_TYPE_REQUIRED')),
    iban: Yup.string()
      .nullable()
      .test('iban', t<string>('VALIDATION.IBAN_NOT_VALID'), (value) => {
        return value ? IBAN.isValid(value) : true;
      }),
  });

  const onSubmit = (newData: BankAccountData) => {
    const variables = {
      id: recordId,
      data: {
        name: newData.name,
        bankType: newData.bankType,
        accountNumber: newData.accountNumber,
        bankCode: newData.bankCode,
        currencyCode: newData.currencyCode,
        iban: newData.iban,
        bic: newData.bic,
        authorizationProperty: newData.authorizationProperty,
        published: !!newData.published,
      },
    };

    handleSaveData(isNew ? createMutation : updateMutation, variables, onCancel);
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit} onCancel={onCancel}>
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item sm={6}>
                  <TextField
                    name="name"
                    label={t('BANK_ACCOUNT.NAME')}
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <BankAccountTypeSelect
                    name="bankType"
                    label={t('BANK_ACCOUNT.BANK_TYPE')}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={8}>
                  <TextField
                    name="accountNumber"
                    label={t('BANK_ACCOUNT.ACCOUNT_NUMBER')}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    name="bankCode"
                    label={t('BANK_ACCOUNT.BANK_CODE')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={8}>
                  <TextField
                    name="iban"
                    label={t('BANK_ACCOUNT.IBAN')}
                    helperText={t('BANK_ACCOUNT.IBAN_DESCRIPTION')}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    name="bic"
                    label={t('BANK_ACCOUNT.BIC')}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={5} md={3}>
                  <TextField
                    name="currencyCode"
                    label={t('BANK_ACCOUNT.CURRENCY_CODE')}
                  />
                </Grid>
              </Grid>
              {values.bankType === BankAccountType.FIO && (
                <AuthorizationFioInputs />
              )}
              <Grid container>
                <Grid item xs={6}>
                  <Switch
                    name="published"
                    label={t('BANK_ACCOUNT.PUBLISHED')}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default BankAccountForm;
