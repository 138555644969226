import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Actions from './components/Actions';
import UserGroupTable from './components/UserGroupTable';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';
import Helmet from 'components/Helmet';

const UserGroupList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('USER_GROUP.MODEL_TITLE')} />
      <ContentHeader
        title={t('USER_GROUP.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={<Breadcrumbs section={t('USER_GROUP.MODEL_TITLE')} />}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.USER_GROUP.LIST}>
        <UserGroupTable />
      </LimitedAccess>
    </>
  );
};

export default UserGroupList;
