import React from 'react';
import { NavLink } from 'react-router-dom';
import Link from 'components/Link';
import { ROUTE } from 'constants/route';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Stack } from '_template';
import { RIGHTS } from 'constants/rights';

interface ExternalObjectInListProps {
  type: string;
  data: any;
}

const ExternalObjectInList = ({ type, data }: ExternalObjectInListProps) => {
  if (type === 'Consultant') {
    return (
      <Stack direction="row" spacing={2}>
        <Box>
          {data.number ? `${data.number}: ` : ``}{data.companyName ? `${data.companyName} / ` : ``}{data.firstName} {data.lastName}
        </Box>
        <Link
          component={NavLink}
          to={ROUTE.CONSULTANT_DETAIL.replace(':recordId', data.id)}
          right={RIGHTS.CONSULTANT.EDIT}
        >
          <OpenInNewIcon sx={{ fontSize: '16px' }} />
        </Link>
      </Stack>
    );
  }

  return null;
};

export default ExternalObjectInList;