import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface LoggedUserState {
  user: GQL.IUser | null;
}

const initialState: LoggedUserState = {
  user: null,
};

export const loggedUserSlice = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<GQL.IUser>) => {
      state.user = action.payload;
    },
  },
});

export const {
  setLoggedUser
} = loggedUserSlice.actions;

export const selectLoggedUser = (state: RootState) => state.loggedUser.user;
export const selectUserRights = (state: RootState) => state.loggedUser.user?.userGroup.rights;

export default loggedUserSlice.reducer;
