import { Grid, Typography } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';

interface ContentHeaderProps {
  title: string;
  description?: ReactNode;
  actions?: ReactElement;
  breadcrumbs?: ReactElement;
}

const ContentHeader = ({
  title,
  description,
  actions,
  breadcrumbs
}: ContentHeaderProps) => {
  return (
    <Grid justifyContent="space-between" container spacing={1} marginBottom={1}>
      <Grid item>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1">
          {description}
        </Typography>
        {breadcrumbs}
      </Grid>
      <Grid item sx={{ display: 'flex', alignItems: 'end' }}>
        {actions}
      </Grid>
    </Grid>
  );
};

export default ContentHeader;
