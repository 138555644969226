import React from 'react';
import { Box, Typography } from '_template';

const CodeDocumentations = () => {
  const codesPresentation: { [key: string]: string } = {
    '{{presentationDeadline}}': 'doplní termín prezentace (datum a čas)',
    '{{presentationDeadlineDate}}': 'doplní termín prezentace (pouze datum)',
    '{{presentationDeadlineTime}}': 'doplní termín prezentace (pouze čas)',
    '{{presentationLocation}}': 'doplní místo prezentace',

    '{{participationToken}}': 'doplní token přihlášky na prezentaci',

    '{{presentatorFullName}}': 'doplní celé jméno prezentující osoby',
    '{{presentatorMobile}}':  'doplní telefonní číslo prezentující osoby',
    '{{presentatorEmail}}': 'doplní email prezentující osoby',

    '{{registrationParentFullName}}': 'doplní celé jméno sponzora zájemce',
    '{{registrationParentPosition}}': 'doplní pozici sponzora zájemce',
    '{{registrationParentMobile}}': 'doplní telefonní číslo sponzora zájemce',
    '{{registrationParentEmail}}': 'doplní e-mail sponzora zájemce',
};

  return (
    <Box sx={{ mt: 5 }}>
      <Typography>
        Do předmětu a obsahu emailu je možné vkládat zástupné kódy, které jsou
        při odeslání nahrazeny za příslušná data.
      </Typography>
      <Typography>
        Je nutné zástupné kódy zadávat přesně (malá/velká písmena).
      </Typography>
      <br />
      <u>
        <Typography>Možné zástupné kódy</Typography>
      </u>
      <br />
      <Typography>Pro data prezentace:</Typography>
      <ul style={{ marginTop: 5 }}>
        {Object.keys(codesPresentation).map((code) => (
          <li key={`code-${code}`}>
            {code} {codesPresentation[code]}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default CodeDocumentations;
