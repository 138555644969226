import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  TableContainer,
  Table,
  TableColumn,
  TableActions,
  CheckIcon,
  Typography,
  Card,
} from '_template';
import {
  DeleteBankAccountMutation,
  GetBankAccountListQuery,
} from 'modules/BankAccount/query';
import ButtonDelete from 'components/ButtonDelete';
import { ROUTE } from 'constants/route';
import Link from 'components/Link';
import IBAN from 'iban';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import { RIGHTS } from 'constants/rights';
import { bankAccountTypeName } from 'modules/BankAccount/constants/bankAccountType';

const BankAccountTable = () => {
  const { t } = useTranslation();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs(
    'bankAccount',
    {
      sortField: 'primary',
      sortBy: 'asc',
    }
  );

  const bankAccountListQuery = useQuery(GetBankAccountListQuery, {
    variables: {
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, error } = bankAccountListQuery;

  const refetch = () => bankAccountListQuery.refetch();

  const [deleteMutation] = useMutation(DeleteBankAccountMutation);

  return (
    <TableContainer component={Card}>
      <Table<GQL.IBankAccount>
        dataSource={data?.bankAccountList?.rows}
        recordsCount={data?.bankAccountList?.count}
        loading={loading}
        error={error}
        {...tableProps}
      >
        <TableColumn
          label={t('BANK_ACCOUNT.NAME')}
          dataIndex="name"
          sortable
          render={(row: GQL.IBankAccount) => (
            <Link
              component={NavLink}
              to={ROUTE.BANK_ACCOUNT_DETAIL.replace(':recordId', row.id)}
              right={RIGHTS.BANK_ACCOUNT.EDIT}
            >
              <Typography>{row.name}</Typography>
            </Link>
          )}
        />
        <TableColumn
          label={t('BANK_ACCOUNT.BANK_TYPE')}
          dataIndex="bankType"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '100px',
            },
          }}
          render={(bankAccount: GQL.IBankAccount) => (
            <Typography>{bankAccountTypeName[bankAccount.bankType]}</Typography>
          )}
        />
        <TableColumn
          label={t('BANK_ACCOUNT.ACCOUNT_NUMBER')}
          dataIndex="iban"
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
          render={(bankAccount: GQL.IBankAccount) => (
            <Typography>{`${bankAccount.accountNumber} / ${bankAccount.bankCode}`}</Typography>
          )}
        />
        <TableColumn
          label={t('BANK_ACCOUNT.IBAN')}
          dataIndex="iban"
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
          render={(bankAccount: GQL.IBankAccount) => (
            <Typography>
              {IBAN.printFormat(bankAccount.iban || '', ' ')}
            </Typography>
          )}
        />
        <TableColumn
          label={t('BANK_ACCOUNT.BIC')}
          dataIndex="bic"
          sx={{
            display: {
              xs: 'none',
              lg: 'table-cell',
            },
          }}
        />
        <TableColumn
          label={t(`BANK_ACCOUNT.PUBLISHED`)}
          dataIndex="published"
          align="center"
          sx={{
            display: {
              xs: 'none',
              md: 'table-cell',
              width: '70px',
            },
          }}
          render={({ published }: GQL.IBankAccount) => (
            <CheckIcon value={published === true} withColor />
          )}
        />
        <TableActions
          render={(row: GQL.IBankAccount) => {
            return (
              <ButtonDelete
                message={t('BANK_ACCOUNT.REALLY_TO_DELETE')}
                right={RIGHTS.BANK_ACCOUNT.DELETE}
                onDelete={() => deleteMutation({ variables: { id: row.id } })}
                refetch={refetch}
              />
            );
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default BankAccountTable;
