import React from 'react';
import LoginForm from './LoginForm';
import Helmet from 'components/Helmet';

const Login = () => {
  return (
    <>
      <Helmet title="Přihlášení" />
      <LoginForm />
    </>
  );
};

export default Login;
