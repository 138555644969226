import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import useTheme from '../hooks/useTheme';
import createTheme from '../theme';

interface ThemeProviderProps {
  children: React.ReactNode;
};

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { theme } = useTheme();

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
