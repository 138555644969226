import { useSelector } from 'react-redux';
import { selectSettingList } from 'stores/settingSlice';

export const useSetting = () => {
  const settingList = useSelector(selectSettingList);

  const getSetting = (settingName: string) => {
    const settingObject = settingList?.find((s: GQL.ISetting) => s.name === settingName);
    if (!settingObject) {
      return null;
    }
    return settingObject;
  };

  return {
    getSetting,
  };
};
