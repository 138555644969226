import React from 'react';
import AppLayout from 'components/AppLayout';
import CurrencyList from '../containers/CurrencyList';
import CurrencyDetail from '../containers/CurrencyDetail';

const routes = {
  path: 'currency',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <CurrencyList />,
    },
    {
      path: 'detail/:recordId?',
      element: <CurrencyDetail />,
    },
  ],
};

export default routes;
