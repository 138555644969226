import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  CheckIcon,
  Table,
  TableContainer,
  TableColumn,
  TableActions,
  FilterContainer,
} from '_template';
import {
  DeleteConsultantMutation,
  GetConsultantListQuery,
} from 'modules/Consultant/query';
import ButtonDelete from 'components/ButtonDelete';
import { ROUTE } from 'constants/route';
import { useTableAttrs } from 'helpers/table/useTableAttrs';
import Phone from 'components/Phone';
import useDataFilter from 'helpers/table/useDataFilter';
import { positionNumberAndName } from 'modules/Position/helpers/position';
import Link from 'components/Link';
import { RIGHTS } from 'constants/rights';
import ConsultantInList from 'modules/Consultant/components/ConsultantInList/ConsultantInList';

const ConsultantTable = () => {
  const { t } = useTranslation();
  const { searchQuery, filterProps } = useDataFilter();
  const { queryLimits, queryOrderBy, tableProps } = useTableAttrs(
    'consultant',
    {
      sortField: 'number',
      sortBy: 'asc',
    }
  );

  const consultantListQuery = useQuery(GetConsultantListQuery, {
    variables: {
      where: {
        searchQuery,
        systems: false,
      },
      limit: queryLimits,
      orderBy: queryOrderBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, error, data } = consultantListQuery;

  const refetch = () => consultantListQuery.refetch();

  const [deleteConsultant] = useMutation(DeleteConsultantMutation);

  return (
    <>
      <FilterContainer {...filterProps} />
      <TableContainer component={Card}>
        <Table<GQL.IConsultant>
          dataSource={data?.consultantList?.rows}
          recordsCount={data?.consultantList?.count}
          loading={loading}
          error={error}
          onRefetch={refetch}
          {...tableProps}
        >
          <TableColumn
            label={t('CONSULTANT.NUMBER')}
            dataIndex="number"
            sortable
            defaultSortBy="asc"
            width={70}
            render={(row: GQL.IConsultant) => row.number}
          />
          <TableColumn
            label={t('CONSULTANT.REGISTRATION_DATE')}
            dataIndex="registrationDate"
            sortable
            defaultSortBy="asc"
            sx={{
              display: { xs: 'none', md: 'table-cell' },
            }}
            render={(row: GQL.IConsultant) =>
              moment(row.registrationDate).format('D.M.Y')
            }
          />
          <TableColumn
            label={t('CONSULTANT.PAR')}
            dataIndex="parent"
            sortable
            defaultSortBy="asc"
            width={70}
            sx={{
              display: { xs: 'none', lg: 'table-cell' },
            }}
            render={(row: GQL.IConsultant) =>
              !row.parent?.isSystem ? row.parent?.number : ''
            }
          />
          <TableColumn
            label={t('CONSULTANT.POSITION')}
            dataIndex="position"
            sortable
            defaultSortBy="asc"
            sx={{
              display: { xs: 'none', lg: 'table-cell' },
            }}
            render={(row: GQL.IConsultant) =>
              positionNumberAndName(row.position)
            }
          />
          <TableColumn
            label={t('CONSULTANT.NAME')}
            dataIndex="name"
            sortable
            sx={{
              py: 0,
            }}
            defaultSortBy="asc"
            render={(row: GQL.IConsultant) => (
              <Link
                component={NavLink}
                to={ROUTE.CONSULTANT_DETAIL.replace(':recordId', row.id)}
                right={RIGHTS.CONSULTANT.EDIT}
              >
                <ConsultantInList consultant={row} withNumber={false} />
              </Link>
            )}
          />
          <TableColumn
            label={t('CONSULTANT.EMAIL')}
            dataIndex="email"
            sortable
            defaultSortBy="asc"
            sx={{
              display: { xs: 'none', sm: 'table-cell' },
            }}
          />
          <TableColumn
            label={t('CONSULTANT.MOBILE')}
            dataIndex="mobileNumber"
            sortable
            defaultSortBy="asc"
            sx={{
              display: { xs: 'none', sm: 'table-cell' },
            }}
            render={(row: GQL.IConsultant) => (
              <Phone
                data={{
                  phoneNumber: row.mobileNumber,
                  phonePrefix: row.mobilePrefix,
                }}
              />
            )}
          />
          <TableColumn
            label={t(`CONSULTANT.ACTIVE`)}
            dataIndex="active"
            align="center"
            sx={{
              display: {
                xs: 'none',
                sm: 'table-cell',
                width: '70px',
              },
            }}
            render={({ active }: GQL.IConsultant) => (
              <CheckIcon value={active === true} withColor />
            )}
          />
          <TableActions
            render={(row: GQL.IConsultant) => {
              return (
                <ButtonDelete
                  message={t('CONSULTANT.REALLY_TO_DELETE')}
                  right={RIGHTS.CONSULTANT.DELETE}
                  onDelete={() =>
                    deleteConsultant({ variables: { id: row.id } })
                  }
                  refetch={refetch}
                />
              );
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default ConsultantTable;
