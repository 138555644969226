import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Button, DropdownMenu, ListItemIcon, MenuItem, Typography } from '../../';
import { Check } from '@mui/icons-material';

interface RecordsOnTableProps {
  value: number;
  handleChange: (recordsOnPage: number) => void;
}

const RecordsOnTable = ({
  value,
  handleChange
}: RecordsOnTableProps) => {
  const options = [10, 25, 100];

  return (
    <DropdownMenu
      control={(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography>
            <Trans>TABLE.RECORDS_ON_PAGE</Trans>
          </Typography>
          <Button
            variant="text"
            color="secondary"
            sx={(theme) => ({
              ml: 2,
              px: 2,
              minWidth: 'auto',
              fontWeight: theme.typography.fontWeightBold,
            })}
          >
            {value}
          </Button>
        </Box>
      )}
      sx={{
        '& .MuiMenuItem-root': {
          justifyContent: 'flex-end',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {options.map((records: number) => (
        <MenuItem
          key={records}
          selected={records === value}
          onClick={() => handleChange(records)}
        >
          {records === value && (
            <ListItemIcon>
              <Check />
            </ListItemIcon>
          )}
          {records}
        </MenuItem>
      ))}
    </DropdownMenu>
  );
};

export default RecordsOnTable;
