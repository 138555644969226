import React, { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';
import { MenuItem } from '@mui/material';
import TextField, { TextFieldProps } from 'components/TextField';
import { Trans } from 'react-i18next';

type SelectProps = TextFieldProps & {
  readOnly?: boolean;
};

const Select = ({ readOnly = false, children, ...props }: SelectProps) => {
  /* eslint-disable-next-line */
  const formikContext = useFormikContext();

  const onChange = (e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (formikContext) {
      formikContext?.setFieldValue(name, value);
    }
  };

  const value =
    props.value ||
    (props.name &&
      formikContext?.values &&
      (formikContext?.values as any)[props.name]);

  return (
    <TextField
      select
      readOnly={readOnly}
      value={value || ''}
      onChange={onChange}
      {...props}
    >
      {!props.required && (
        <MenuItem value="">
          <em>
            <Trans>FORM.NO_VALUE</Trans>
          </em>
        </MenuItem>
      )}
      {children}
    </TextField>
  );
};

export default Select;
