import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Tabs, TabPanel, Grid, DatePickerField } from '_template';
import {
  CreatePaymentMutation,
  UpdatePaymentMutation,
} from 'modules/Payment/query';
import { handleSaveData } from 'helpers/data/handleSaveData';
import { ROUTE } from 'constants/route';
import { PaymentData } from '../../interfaces/payment';
import { BankAccountSelect } from 'modules/BankAccount/components';
import NumberField from 'components/NumberField';
import TextField from 'components/TextField';
import { CurrencySelect } from 'modules/Currency/components';

interface PaymentFormProps {
  recordId?: string;
  data?: PaymentData;
}

const PaymentForm = ({ recordId, data }: PaymentFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreatePaymentMutation);
  const [updateMutation] = useMutation(UpdatePaymentMutation);

  const onCancel = () => navigate(ROUTE.PAYMENT_LIST);

  const validationSchema = Yup.object({
    bankAccountId: Yup.string().required(
      t<string>('PAYMENT.BANK_ACCOUNT_REQUIRED')
    ),
    date: Yup.string().required(t<string>('PAYMENT.DATE_REQUIRED')),
    payment: Yup.string().required(t<string>('PAYMENT.PAYMENT_REQUIRED')),
    currencyId: Yup.string().required(t<string>('PAYMENT.CURRENCY_REQUIRED')),
  });

  const onSubmit = (newData: PaymentData) => {
    const variables = {
      id: recordId,
      data: {
        paymentType: 'income',
        bankAccountId: newData.bankAccountId,
        date: newData.date,
        payment: newData.payment,
        currencyId: newData.currencyId,
        accountNumber: newData.accountNumber,
        bankCode: newData.bankCode,
        vs: newData.vs,
        ss: newData.ss,
        ks: newData.ks,
        message: newData.message,
      },
    };

    handleSaveData(
      isNew ? createMutation : updateMutation,
      variables,
      onCancel
    );
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} onCancel={onCancel}>
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item sm={6}>
                  <BankAccountSelect
                    name="bankAccountId"
                    label={t('PAYMENT.BANK_ACCOUNT')}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={4}>
                  <DatePickerField
                    name="date"
                    label={t('PAYMENT.DATE')}
                    required
                  />
                </Grid>
                <Grid item sm={4}>
                  <NumberField
                    name="payment"
                    label={t('PAYMENT.PAYMENT')}
                    required
                  />
                </Grid>
                <Grid item sm={4}>
                  <CurrencySelect
                    name="currencyId"
                    label={t('PAYMENT.CURRENCY')}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={8}>
                  <TextField
                    name="accountNumber"
                    label={t('PAYMENT.ACCOUNT_NUMBER')}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField name="bankCode" label={t('PAYMENT.BANK_CODE')} />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={4}>
                  <TextField name="vs" label={t('PAYMENT.VS')} />
                </Grid>
                <Grid item sm={4}>
                  <TextField name="ss" label={t('PAYMENT.SS')} />
                </Grid>
                <Grid item sm={4}>
                  <TextField name="ks" label={t('PAYMENT.KS')} />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={12}>
                  <TextField name="message" label={t('PAYMENT.MESSAGE')} />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentForm;
