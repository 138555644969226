import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider, Loader } from '_template';
import NotFound from '_template/pages/NotFound';
import Breadcrumbs from 'components/Breadcrumbs';
import { GetUserQuery } from 'modules/User/query/User';
import { ROUTE } from 'constants/route';
import UserForm from './UserForm';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';
import Helmet from 'components/Helmet';

const UserDetail = () => {
  const { t } = useTranslation();
  const { recordId } = useParams();

  const userQuery = useQuery(GetUserQuery, {
    variables: {
      where: {
        id: recordId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { loading, error } = userQuery;

  const data = userQuery?.data?.user;

  const isNew = recordId === 'new';

  if (loading) {
    return <Loader />;
  }

  if (error && !isNew) {
    return <NotFound />;
  }

  const title = isNew ? t('USER.NEW') : t('USER.EDIT');

  return (
    <>
      <Helmet title={title} />
      <ContentHeader title={t('USER.MODEL_TITLE')} />
      <Breadcrumbs
        links={{
          [ROUTE.USER_LIST]: t('USER.MODEL_TITLE'),
        }}
        section={title}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={isNew ? RIGHTS.USER.ADD : RIGHTS.USER.EDIT}>
        <UserForm
          recordId={recordId}
          data={{
            ...data,
            userGroupId: data?.userGroup?.id,
            active: data ? data.active : true,
          }}
        />
      </LimitedAccess>
    </>
  );
};

export default UserDetail;
