import React from 'react';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Helmet from 'components/Helmet';

const EmptyScene = () => {
  return (
    <>
      <Helmet title='Výchozí stránka' />
      <ContentHeader title='Výchozí stránka' />
      <Breadcrumbs section='Výchozí stránka' />
      <Divider sx={{ my: 4 }} />
    </>
  );
};

export default EmptyScene;
