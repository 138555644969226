import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '_template';
import { SETTINGS } from 'constants/settings';
import SettingsForm from 'components/SettingsForm';
import NumberField from 'components/NumberField';

const ConsultantNumberFormatSetting = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    [SETTINGS.CONSULTANT.NUMBER_LENGTH]: Yup.number().min(1).required(t<string>('GENERAL.FIELD_REQUIRED')),
  });

  return (
    <SettingsForm validationSchema={validationSchema}>
      {({ handleSubmit }) => (
        <>
          <TextField
            name={SETTINGS.CONSULTANT.NUMBER_PREFIX}
            label={t('CONSULTANT.SETTINGS.NUMBER_PREFIX')}
            helperText={t('CONSULTANT.SETTINGS.NUMBER_PREFIX_DESCRIPTION')}
            onBlur={() => handleSubmit()}
          />
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <NumberField
                name={SETTINGS.CONSULTANT.NUMBER_LENGTH}
                required
                label={t('CONSULTANT.SETTINGS.NUMBER_LENGTH')}
                onBlur={() => handleSubmit()}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberField
                name={SETTINGS.CONSULTANT.COUNT_FROM}
                label={t('CONSULTANT.SETTINGS.COUNT_FROM')}
                onBlur={() => handleSubmit()}
              />
            </Grid>
          </Grid>
        </>
      )}
    </SettingsForm>
  );
};

export default ConsultantNumberFormatSetting;
