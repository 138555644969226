import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { localizationsCode } from 'constants/localizations';
import { RootState } from '.';
import { SortProps } from '_template/components/Table/Table';

interface GridSetting {
  page: number;
  recordsOnPage: number;
  sort: SortProps;
}

export interface AppState {
  // jazyk lokalizace
  locale: string;
  // jazyk pro zadávání dat do formulářů
  inputLanguage: GQL.ILanguage | null;
  // jazyk pro zobrazení dat v administraci
  dataLanguage: string;
  // nastavení gridů
  grids: { [key: string]: GridSetting };
}

const initialState: AppState = {
  locale: localizationsCode.CZ,
  inputLanguage: null,
  dataLanguage: 'cs',
  grids: {},
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
      localStorage.setItem('locale', action.payload);
    },
    changeInputLanguage: (state, action: PayloadAction<GQL.ILanguage>) => {
      state.inputLanguage = action.payload;
    },
    changeDataLanguage: (state, action: PayloadAction<string>) => {
      state.dataLanguage = action.payload;
    },
    changeGridSetting: (state, action: PayloadAction<{ tableName: string, settings: GridSetting }>) => {
      const { tableName, settings } = action.payload;
      if (!state.grids) {
        state.grids = {};
      }
      state.grids[tableName] = {
        ...state.grids[tableName] || {},
        ...settings,
      };
    },
  },
});

export const { changeLocale,changeInputLanguage, changeGridSetting } = appSlice.actions;

export const selectLocale = (state: RootState) => state.app.locale;

export const selectInputLanguage = (state: RootState) => {
  if (!state.app.inputLanguage) {
    return state.language.languageList?.find((l: GQL.ILanguage) => l.primary);
  }
  return state.app.inputLanguage;
};

export const selectDataLanguage = (state: RootState) => {
  return state.app.dataLanguage;
};

export const selectGridSetting = (tableName: string) => (state: RootState): GridSetting => {
  return state.app?.grids?.[tableName] || {
    page: 1,
    recordsOnPage: 10,
  };
};

export default appSlice.reducer;
