import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Actions from './components/Actions';
import RegistrationTable from './components/RegistrationTable';
import { RIGHTS } from 'constants/rights';
import LimitedAccess from 'components/LimitedAccess';
import Helmet from 'components/Helmet';

const RegistrationList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('REGISTRATION.MODEL_TITLE')} />
      <ContentHeader
        title={t('REGISTRATION.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={<Breadcrumbs section={t('REGISTRATION.MODEL_TITLE')} />}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.REGISTRATION.LIST}>
        <RegistrationTable />
      </LimitedAccess>
    </>
  );
};

export default RegistrationList;
