import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider, Grid } from '_template';
import Actions from './Actions';
import LineChart from './LineChart';
import Helmet from 'components/Helmet';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('DASHBOARD.MODEL_TITLE')} />
      <ContentHeader
        title={t('DASHBOARD.MODEL_TITLE')}
        description={
          <>
            Welcome back, Lucy! We've missed you.{' '}
            <span role='img' aria-label='Waving Hand Sign'>
              👋
            </span>
          </>
        }
        actions={<Actions />}
      />
      <Divider sx={{ my: 4 }} />
      <Grid container>
        <Grid item sm={12}>
          <LineChart />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
