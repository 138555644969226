import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { RecordsLimits, SortProps } from '_template/components/Table/Table';
import { changeGridSetting, selectGridSetting } from 'stores/appSlice';

interface UseTableAttrsProps {
  page: number;
  recordsOnPage: number;
  queryLimits: RecordsLimits;
  queryOrderBy: SortProps;
  tableProps: any;
}

export const useTableAttrs = (tableName: string, defaultSort?: SortProps): UseTableAttrsProps => {
  const dispatch = useDispatch();
  const grid = useSelector(selectGridSetting(tableName));

  const [page, setPage] = useState<number>(grid?.page || 1);
  const [recordsOnPage, setRecordsOnPage] = useState(grid?.recordsOnPage || 10);
  const [sort, setSort] = useState<SortProps>(grid?.sort || defaultSort);

  const queryLimits = useMemo(
    () => ({
      limit: recordsOnPage,
      offset: (page - 1) * recordsOnPage,
    }),
    [page, recordsOnPage]
  );

  const queryOrderBy = useMemo(() => sort, [sort]);

  const tableProps = useMemo(() => {
    return {
      name: tableName,
      page,
      recordsOnPage,
      limit: queryLimits,
      sort,
      handlePageChange: (page: number) => {
        dispatch(
          changeGridSetting({
            tableName,
            settings: {
              ...grid,
              page,
            },
          })
        );
        setPage(page);
      },
      handleRecordsOnPageChange: (recordsOnPage: number) => {
        dispatch(
          changeGridSetting({
            tableName,
            settings: {
              ...grid,
              page: 1,
              recordsOnPage,
            },
          })
        );
        setRecordsOnPage(recordsOnPage);
      },
      handleSortChange: (sort: SortProps) => {
        dispatch(
          changeGridSetting({
            tableName,
            settings: {
              ...grid,
              sort,
            },
          })
        );
        setSort(sort);
      },
    };
    // eslint-disable-next-line
  }, [page, recordsOnPage, queryLimits, sort]);

  useEffect(() => {
    if (page !== grid.page) {
      setPage(grid.page);
    }
    if (recordsOnPage !== grid.recordsOnPage) {
      setRecordsOnPage(grid.recordsOnPage);
    }
    // eslint-disable-next-line
  }, [page, recordsOnPage, grid]);

  return {
    page,
    recordsOnPage,
    queryLimits,
    queryOrderBy,
    tableProps,
  };
};
