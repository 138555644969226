import React, { useMemo } from 'react';
import { Pagination } from '@mui/material';

interface PaginateProps {
  page: number;
  recordsOnPage: number;
  recordsCount: number;
  handlePageChange: (selected: number) => void;
}

const Paginate = ({
  page,
  recordsCount,
  recordsOnPage,
  handlePageChange
}: PaginateProps) => {
  const pagesCount = useMemo(
    () => Math.ceil(recordsCount / recordsOnPage), [recordsCount, recordsOnPage]
  );

  return (
    <div className="noselect">
      <Pagination
        count={pagesCount}
        page={page}
        onChange={(_: any, page: number) => handlePageChange(page)}
      />
    </div>
  );
};

export default Paginate;
