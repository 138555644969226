import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Helmet from 'components/Helmet';
import Actions from './components/Actions';
import BankAccountTable from './components/BankAccountTable';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';

const BankAccountList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('BANK_ACCOUNT.MODEL_TITLE')} />
      <ContentHeader
        title={t('BANK_ACCOUNT.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={<Breadcrumbs section={t('BANK_ACCOUNT.MODEL_TITLE')} />}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.BANK_ACCOUNT.LIST}>
        <BankAccountTable />
      </LimitedAccess>
    </>
  );
};

export default BankAccountList;
