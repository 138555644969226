import React from 'react';
import { default as MuiBox, BoxProps } from '@mui/material/Box';

const Box = (props: BoxProps) => (
  <MuiBox {...props}>
    {props.children}
  </MuiBox>
);

export default Box;
