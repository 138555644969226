import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '_template';
import { useFormikContext } from 'formik';
import { BankAccountData } from 'modules/BankAccount/interfaces';

const AuthorizationFioInputs = () => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<BankAccountData>();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    formikContext.setFieldValue('authorizationProperty', {
      [name]: value,
    });
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        <TextField
          name="token"
          label={t('BANK_ACCOUNT.FIO.TOKEN')}
          helperText={t('BANK_ACCOUNT.FIO.TOKEN_HELPER_TEXT')}
          value={formikContext?.values?.authorizationProperty?.token || ''}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default AuthorizationFioInputs;