import React from 'react';
import AppLayout from 'components/AppLayout';
import TaxDocumentList from '../containers/TaxDocumentList';

const routes = {
  path: 'tax-document',
  element: <AppLayout />,
  children: [
    {
      path: 'list',
      element: <TaxDocumentList />,
    },
  ],
};

export default routes;
