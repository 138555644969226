import gql from 'graphql-tag';

export const GetFileListQuery: any = gql`
  query fileList($where: FileListWhereInput!) {
    fileList(where: $where) {
      rows {
        id
        parent {
          id
        }
        code
        fileName
        fileType
        fileSize
        storageLink
        storageData
        createdAt
      }
      count
    }
  }
`;

export const DeleteFileMutation: any = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`;