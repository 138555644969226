import * as Yup from 'yup';
import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Switch } from '_template';
import { CreateUserMutation, UpdateUserMutation } from 'modules/User/query';
import { handleSaveData } from 'helpers/data/handleSaveData';
import { selectLoggedUser } from 'stores/loggedUserSlice';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import { UserData } from '../../interfaces/user';
import { UserGroupSelect } from 'modules/UserGroup/components';

interface UserFormProps {
  recordId?: string;
  data?: UserData;
}

const UserForm = ({ recordId, data }: UserFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loggedUser = useSelector(selectLoggedUser);

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreateUserMutation);
  const [updateMutation] = useMutation(UpdateUserMutation);

  const onCancel = () => navigate(ROUTE.USER_LIST);

  const validationSchema = Yup.object({
    name: Yup.string().required(t<string>(`USER.NAME_REQUIRED`)),
    userName: Yup.string().required(t<string>(`USER.USER_NAME_REQUIRED`)),
    email: Yup.string().email(t<string>(`VALIDATION.EMAIL_NOT_VALID`)).required(t<string>(`USER.EMAIL_REQUIRED`)),
    password: Yup.string().test('password', t<string>(`USER.PASSWORD_REQUIRED`), value => {
      if (recordId === 'new') {
        return !!value;
      }
      return true;
    }),
    userGroupId: Yup.string().required(t<string>(`USER.USER_GROUP_REQUIRED`)),
  });

  const onSubmit = (newData: UserData) => {
    const variables = {
      id: recordId,
      data: {
        userGroupId: newData.userGroupId,
        name: newData.name,
        userName: newData.userName,
        email: newData.email,
        active: !!newData.active,
      },
    };

    if (isNew) {
      (variables.data as any).password = newData.password;
    }

    handleSaveData(
      (isNew ? createMutation : updateMutation),
      variables,
      onCancel
    );
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          onCancel={onCancel}
        >
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    name="name"
                    label={t('USER.NAME')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    name="userName"
                    label={t('USER.USER_NAME')}
                    // helperText={t('USER.USER_NAME_DESCRIPTION')}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    required
                    type="email"
                    name="email"
                    label={t(`USER.EMAIL`)}
                  />
                </Grid>
              </Grid>
              {isNew && (
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      required
                      type="password"
                      name="password"
                      label={t(`USER.PASSWORD`)}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <UserGroupSelect
                    required
                    name="userGroupId"
                    label={t(`USER.USER_GROUP`)}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <I18nextSelect
                    required
                    name="language"
                    label={t('GENERAL.BACKOFFICE_LOCALIZATION')}
                  />
                </Grid> */}
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Switch
                    name="active"
                    disabled={data?.id === loggedUser?.id}
                    label={t('USER.ACTIVE')}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
