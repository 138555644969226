import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import { useFormikContext } from 'formik';
import { TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '../TextField';

type DateTimePickerFieldProps = TextFieldProps & {
  withoutFormik?: boolean;
}

const DateTimePickerField = ({ withoutFormik = false, ...props }: DateTimePickerFieldProps) => {
  /* eslint-disable-next-line */
  const formikContext = !withoutFormik ? useFormikContext() : null;

  const onChange = (value: Dayjs | null) => {
    if (props.name) {
      const newValue = value ? dayjs(value).format() : null;
      if (formikContext) {
        formikContext?.setFieldValue(props.name, newValue);
      }
      if (props.onChange) {
        props.onChange({
          target: {
            name: props.name,
            value: newValue,
          }
        } as any);
      }
    }
  }

  const value = props.value
    || (props.name && formikContext?.values && (formikContext?.values as any)[props.name]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
      <DateTimePicker
        label={props.label}
        value={value}
        ampm={false}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
};

export default DateTimePickerField;
