import React, { ChangeEvent, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormHelperText } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { default as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

interface CheckboxProps extends MuiCheckboxProps {
  label?: React.ReactElement | string | null;
  disabled?: boolean;
  readOnly?: boolean;
  helperText?: string | null;
};

const Checkbox = ({
  label,
  disabled = false,
  helperText,
  ...props
}: CheckboxProps) => {
  const [innerChecked, setInnerChecked] = useState<boolean>(!!props.checked);

  const formikContext = useFormikContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = e.target as HTMLInputElement;
    if (!props.readOnly) {
      setInnerChecked(checked);
      if (props.onChange) {
        props.onChange(e, checked);
      } else if (formikContext) {
        formikContext?.setFieldValue(name, checked);
      }
    }
  }

  const checked: boolean = !!props.checked
    ?? (props.name && formikContext?.values && (formikContext?.values as any)[props.name]);

  if (!label) {
    return (
      <>
        <MuiCheckbox
          {...props}
          checked={checked || innerChecked}
          disabled={disabled}
          onChange={onChange}
        />
        {helperText && (
          <FormHelperText sx={{ ml: '33px', mt: '-10px', mb: '10px' }}>
            {helperText}
          </FormHelperText>
        )}
      </>
    );
  }

  return (
    <>
      <FormControlLabel
        control={
          <MuiCheckbox
            {...props}
            checked={checked || innerChecked}
            onChange={onChange}
          />
        }
        label={label}
        disabled={disabled}
      />
      {helperText && (
        <FormHelperText sx={{ ml: '33px', mt: '-10px', mb: '10px' }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default Checkbox;
