import React, { Fragment } from 'react';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '_template';
import { useQuery } from '@apollo/client';
import { EmailAttachmentsListQuery } from 'modules/Email/query';

interface EmailDetailProps {
  data: GQL.IEmail;
  onClose: () => void;
}

const EmailDetail = ({
  data,
  onClose,
}: EmailDetailProps) => {
  const { t } = useTranslation();

  const attachmentsQuery = useQuery(EmailAttachmentsListQuery, {
    variables: {
      where: {
        emailId: data.id,
      },
    },
  });

  const attachments = attachmentsQuery?.data?.emailAttachmentList || [];

  return (
    <Dialog open maxWidth="md">
      <DialogTitle>
        <Trans>EMAIL.DETAIL</Trans>
      </DialogTitle>
      <DialogContent>
        <TextField
          name="subject"
          label={t('EMAIL.SUBJECT')}
          value={data.subject}
          InputProps={{
            readOnly: true,
          }}
        />
        {attachments.length > 0 && (
          <>
            <FormLabel>
              <Trans>EMAIL.ATTACHMENTS</Trans>
            </FormLabel>
            <Box sx={{ mb: 5 }}>
              {attachments?.map((attachment: GQL.IEmailAttachment) => (
                <Chip
                  key={`attachment-${attachment.id}`}
                  label={attachment.fileName}
                  variant="outlined"
                />
              ))}
            </Box>
          </>
        )}
        <FormLabel>
          <Trans>EMAIL.BODY</Trans>
        </FormLabel>
        <Box
          sx={{
            border: 1,
            borderRadius: 1,
            pt: 3,
            pb: 8,
            borderColor: 'rgba(0, 0, 0, 0.87)',
          }}
          dangerouslySetInnerHTML={{ __html: data.bodyHtml || '' }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          <Trans>EMAIL.CLOSE_DETAIL</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDetail;
