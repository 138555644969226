import React from 'react';
import { GetCurrencyQuery } from 'modules/Currency/query/Currency';
import ContainerDetailWrapper from 'components/ContainerDetailWrapper';
import { ROUTE } from 'constants/route';
import { RIGHTS } from 'constants/rights';
import CurrencyForm from './CurrencyForm';

const CurrencyDetail = () => (
  <ContainerDetailWrapper
    query={GetCurrencyQuery}
    parseQueryData={(data) => data?.currency || null}
    languagePrefix="CURRENCY"
    routeList={ROUTE.CURRENCY_LIST}
    rights={RIGHTS.CURRENCY}
  >
    {({ recordId, data }) => (
      <CurrencyForm
        recordId={recordId}
        data={{
          ...data,
          published: data ? data.published : true,
        }}
      />
    )}
  </ContainerDetailWrapper>
);

export default CurrencyDetail;
