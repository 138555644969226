import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
} from '@mui/material';

export interface BreadcrumbsProps {
  prefix?: string;
  section: string;
  links?: { [key: string]: string };
};

const Breadcrumbs = ({
  prefix,
  section,
  links,
}: BreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs
      aria-label="Breadcrumb"
      sx={{
        '& li, & a, & p': {
          fontSize: '12px',
        },
      }}
    >
      <Link component={NavLink} to="/">
        {prefix}
      </Link>
      {links && Object.keys(links).map((key: string) => (
        <Link key={key} component={NavLink} to={key}>
          {links[key]}
        </Link>
      ))}
      <Typography>
        {section}
      </Typography>
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
