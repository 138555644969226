import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Divider,
  Typography,
  TablePropsSizeOverrides,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import Paginate from '../Paginate';
import Column, { ColumnProps } from './components/Column';
import Actions from './components/Actions';
import Header from './components/Header';
import Data from './components/Data';
import Error from './components/Error';
import Checkbox from '../Checkbox';
import RecordsOnTable from '../RecordsOnTable';
import Loader from '../Loader';

export interface RecordsLimits {
  limit: number;
  offset: number;
}

export type SortBy = 'asc' | 'desc';

export interface SortProps {
  sortField: string;
  sortBy: SortBy;
}

interface TableProps<T> {
  name?: string;
  page?: number;
  recordsOnPage?: number;
  limit?: RecordsLimits;
  checkboxes?: boolean;
  disableSummary?: boolean;
  disablePaginate?: boolean;
  dataSource: T[] | undefined;
  recordsCount?: number;
  sort?: SortProps;
  loading?: boolean;
  error?: any;
  size?: OverridableStringUnion<'small' | 'medium', TablePropsSizeOverrides>;
  sx?: any;
  children?: React.ReactNode;
  onRefetch?: () => void;
  handleRowClick?: (data: T, index: number) => void;
  handleRowDoubleClick?: (data: T) => void;
  handlePageChange?: (page: number) => void;
  handleRecordsOnPageChange?: (recordsOnPage: number) => void;
  handleSortChange?: (sort: SortProps) => void;
}

const DefaultLimit: RecordsLimits = { limit: 10, offset: 0 };

const Table = <T,>({
  page,
  recordsOnPage,
  limit = DefaultLimit,
  sx = {},
  checkboxes = false,
  disableSummary = false,
  disablePaginate = false,
  children,
  dataSource = [],
  recordsCount = 0,
  sort,
  loading = false,
  error = false,
  onRefetch,
  handleRowClick,
  handlePageChange,
  handleRecordsOnPageChange,
  handleSortChange,
  ...props
}: TableProps<T>) => {
  const [dataSourceCached, setDataSourceCached] = useState<T[]>(dataSource);
  const [recordsCountCached, setRecordsCountCached] = useState(recordsCount);

  const columns: Array<any> = [];

  if (!Array.isArray(children)) {
    if (
      (children as any).type === Column ||
      (children as any).type === Actions
    ) {
      columns.push(children);
    }
  }
  if (children instanceof Array) {
    children.forEach((child) => {
      if (child instanceof Array) {
        child.forEach((subChild: any) => {
          columns.push(subChild);
        });
      } else {
        columns.push(child);
      }
    });
  }

  const innerHandleRowClick = (data: T, index: number) => {
    if (handleRowClick) {
      handleRowClick(data, index);
    }
  };

  const handleRowDoubleClick = (data: T) => {
    if (props.handleRowDoubleClick) {
      props.handleRowDoubleClick(data);
    }
  };

  const isError = useMemo(() => !!error, [error]);

  useEffect(() => {
    if (!loading && !isError) {
      setDataSourceCached(dataSource);
      setRecordsCountCached(recordsCount);
    }
    // eslint-disable-next-line
  }, [loading, dataSource, recordsCount]);

  const displayRecordsOnPage =
    recordsCountCached > 0 &&
    disablePaginate !== true &&
    !!handleRecordsOnPageChange;
  const displayPagination =
    recordsCountCached > 0 &&
    disablePaginate !== true &&
    page &&
    recordsOnPage &&
    !!handlePageChange;

  return (
    <MuiTable sx={{ ...sx }} {...props}>
      <TableHead>
        <TableRow>
          {checkboxes === true && (
            <TableCell>
              <Checkbox />
            </TableCell>
          )}
          {columns.map(
            (column: React.Component<ColumnProps>, index: number) => {
              const { props: columnProps } = column;
              return (
                <Header
                  key={index}
                  column={column}
                  sortable={columnProps.sortable}
                  sortField={columnProps.dataIndex}
                  currentSort={sort}
                  defaultSortBy={columnProps.defaultSortBy}
                  width={columnProps.width}
                  align={columnProps.align}
                  sx={columnProps.sx}
                  handleSortChange={handleSortChange}
                >
                  {columnProps.label}
                </Header>
              );
            }
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && dataSourceCached.length === 0 && (
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ py: 20 }}>
              <Loader />
            </TableCell>
          </TableRow>
        )}
        {isError && !loading && (
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ py: 20 }}>
              <Error onRefetch={onRefetch} />
            </TableCell>
          </TableRow>
        )}
        {!isError &&
          dataSourceCached?.map((data: T, index: number) => (
            <TableRow hover key={index}>
              {checkboxes === true && (
                <TableCell>
                  <Checkbox />
                </TableCell>
              )}
              {columns.map(
                (column: React.Component<ColumnProps>, cIndex: number) => {
                  const { props: columnProps } = column;
                  return (
                    <Data
                      key={cIndex}
                      column={column}
                      data={data}
                      index={index}
                      sx={columnProps.sx}
                      onClick={(data: T) => innerHandleRowClick(data, index)}
                      onDoubleClick={() => handleRowDoubleClick(data)}
                    />
                  );
                }
              )}
            </TableRow>
          ))}
        {!loading && !isError && dataSourceCached.length === 0 && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Trans>TABLE.NO_FOUND_RECORDS</Trans>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {!(
        disableSummary ||
        isError ||
        (loading && dataSourceCached.length === 0)
      ) && (
        <caption>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: 0,
                px: 2,
                paddingTop: '7px !important',
                paddingBottom: '7px',
              }}
            >
              <Typography>
                <Trans>TABLE.RECORDS_COUNT</Trans>:{` `}
                <Typography component="strong">{recordsCountCached}</Typography>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: 2,
              }}
            >
              {displayRecordsOnPage && handleRecordsOnPageChange && (
                <RecordsOnTable
                  value={recordsOnPage || 10}
                  handleChange={handleRecordsOnPageChange}
                />
              )}
              {displayRecordsOnPage && displayPagination && (
                <Divider orientation="vertical" sx={{ mx: 5, height: '50%' }} />
              )}
              {displayPagination && (
                <Paginate
                  page={page}
                  recordsOnPage={recordsOnPage}
                  recordsCount={recordsCountCached}
                  handlePageChange={handlePageChange}
                />
              )}
            </Grid>
          </Grid>
        </caption>
      )}
    </MuiTable>
  );
};

export default Table;
