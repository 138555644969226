import * as Yup from 'yup';
import React from 'react';
import IBAN from 'iban';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Switch, SelectChangeEvent } from '_template';
import { CreateCurrencyMutation, UpdateCurrencyMutation } from 'modules/Currency/query';
import { handleSaveData } from 'helpers/data/handleSaveData';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import { CurrencyData } from '../../interfaces/currency';
import { parseFloatValue } from 'helpers/parseFloatValue';
import SupportCurrencySelect from 'components/SupportCurrencySelect';
import { SupportCurrency } from 'components/SupportCurrencySelect/SupportCurrencySelect';

interface CurrencyFormProps {
  recordId?: string;
  data?: CurrencyData;
}

const CurrencyForm = ({ recordId, data }: CurrencyFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreateCurrencyMutation);
  const [updateMutation] = useMutation(UpdateCurrencyMutation);

  const onCancel = () => navigate(ROUTE.CURRENCY_LIST);

  const validationSchema = Yup.object({
    code: Yup.string().required(t<string>('CURRENCY.CODE_REQUIRED')),
    iban: Yup.string()
      .nullable()
      .test('iban', t<string>('VALIDATION.IBAN_NOT_VALID'), (value) => {
        return value ? IBAN.isValid(value) : true;
      }),
  });

  const onSubmit = (newData: CurrencyData) => {
    const variables = {
      id: recordId,
      data: {
        name: newData.name,
        code: newData?.code?.toUpperCase(),
        exchangeRate: parseFloatValue(newData.exchangeRate),
        iban: newData.iban,
        bic: newData.bic,
        primary: !!newData.primary,
        published: !!newData.published,
      },
    };

    handleSaveData(isNew ? createMutation : updateMutation, variables, onCancel);
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit} onCancel={onCancel}>
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container>
                <Grid item xs={12}>
                  <SupportCurrencySelect
                    autoFocus
                    required
                    name="code"
                    label={t('CURRENCY.CODE')}
                    helperText={t<string>('CURRENCY.CODE_DESCRIPTION')}
                    // @ts-ignore
                    onChange={(event: SelectChangeEvent<any>) => {
                      const { value } = event.target;
                      setFieldValue('code', value);
                      setFieldValue('name', SupportCurrency[value].name);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={8}>
                  <TextField name="iban" label={t('CURRENCY.IBAN')} helperText={t('CURRENCY.IBAN_DESCRIPTION')} />
                </Grid>
                <Grid item sm={4}>
                  <TextField name="bic" label={t('CURRENCY.BIC')} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={5} md={3}>
                  <TextField type="decimal" name="exchangeRate" label={t('CURRENCY.EXCHANGE_RATE')} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Switch name="published" label={t('CURRENCY.PUBLISHED')} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Switch name="primary" disabled={!!data?.primary} label={t('CURRENCY.PRIMARY')} />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default CurrencyForm;
