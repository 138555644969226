import React, { ReactElement, ReactNode } from 'react';
import { Menu, MenuProps } from '@mui/material';

interface DropdownMenuProps extends Omit<MenuProps, 'open'> {
  control: ReactElement;
  children?: ReactNode | Array<ReactNode>;
}

const DropdownMenu = ({ control, children, ...props }: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);

  const menuItems = children
    ? Array.isArray(children) ? children : [children]
    : [];

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeControl = React.cloneElement(
    control, {
      onClick: handleClickListItem,
    },
  );

  return (
    <>
      {activeControl}
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        {...props}
      >
        {menuItems.map((item: any, index: number) => {
          return React.cloneElement(
            item, {
              key: index,
              selected: index === selectedIndex,
              onClick: (e: React.MouseEvent<HTMLElement>) => {
                handleMenuItemClick(e, index);
                if (item.props && item.props.onClick) {
                  item.props.onClick(e);
                }
              }
            },
          );
        })}
      </Menu>
    </>
  );
};

export default DropdownMenu;
