import React, { ReactElement } from 'react';
import { matchPath } from 'react-router-dom';

import SidebarNavListItem from './SidebarNavListItem';
import SidebarNavList from './SidebarNavList';
import { SidebarItemPageOptions } from './types';

interface ReduceChildRoutesProps {
  items: ReactElement[];
  page: SidebarItemPageOptions;
  depth?: number;
  currentRoute: string;
}

const reduceChildRoutes = ({
  items,
  page,
  depth = 0,
  currentRoute
}: ReduceChildRoutesProps) => {
  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    items?.push(
      <SidebarNavListItem
        key={`item-${page.title}`}
        depth={depth}
        icon={page.icon}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        disabled={page.disabled}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );
  } else {
    items?.push(
      <SidebarNavListItem
        key={`item-${page.title}`}
        depth={depth}
        href={page.href}
        icon={page.icon}
        badge={page.badge}
        title={page.title}
        disabled={page.disabled}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
