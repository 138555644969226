import React, { useEffect } from 'react';
import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLocale } from 'stores/appSlice';
import { localizationsCode } from 'constants/localizations';
import { csCZ, enUS } from './locales';

/*if (__BROWSER__) {*/
  i18next.use(i18nextXHRBackend);
/*}*/

i18next.init({
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  debug: process.env.NODE_ENV === 'development'/* && __BROWSER__*/,
  fallbackLng: localizationsCode.CZ,
  fallbackNS: ['translation'],
  partialBundledLanguages: true,
  resources: {
    [localizationsCode.CZ]: { translation: csCZ },
    [localizationsCode.EN]: { translation: enUS },
  },
  // parseMissingKeyHandler: (missing: any) => {
  //   if (process.env.NODE_ENV === 'development'/* && __BROWSER__*/) {
  //     console.warn('MISSING TRANSLATION:', missing);
  //   }
  //   return missing;
  // },
});

i18next.languages = Object.values(localizationsCode);

const I18N: React.FC<any> = ({ children }) => {
  const locale = useSelector(selectLocale);

  useEffect(() => {
    i18next.changeLanguage(locale);
  }, [locale]);

  return (
    <I18nextProvider i18n={i18next}>
      {children}
    </I18nextProvider>
  );
};

export default React.memo(I18N);
