import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, SelectProps } from '_template';
import Select from 'components/Select';

/** Podporavané měny pro frontend projektů */
export const SupportCurrency: any = {
  CZK: {
    name: 'Czech crown',
  },
  EUR: {
    name: 'Euro',
  },
  // 'USD': {
  //   name: 'US dollar',
  // }
};

const SupportCurrencySelect = (props: SelectProps) => {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      {Object.keys(SupportCurrency).map((currency: string) => (
        <MenuItem key={currency} value={currency}>
          {currency} - {t(`GENERAL.CURRENCY_NAMES.${currency}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SupportCurrencySelect;
