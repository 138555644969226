import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Alert } from '_template';

interface AlertLimitedAccessProps {
  children?: ReactNode;
}

const AlertLimitedAccess = ({ children }: AlertLimitedAccessProps) => (
  <Alert severity="error" variant="filled" sx={{ mb: 4 }}>
    {children ? children : <Trans>ERROR.LIMITED_ACCESS</Trans>}
  </Alert>
);

export default AlertLimitedAccess;
