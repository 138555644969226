import { useState } from 'react';

const useDataFilter = () => {
  const [searchQuery, setSearchQuery] = useState('');

  return {
    filterProps: {
      searchQuery,
      setSearchQuery,
    },
    searchQuery,
  };
};

export default useDataFilter;
