import { ModuleRightType } from 'constants/rights';

export const emailRights: ModuleRightType = {
  EMAIL: {
    LIST: 'EMAIL.LIST',
    // ADD: 'EMAIL.ADD',
    // EDIT: 'EMAIL.EDIT',
    // DELETE: 'EMAIL.DELETE',
  },
};
