import React, { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Button, DateTimePickerField, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '_template';
import { CreatePresentationMutation, UpdatePresentationMutation } from 'modules/Presentation/query';
import { PresentationData } from 'modules/Presentation/interfaces/presentation';
import { handleSaveData } from 'helpers/data/handleSaveData';
import PresentationTypeSelect from 'modules/Presentation/components/PresentationCategorySelect';
import NumberField from 'components/NumberField';
import TextField from 'components/TextField';

interface ConsultantPresentationModalProps {
  consultantId: string;
  data: PresentationData | null;
  onSubmit: () => void;
  onClose: () => void;
}

const ConsultantPresentationModal = ({ consultantId, data, onSubmit, onClose }: ConsultantPresentationModalProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<PresentationData>({
    ...data,
    deadline: data?.deadline || moment().minute(0).format('YYYY-MM-DD HH:mm'),
    end: data?.end || moment().minute(0).add(1, 'hour').format('YYYY-MM-DD HH:mm'),
    limit: data?.limit || 40,
  });

  const isNew = !data;

  const [createMutation] = useMutation(CreatePresentationMutation);
  const [updateMutation] = useMutation(UpdatePresentationMutation);

  const handleSubmit = () => {
    const variables = {
      data: {
        consultantId,
        category: formData.category,
        name: formData.name,
        location: formData.location,
        deadline: moment(formData.deadline).format(),
        end: moment(formData.end).format(),
        limit: formData.limit,
      },
    };

    if (!isNew) {
      (variables as any).id = data.id;
    }

    handleSaveData(
      (isNew ? createMutation : updateMutation),
      variables,
      onClose,
      onSubmit,
    );
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xs">
      <DialogTitle>
        {data ? <Trans>CONSULTANT.PRESENTATION.EDIT</Trans> : <Trans>CONSULTANT.PRESENTATION.NEW</Trans>}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PresentationTypeSelect
              name="category"
              required
              label={t('CONSULTANT.PRESENTATION.CATEGORY')}
              value={formData.category}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label={t('CONSULTANT.PRESENTATION.NAME')}
              value={formData.name}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              name="location"
              required
              label={t('CONSULTANT.PRESENTATION.LOCATION')}
              value={formData.location}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <DateTimePickerField
              name='deadline'
              required
              label={t('CONSULTANT.PRESENTATION.DEADLINE')}
              value={formData.deadline}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePickerField
              name='end'
              required
              label={t('CONSULTANT.PRESENTATION.END')}
              value={formData.end}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <NumberField
              name='limit'
              required
              label={t('CONSULTANT.PRESENTATION.LIMIT')}
              value={formData.limit}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleSubmit}>
          <Trans>FORM.SAVE</Trans>
        </Button>
        <Button variant='outlined' onClick={onClose}>
          <Trans>FORM.CANCEL</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsultantPresentationModal;
