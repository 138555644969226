import React, { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import {
  Grid,
  Link,
  LoaderScreen,
  Table,
  TableActions,
  TableColumn,
  TableContainer,
  displayInfo,
} from '_template';
import {
  DeletePresentationMutation,
  GetPresentationListQuery,
  ResendInviationToPresentationMutation,
} from 'modules/Presentation/query';
import { PresentationData } from 'modules/Presentation/interfaces/presentation';
import ButtonDelete from 'components/ButtonDelete';
import ButtonAdd from 'components/ButtonAdd';
import ConsultantPresentationModal from './ConsultantPresentationModal';

interface ConsultantPresentationListProps {
  consultantId: string;
}

const ConsultantPresentationList = ({
  consultantId,
}: ConsultantPresentationListProps) => {
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<PresentationData | null>(null);

  const presentationListQuery = useQuery(GetPresentationListQuery, {
    variables: {
      where: {
        consultantId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deletePresentation] = useMutation(DeletePresentationMutation);
  const [resendInviationToPresentation] = useMutation(
    ResendInviationToPresentationMutation
  );

  const { data, loading, error, refetch } = presentationListQuery;

  const onClickAdd = () => {
    setModalData(null);
    setShowModal(true);
  };

  const onClickEdit = (presentation: GQL.IPresentation) => {
    setModalData({
      ...presentation,
      consultantId: presentation?.consultant?.id,
    });
    setShowModal(true);
  };

  const onCloseModal = () => {
    setModalData(null);
    setShowModal(false);
  };

  const onClickResendInvitation = (presentation: GQL.IPresentation) => {
    setShowLoading(true);
    resendInviationToPresentation({
      variables: {
        id: presentation.id,
      },
    })
      .then(() => {
        displayInfo(t('CONSULTANT.PRESENTATION.INVITATIONS_WAS_RESENT'));
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  return (
    <>
      {showLoading && <LoaderScreen />}
      {showModal && (
        <ConsultantPresentationModal
          consultantId={consultantId}
          data={modalData}
          onSubmit={refetch}
          onClose={onCloseModal}
        />
      )}
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonAdd size="small" onClick={onClickAdd}>
            <Trans>CONSULTANT.PRESENTATION.ADD</Trans>
          </ButtonAdd>
        </Grid>
      </Grid>
      <TableContainer>
        <Table<GQL.IPresentation>
          dataSource={data?.presentationList?.rows}
          recordsCount={data?.presentationList?.count}
          loading={loading}
          error={error}
        >
          <TableColumn
            label={t('CONSULTANT.PRESENTATION.DEADLINE')}
            dataIndex="deadline"
            render={(row: GQL.IPresentation) => (
              <Link onClick={() => onClickEdit(row)}>
                {moment(row.deadline).format('D.M.Y H:mm')}
              </Link>
            )}
          />
          <TableColumn
            label={t('CONSULTANT.PRESENTATION.CATEGORY')}
            dataIndex="category"
            render={(row: GQL.IPresentation) => {
              return `${t(
                `CONSULTANT.PRESENTATION.CATEGORY_TYPE.${row.category?.toUpperCase()}`
              )}${row.name ? ` (${row.name})` : ``}`;
            }}
          />
          <TableColumn
            label={t('CONSULTANT.PRESENTATION.LOCATION')}
            dataIndex="location"
          />
          <TableColumn
            label={t('CONSULTANT.PRESENTATION.LIMIT')}
            dataIndex="limit"
            align="right"
          />
          <TableColumn
            label={t(`CONSULTANT.PRESENTATION.PARTICIPATNS`)}
            dataIndex="participants"
            align="center"
            sx={{
              display: {
                xs: 'none',
                sm: 'table-cell',
                width: '140px',
              },
            }}
            render={(row: GQL.IPresentation) => {
              return (
                <>
                  {row.participants?.length || 0}
                  {(row.participants?.length || 0) > 0 && (
                    <>
                      {', '}
                      <Link onClick={() => onClickResendInvitation(row)}>
                        {t('CONSULTANT.PRESENTATION.RESEND_INVITATION')}
                      </Link>
                    </>
                  )}
                </>
              );
            }}
          />
          <TableActions
            render={(row: GQL.IPresentation) => {
              return (
                <ButtonDelete
                  message={t('CONSULTANT.PRESENTATION.REALLY_TO_DELETE')}
                  onDelete={() =>
                    deletePresentation({ variables: { id: row.id } })
                  }
                  refetch={refetch}
                />
              );
            }}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default ConsultantPresentationList;
