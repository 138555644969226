import React from 'react';
import Button, { ButtonProps } from '../Button';

const ButtonSubmit = (props: ButtonProps) => (
  <Button
    type="submit"
    {...props}
  >
    {props.children}
  </Button>
);

export default ButtonSubmit;
