import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { Card, CardContent } from '_template';
import SettingTitle from 'modules/Setting/components/SettingTitle';
import SettingLayout from 'modules/Setting/components/SettingLayout';
import SettingValue from 'modules/Setting/components/SettingValue';
import ConsultantNumberFormatSetting from './ConsultantNumberFormatSetting';

const ConsultantSetting = () => {
  const { t } = useTranslation();

  return (
    <SettingLayout>
      <SettingTitle icon={<Diversity3Icon />}>
        <Trans>CONSULTANT.MODEL_TITLE</Trans>
      </SettingTitle>
      <Card>
        <CardContent>
          <SettingValue label={t('CONSULTANT.SETTING_NUMBER_FORMAT')}>
            <ConsultantNumberFormatSetting />
          </SettingValue>
        </CardContent>
      </Card>
    </SettingLayout>
  );
};

export default ConsultantSetting;
