import gql from 'graphql-tag';

export const GetLoggedUserQuery: any = gql`
  query loggedUser($token: String!) {
    loggedUser(token: $token) {
      id
      userGroup {
        id
        name
        rights
      }
      name
      userName
      email
    }
  }
`;

export const UserLoginMutation = gql`
  mutation userLogin($userName: String!, $password: String!) {
    userLogin(userName: $userName, password: $password) {
      authToken
      user {
        id
        name
        userName
        email
      }
    }
  }
`;

export const GetUserListQuery: any = gql`
  query userList($where: UserListWhereInput, $limit: LimitsInput, $orderBy: OrderByInput) {
    userList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows{
        id
        userGroup {
          id
          name
        }
        name
        userName
        email
        active
      }
      count
    }
  }
`;

export const GetUserQuery: any = gql`
  query user($where: UserWhereInput!) {
    user(where: $where) {
      id
      userGroup {
        id
        name
      }
      name
      userName
      email
      active
    }
  }
`;

export const CreateUserMutation: any = gql`
  mutation createUser($data: CreateUserInput!) {
    createUser(data: $data) {
      id
    }
  }
`;

export const UpdateUserMutation: any = gql`
  mutation updateUser($id: ID!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      id
    }
  }
`;

export const UpdateUserProfileMutation: any = gql`
  mutation updateUserProfile($token: String!, $data: UpdateUserProfileInput!) {
    updateUserProfile(token: $token, data: $data) {
      id
    }
  }
`;

export const DeleteUserMutation: any = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const SaveChangePasswordMutation: any = gql`
  mutation saveChangePassword($data: SaveChangePasswordInput!) {
    saveChangePassword(data: $data) {
      id
    }
  }
`;
