import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, MenuItem, IconButton, Avatar, Tooltip } from '_template';
import { localizationsCode } from 'constants/localizations';
import { selectLocale, changeLocale } from 'stores/appSlice';

const Languages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const locale = useSelector(selectLocale);

  const toggleMenu = (event: any) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const onClickLanguage = (lng: string) => {
    dispatch(changeLocale(lng));
    closeMenu();
  };

  if (Object.keys(localizationsCode).length === 1) {
    return null;
  }

  return (
    <React.Fragment>
      <Tooltip title={t('GENERAL.BACKOFFICE_LOCALIZATION')}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="small"
        >
          <Avatar
            sx={(theme) => ({
              fontSize: theme.typography.fontSize,
              width: '32px',
              height: '32px',
            })}
          >
            {/* @ts-ignore */}
            {Object.keys(localizationsCode).find((lng) => localizationsCode[lng] === locale)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        transitionDuration={0}
        onClose={closeMenu}
      >
        {Object.keys(localizationsCode).map((lng: string) => (
          // @ts-ignore
          <MenuItem key={lng} selected={locale === lng} onClick={() => onClickLanguage(localizationsCode[lng])}>
            {lng}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default Languages;
