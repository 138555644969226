import React from 'react';
import { Trans } from 'react-i18next';
import { ROUTE } from 'constants/route';
import { SettingRouteType } from 'constants/settings';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ConsultantSetting from '../components/ConsultantSetting';

export const settingRouteConsultant: SettingRouteType[] = [
  {
    path: ROUTE.CONSULTANT_SETTING,
    label: <Trans>CONSULTANT.MODEL_TITLE</Trans>,
    icon: <Diversity3Icon />,
    component: <ConsultantSetting />,
  }
];
