import React, { ChangeEvent } from 'react';
import {
  FormControl,
  FormLabel,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';
import { useFormikContext } from 'formik';
import {
  hasControlError,
  textControlError,
} from '_template/helpers/forms/controlError';

type MyTextFieldProps = TextFieldProps & {
  withoutFormik?: boolean;
  withoutLabel?: boolean;
  withoutHelperText?: boolean;
  sxControl?: any;
};

const TextField = ({
  withoutFormik,
  withoutLabel = false,
  withoutHelperText = false,
  sxControl,
  ...props
}: MyTextFieldProps) => {
  // eslint-disable-next-line
  const formikContext = withoutFormik ? null : useFormikContext();

  const onChange = (e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (formikContext) {
      formikContext?.setFieldValue(name, value);
    }
  };

  const controlValue =
    props.value ||
    (props.name &&
      formikContext?.values &&
      (formikContext?.values as any)[props.name]);

  const controlError =
    props.error ||
    (props.name &&
      formikContext?.errors &&
      (formikContext?.errors as any)[props.name]);

  const helperText = !withoutHelperText ? ' ' : '';

  return (
    <FormControl sx={{ ...(sxControl || {}) }}>
      {!withoutLabel && props.label && (
        <FormLabel required={props.required} focused={props.focused}>
          {props.label}
        </FormLabel>
      )}
      <MuiTextField
        value={controlValue}
        onChange={onChange}
        {...props}
        label={null}
        error={hasControlError(controlError) || props.error}
        helperText={
          textControlError(controlError) || props.helperText || helperText
        }
      />
    </FormControl>
  );
};

export default TextField;
