import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider } from '_template';
import Breadcrumbs from 'components/Breadcrumbs';
import Helmet from 'components/Helmet';
import Actions from './components/Actions';
import PaymentTable from './components/PaymentTable';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';

const PaymentList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t<string>('PAYMENT.MODEL_TITLE')} />
      <ContentHeader
        title={t('PAYMENT.MODEL_TITLE')}
        actions={<Actions />}
        breadcrumbs={
          <Breadcrumbs
            section={t('PAYMENT.MODEL_TITLE')}
          />
        }
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={RIGHTS.PAYMENT.LIST}>
        <PaymentTable />
      </LimitedAccess>
    </>
  );
};

export default PaymentList;
