import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface ProjectState {
  project: GQL.IProject | null;
}

const initialState: ProjectState = {
  project: null,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<GQL.IProject>) => {
      state.project = action.payload;
    },
  },
});

export const { setProject } = projectSlice.actions;

export const selectProject = (state: RootState) => state.project.project;
export const selectProjectIndex = (state: RootState) => state.project.project?.index;

export default projectSlice.reducer;
