import React from 'react';
import { Trans } from 'react-i18next';
import { Button, DropdownMenu } from '_template';
import EmailIcon from '@mui/icons-material/Email';
import MenuItemSendPaymentInstructions from './MenuItemSendPaymentInstructions';
import MenuItemSendMembershipConfirmation from './MenuItemSendMembershipConfirmation';

export interface DropDownButtonSendActionsProps {
  consultant: GQL.IConsultant;
  refetch: () => void;
}

const DropDownButtonSendActions = (props: DropDownButtonSendActionsProps) => {
  return (
    <DropdownMenu
      control={
        <Button variant="contained" size="small" startIcon={<EmailIcon />}>
          <Trans>CONSULTANT.RESEND</Trans>
        </Button>
      }
    >
      <MenuItemSendPaymentInstructions {...props} />
      <MenuItemSendMembershipConfirmation {...props} />
    </DropdownMenu>
  );
};

export default DropDownButtonSendActions;
