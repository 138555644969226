import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete as MuiAutocomplete, TextFieldProps } from '@mui/material';
import TextField from 'components/TextField';

interface AutoCompleteOption {
  label: string;
  value: string;
}

type AutoCompleteProps = TextFieldProps & {
  autocompleteProps?: any;
  readOnly?: boolean;
  multiple?: boolean;
  options: Array<AutoCompleteOption>;
};

const AutoComplete = ({
  options,
  multiple = false,
  autocompleteProps,
  readOnly = false,
  ...props
}: AutoCompleteProps) => {
  const [value, setValue] = useState<AutoCompleteOption | AutoCompleteOption[] | null>(null);
  const [inputValue, setInputValue] = useState('');

  /* eslint-disable-next-line */
  const formikContext = useFormikContext();

  const onChange = (_: any, reason: AutoCompleteOption | null) => {
    setValue(reason);
    const reasonValue = multiple ? Array.isArray(reason) && reason?.map((v) => v.value) : reason?.value;
    if (props.onChange && props.name) {
      props.onChange({
        target: {
          name: props.name,
          value: reasonValue,
        },
      } as any);
    } else if (formikContext && props.name) {
      formikContext?.setFieldValue(props.name, reason?.value || null);
    }
  };

  const onBlur = () => {
    // if (formikContext && props.name) {
    //   formikContext?.setFieldValue(props.name, Array.isArray(value) && multiple
    //     ? value?.map((v) => v.value)
    //     : (value as AutoCompleteOption)?.value);
    // }
  };

  const getOption = useCallback(
    (optionId: string | string[]): AutoCompleteOption | AutoCompleteOption[] | null => {
      if (multiple) {
        return (options || []).filter((opt: AutoCompleteOption) => optionId?.includes(opt.value));
      }
      return options?.find((opt: AutoCompleteOption) => opt.value === optionId) || null;
    },
    // eslint-disable-next-line
    [options]
  );

  const controlValue =
    props.value || (props.name && formikContext?.values && (formikContext?.values as any)[props.name]);

  useEffect(() => {
    setValue(getOption(controlValue));
  }, [getOption, controlValue]);

  return (
    <MuiAutocomplete
      {...props}
      {...autocompleteProps}
      value={value || []}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      readOnly={readOnly}
      multiple={multiple}
      options={options}
      getOptionLabel={(option: AutoCompleteOption) => {
        if (Array.isArray(option)) {
          return option;
        }
        return option.label;
      }}
      isOptionEqualToValue={(option: AutoCompleteOption, value: AutoCompleteOption) => option.value === value.value}
      renderInput={(params) => <TextField {...params} {...props} />}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default AutoComplete;
