import React, { ReactNode } from 'react';
import { Card, Grid } from '@mui/material';
import QuickSearchField from '../QuickSearchField';

interface FilterContainerProps {
  searchQuery?: string;
  setSearchQuery?: (value: string) => void;
  children?: ReactNode;
}

const FilterContainer = ({
  searchQuery,
  setSearchQuery,
  children,
}: FilterContainerProps) => {
  return (
    <Card sx={{ mb: 3, p: 3, display: 'flex' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {children}
          {setSearchQuery && (
            <QuickSearchField
              searchQuery={searchQuery}
              onSubmit={setSearchQuery}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default FilterContainer;
