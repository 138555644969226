import React from 'react';
import { ButtonProps, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ButtonDeleteProps extends ButtonProps {
  tooltip?: string | null;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonDelete = ({ tooltip = null, onClick, ...props }: ButtonDeleteProps) => {
  if (props.disabled) {
    return (
      <IconButton onClick={onClick} {...props}>
        <DeleteIcon />
      </IconButton>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} {...props}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ButtonDelete;
