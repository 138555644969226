import gql from 'graphql-tag';

export const GetRegistrationListQuery: any = gql`
  query registrationList($where: RegistrationListWhereInput, $limit: LimitsInput, $orderBy: OrderByInput) {
    registrationList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        parent {
          id
          number
          lastName
          firstName
          isSystem
        }
        position {
          id
          code
          name
        }
        currency {
          id
          code
        }
        companyName
        companyIC
        companyDIC
        lastName
        firstName
        titleBefore
        titleAfter
        mobilePrefix
        mobileNumber
        email
        createdAt
      }
      count
    }
  }
`;

export const GetRegistrationQuery: any = gql`
  query registration($where: RegistrationWhereInput!) {
    registration(where: $where) {
      id
      parent {
        id
        number
        lastName
        firstName
      }
      position {
        id
        code
        name
      }
      currency {
        id
        code
      }
      companyName
      companyIC
      companyDIC
      lastName
      firstName
      titleBefore
      titleAfter
      mobilePrefix
      mobileNumber
      email
      createdAt
    }
  }
`;

export const CreateRegistrationMutation: any = gql`
  mutation createRegistration($data: CreateOrUpdateRegistrationInput!) {
    createRegistration(data: $data) {
      id
    }
  }
`;

export const UpdateRegistrationMutation: any = gql`
  mutation updateRegistration($id: ID!, $data: CreateOrUpdateRegistrationInput!) {
    updateRegistration(id: $id, data: $data) {
      id
    }
  }
`;

export const DeleteRegistrationMutation: any = gql`
  mutation deleteRegistration($id: ID!) {
    deleteRegistration(id: $id)
  }
`;
