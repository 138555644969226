import { enqueueSnackbar, SnackbarOrigin } from 'notistack';

const defaultOptions = {
  autoHideDuration: 4000,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'top',
  } as SnackbarOrigin,
};

export const displaySuccess = (message: string) => {
  enqueueSnackbar<"success">(message, {
    ...defaultOptions,
    variant: 'success',
  });
};

export const displayAlert = (message: string) => {
  enqueueSnackbar(message, {
    ...defaultOptions,
    variant: 'error',
  });
};

export const displayWarning = (message: string) => {
  enqueueSnackbar(message, {
    ...defaultOptions,
    variant: 'warning',
  });
};

export const displayInfo = (message: string) => {
  enqueueSnackbar(message, {
    ...defaultOptions,
    variant: 'info',
  });
};
