import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import { configureStore } from '@reduxjs/toolkit';
import { ApolloClient, InMemoryCache, ApolloProvider, concat, ApolloLink, HttpLink } from '@apollo/client';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import IntlProvider from './i18n/IntlProvider';
import { AuthToken } from './services/AuthToken';
import { rootReducer } from './stores';
import App from './App';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL_GRAPHQL,
  fetch,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: AuthToken.getToken() ? `Bearer ${AuthToken.getToken()}` : null,
    },
  });

  return forward ? forward(operation) : null;
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
});

const preloadedState = window.__PRELOADED_STATE__ || {};

const clientStore = configureStore({
  reducer: rootReducer,
  preloadedState,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={clientStore}>
        <IntlProvider>
          <HelmetProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </HelmetProvider>
        </IntlProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
