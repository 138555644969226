import React from 'react';
import { Grid, MenuItem, TextFieldProps } from '_template';
import TextField from 'components/TextField';
import Select from 'components/Select';
import { CountryTypeList, CountryType } from './prefixes';

type PhoneFieldProps = TextFieldProps & {
  readOnly?: boolean;
}

const PhoneField = ({ readOnly, ...props }: PhoneFieldProps) => (
  <Grid container spacing={1}>
    <Grid item sx={{ width: 110 }}>
      <Select
        readOnly={readOnly}
        label={props.label}
        name={`${props.name}Prefix`}
      >
        {CountryTypeList.map((country: CountryType, index: number) => (
          <MenuItem key={index} value={`+${country.phone}`}>
            {`+${country.phone}`} ({country.code}) {country.label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
    <Grid item sx={{ display: 'flex', alignItems: 'flex-end', width: 'calc(100% - 110px)' }}>
      <TextField
        readOnly={readOnly}
        mask={/^\+?\d{0,15}$/}
        name={`${props.name}Number`}
      />
    </Grid>
  </Grid>
);

export default PhoneField;
