import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Switch, Divider, FormLabel, Grid } from '_template';
import { RIGHTS } from 'constants/rights';
import { toggleInArray } from 'helpers/toggleInArray';
import { UserGroupData } from 'modules/UserGroup/interfaces/userGroup';

interface UserGroupRightProps {
  label: string;
  value: string;
  disabled?: boolean;
  rights: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserGroupRight = ({ rights, ...props }: UserGroupRightProps) => (
  <Grid item xs={12}>
    <Switch
      // sx={{ py: .8 }}
      checked={rights.includes(props.value)}
      {...props}
    />
  </Grid>
);

interface UserGroupRightTypeProps {
  rightKey: string;
  rights: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserGroupRightType = ({ rightKey, rights, onChange }: UserGroupRightTypeProps) => {
  const { t } = useTranslation();

  const disabled = rights.includes('ALL');

  return (
    <Grid container>
      <Grid key={rightKey} item xs={12} sx={{ pt: 3.4, pr: 3, textAlign: 'left' }}>
        <FormLabel disabled={disabled}>
          {t(`${rightKey}.MODEL_TITLE`)}
        </FormLabel>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ pt: 1, mb: 1 }} />
          {Object.keys(RIGHTS[rightKey]).map((right: string) => (
            <UserGroupRight
              key={right}
              value={`${rightKey}.${right}`}
              label={t(`${rightKey}.RIGHTS.${right}`)}
              disabled={disabled}
              rights={rights}
              onChange={onChange}
            />
          ))}
      </Grid>
    </Grid>
  );
};

const UserGroupRights = () => {
  const { t } = useTranslation();

  const formikContext = useFormikContext<UserGroupData>();

  const rights: string[] = formikContext?.values?.rights || [];

  const onChangeRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (formikContext) {
      formikContext?.setFieldValue('rights', toggleInArray<string>(rights, value));
    }
  };

  return (
    <Box sx={{ py: 1 }}>
      <Grid container>
        <Grid item xs={12} sx={{ pt: 3.4, pr: 3 }} />
        <UserGroupRight
          value="ALL"
          label={t(`USER_GROUP.FULL_ACCESS`)}
          rights={rights}
          onChange={onChangeRight}
        />
      </Grid>
      {Object.keys(RIGHTS).map((rightKey: string) => (
        <UserGroupRightType
          key={rightKey}
          rightKey={rightKey}
          rights={rights}
          onChange={onChangeRight}
        />
      ))}
    </Box>
  );
};

export default UserGroupRights;
