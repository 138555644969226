import React from 'react';
import { Link as NavLink } from 'react-router-dom';
import { Link } from '_template';

interface PhoneProps {
  data?: {
    phonePrefix?: string | null;
    phoneNumber?: string | null;
  };
  link?: boolean;
}

const Phone = ({ data, link = false }: PhoneProps) => {
  const phoneElements: string[] = [
    (data?.phonePrefix || ''),
    (data?.phoneNumber || '')
  ];

  const phone = phoneElements.join(' ');

  if (link && phone) {
    return (
      <Link component={NavLink} to={`tel:${phone}`}>{phone}</Link>
    );
  }

  return <>{phone}</>;
};

export default Phone;
