import React from 'react';
import { Button as TemplateButton, ButtonProps as TemplateButtonProps } from '_template';
import { useUserAccess } from 'hooks/useUserAccess';

interface ButtonProps extends TemplateButtonProps {
  right?: string;
}

const Button = ({ right, ...props }: ButtonProps) => {
  const { getAccess } = useUserAccess();

  let disabled = false;
  if (props.disabled) {
    disabled = props.disabled;
  }
  if (!disabled && right) {
    disabled = !getAccess(right);
  }

  return (
    <TemplateButton
      {...props}
      disabled={disabled}
    />
  );
};

export default Button;
