import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentHeader, Divider, Loader } from '_template';
import NotFound from '_template/pages/NotFound';
import Breadcrumbs from 'components/Breadcrumbs';
import { GetPositionQuery } from 'modules/Position/query/Position';
import { ROUTE } from 'constants/route';
import PositionForm from './PositionForm';
import LimitedAccess from 'components/LimitedAccess';
import { RIGHTS } from 'constants/rights';
import Helmet from 'components/Helmet';

const PositionDetail = () => {
  const { t } = useTranslation();
  const { recordId } = useParams();

  const positionQuery = useQuery(GetPositionQuery, {
    variables: {
      where: {
        id: recordId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { loading, error } = positionQuery;

  const data = positionQuery?.data?.position;

  const isNew = recordId === 'new';

  if (loading) {
    return <Loader />;
  }

  if (error && !isNew) {
    return <NotFound />;
  }

  const title = isNew ? t('POSITION.NEW') : t('POSITION.EDIT');

  return (
    <>
      <Helmet title={title} />
      <ContentHeader title={t('POSITION.MODEL_TITLE')} />
      <Breadcrumbs
        links={{
          [ROUTE.POSITION_LIST]: t('POSITION.MODEL_TITLE'),
        }}
        section={title}
      />
      <Divider sx={{ my: 4 }} />
      <LimitedAccess right={isNew ? RIGHTS.POSITION.ADD : RIGHTS.POSITION.EDIT}>
        <PositionForm
          recordId={recordId}
          data={{
            ...data,
            parentId: data?.parent ? data.parent.id : null,
            currencyId: data?.currency ? data.currency.id : null,
            published: data ? data.published : true,
            progressive: data ? data.progressive : false,
            conditions:
              data && data.conditions
                ? data.conditions.map((condition: GQL.IPositionCondition) => ({
                    firstLevelSize: condition.firstLevelSize,
                    firstLevelOnPositions: condition.firstLevelOnPositions,
                    teamSize: condition.teamSize,
                    sumAmount: condition.sumAmount,
                  }))
                : null,
          }}
        />
      </LimitedAccess>
    </>
  );
};

export default PositionDetail;
