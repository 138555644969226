import React from 'react';
import Layout, { LayoutProps } from '../Layout';
import SimpleLayout from '../SimpleLayout';

interface LayoutByAuthProps extends LayoutProps {
  isAuthorized?: boolean;
};

const LayoutByAuth = ({ isAuthorized = false, ...props }: LayoutByAuthProps) => {
  if (isAuthorized) {
    return (
      <Layout {...props} />
    );
  }

  return (
    <SimpleLayout>
      {props.children}
    </SimpleLayout>
  );
};

export default LayoutByAuth;
