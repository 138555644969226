import React from 'react';
import { Trans } from 'react-i18next';
import { ROUTE } from 'constants/route';
import { SettingRouteType } from 'constants/settings';
import PatternIcon from '@mui/icons-material/Pattern';
import TaxDocumentSetting from '../components/TaxDocumentSetting';

export const settingRouteTaxDocument: SettingRouteType[] = [
  {
    path: ROUTE.TAX_DOCUMENT_SETTING,
    label: <Trans>TAX_DOCUMENT.MODEL_TITLE</Trans>,
    icon: <PatternIcon />,
    component: <TaxDocumentSetting />,
  }
];
