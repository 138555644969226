import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
  label: string;
  index?: number;
  value?: number;
  children?: ReactNode;
}

const TabPanel = ({ label, index, value, children, ...props }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
