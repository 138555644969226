import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Drawer, DrawerProps, ListItemButton } from '@mui/material';
import { SidebarItemOptions } from './types';
import SidebarNav from './SidebarNav';
import Footer from './SidebarFooter';

const BrandIcon = styled('img')`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

interface SidebarProps extends DrawerProps {
  header?: string;
  logo?: any;
  items?: SidebarItemOptions[];
  showFooter?: boolean;
}

const Sidebar = ({ header, logo, items, showFooter = true, ...rest }: SidebarProps) => {
  return (
    <Drawer
      variant="permanent"
      {...rest}
      sx={{
        borderRight: 0,
        "& > div": {
          borderRight: 0,
        },
      }}
    >
      <ListItemButton
        component={NavLink}
        to="/"
        sx={(theme: any) => ({
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.sidebar.header.color,
          backgroundColor: theme.sidebar.header.background,
          fontFamily: theme.typography.fontFamily,
          minHeight: '56px',
          px: 4,
          justifyContent: 'center',
          cursor: 'pointer',
          flexGrow: 0,
          [theme.breakpoints.up('sm')]: {
            minHeight: '64px',
          },
          "&:hover": {
            backgroundColor: theme.sidebar.header.background,
          },
        })}
      >
        {logo && <BrandIcon src={logo} />}
        <Box ml={1}>
          {header}
        </Box>
      </ListItemButton>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
