import React from 'react';
import { CircularProgress, SxProps, Theme } from '@mui/material';
import Box from '../Box';

interface LoaderProps {
  sx?: SxProps<Theme>;
  center?: boolean;
  iconProps?: {
    size?: number | string;
    sx?: SxProps<Theme>;
  };
}

const Loader = ({ sx, center = true, iconProps }: LoaderProps) => {
  const wrapperProps = center
    ? {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        minHeight: '100%',
      }
    : {};

  return (
    <Box
      sx={{
        ...wrapperProps,
        ...sx,
      }}
    >
      <CircularProgress color="secondary" {...iconProps} />
    </Box>
  );
};

export default Loader;
