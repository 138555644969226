import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import Select from 'components/Select';
import { MenuItem } from '_template';
import { useQuery } from '@apollo/client';
import { GetCommissionPeriodListQuery } from 'modules/Commission/query';

interface FilterProps {
  selected: string | null;
  onChange: (period: string) => void;
}

const Filter = ({ selected, onChange }: FilterProps) => {
  const { t } = useTranslation();
  const format = 'YYYY-MM-DD';

  const commissionPeriodsQuery = useQuery(GetCommissionPeriodListQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const { data } = commissionPeriodsQuery;

  const periods = (data?.commissionPeriodList || []).map(
    (commission: GQL.ICommission) => moment(commission.periodFrom)
  );

  useEffect(() => {
    if (!selected && periods.length > 0) {
      onChange(periods[0].format(format));
    }
  }, [selected, periods, onChange]);

  return (
    <Select
      withoutHelperText
      inputProps={{
        sx: { width: 200, maxWidth: 200 },
      }}
      fullWidth={false}
      required
      label={t('COMMISSION.SELECT_PERIOD')}
      value={selected}
      onChange={(e) => onChange(e.target.value)}
    >
      {periods.map((period: Moment) => {
        return (
          <MenuItem
            key={period.format()}
            selected={period.format(format) === selected}
            value={period.format(format)}
          >
            {t(`MONTH.${period.format('M')}`)} {period.format('Y')}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default Filter;
