import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from '_template';
import { SETTINGS } from 'constants/settings';
import { REGISTRATION_MODE } from 'modules/Registration/constants/registration';
import SettingsForm from 'components/SettingsForm';

const RegistrationModeSetting = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    [SETTINGS.REGISTRATION.MODE]: Yup.string().required(t<string>('GENERAL.FIELD_REQUIRED')),
  });

  return (
    <SettingsForm validationSchema={validationSchema}>
      {({ handleSubmit }) => (
        <RadioGroup 
          name={SETTINGS.REGISTRATION.MODE}
          helperText={t<string>('REGISTRATION.SETTING_REGISTRATION_MODE_HELPERTEXT')}
          withoutHelperText
        >
          <Radio 
            value={REGISTRATION_MODE.AUTOMATIC} 
            label={t<string>('REGISTRATION.SETTINGS.AUTOMATIC')}
            disabled
            onBlur={() => handleSubmit()}
          />
          <Radio 
            value={REGISTRATION_MODE.AFTER_PAYMENT} 
            label={t<string>('REGISTRATION.SETTINGS.AFTER_PAYMENT')}
            onBlur={() => handleSubmit()} 
          />
          <Radio 
            value={REGISTRATION_MODE.MANUAL} 
            label={t<string>('REGISTRATION.SETTINGS.MANUAL')}
            disabled
            onBlur={() => handleSubmit()}
          />
        </RadioGroup>
      )}
    </SettingsForm>
  );
};

export default RegistrationModeSetting;
