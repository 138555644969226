import moment from 'moment';
import UniversalCookie from 'universal-cookie';

export default class Cookies {
  static cookies = new UniversalCookie();

  static get = (key: string): string | null => {
    return Cookies.cookies.get(key);
  }

  static set = (key: string, value: string, expireIn: number) => {
    Cookies.cookies.set(key, value, {
      path: '/',
      expires: moment().add(expireIn, 's').toDate(),
    });
  }

  static remove = (key: string) => {
    Cookies.cookies.remove(key, {
      path: '/',
    });
  }
}