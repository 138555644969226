import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '_template';
import Select from 'components/Select';
import { useMutation } from '@apollo/client';
import { CalculateCommissionMutation } from 'modules/Commission/query';

interface CalculateModalData {
  periodFrom: string;
}

interface CalculateModalProps {
  onClose: () => void;
}

const CalculateModal = ({ onClose }: CalculateModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [calculateCommissionMutation] = useMutation(
    CalculateCommissionMutation
  );

  const handleSubmit = (values: CalculateModalData) => {
    const periodFrom = moment(values.periodFrom);
    const periodTo = moment(values.periodFrom).endOf('month');
    setLoading(true);
    calculateCommissionMutation({
      variables: {
        data: {
          periodFrom: periodFrom.format('YYYY-MM-DD'),
          periodTo: periodTo.endOf('month').format('YYYY-MM-DD'),
        },
      },
      refetchQueries: ['commissionList'],
    })
      .then(() => {
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const periods = [-2, -1, 0, 1, 2].map((n: number) =>
    moment().add(n, 'month').startOf('month').format('YYYY-MM-DD')
  );

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Trans>COMMISSION.CALCULATE</Trans>
      </DialogTitle>
      <Formik<CalculateModalData>
        initialValues={{
          periodFrom: localStorage.getItem('commissionPeriod') || periods[0],
        }}
        onSubmit={handleSubmit}
      >
        {({ values, ...formikProps }) => (
          <>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Select
                    name="periodFrom"
                    value={values.periodFrom}
                    label={t('COMMISSION.CALCULATE_PERIOD')}
                  >
                    {periods.map((period: string) => (
                      <MenuItem value={period}>
                        {t(`MONTH.${moment(period).format('M')}`)}{' '}
                        {moment(period).format('Y')}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                loading={loading}
                onClick={() => formikProps.handleSubmit()}
              >
                <Trans>COMMISSION.RUN</Trans>
              </Button>
              <Button variant="outlined" onClick={onClose}>
                <Trans>FORM.CANCEL</Trans>
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CalculateModal;
