import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import Notifications from '../components/Notifications';

const Provider: React.FC<any> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Notifications />
      <ThemeContextProvider>
        {children}
      </ThemeContextProvider>
    </LocalizationProvider>
  );
};

export default Provider;
