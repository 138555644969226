import gql from 'graphql-tag';

export const GetConsultantListQuery: any = gql`
  query consultantList(
    $where: ConsultantListWhereInput
    $limit: LimitsInput
    $orderBy: OrderByInput
  ) {
    consultantList(where: $where, limit: $limit, orderBy: $orderBy) {
      rows {
        id
        parent {
          id
          number
          lastName
          firstName
          isSystem
        }
        position {
          id
          code
          name
        }
        avatarFile {
          id
          storageLink
        }
        # currency
        isSystem
        registrationDate
        active
        number
        firstName
        lastName
        mobilePrefix
        mobileNumber
        email
        companyName
        companyIC
        companyDIC
        companyICDPH
        permanentAddress
        bankIBAN
        bankSWIFT
        vs
        canPresentations
        userName
        lastLogin
        bonatoNumber
      }
      count
    }
  }
`;

export const GetConsultantQuery: any = gql`
  query consultant($where: ConsultantWhereInput!) {
    consultant(where: $where) {
      id
      parent {
        id
        number
        lastName
        firstName
      }
      position {
        id
        code
        name
      }
      files {
        id
      }
      # currency
      isSystem
      registrationDate
      active
      number
      firstName
      lastName
      mobilePrefix
      mobileNumber
      email
      companyName
      companyIC
      companyDIC
      companyICDPH
      permanentAddress
      bankIBAN
      bankSWIFT
      vs
      canPresentations
      userName
      lastLogin
      bonatoNumber
    }
  }
`;

export const CreateConsultantMutation: any = gql`
  mutation createConsultant($data: CreateConsultantInput!) {
    createConsultant(data: $data) {
      id
    }
  }
`;

export const UpdateConsultantMutation: any = gql`
  mutation updateConsultant($id: ID!, $data: UpdateConsultantInput!) {
    updateConsultant(id: $id, data: $data) {
      id
    }
  }
`;

export const UpdateConsultantProfileMutation: any = gql`
  mutation updateConsultantProfile(
    $token: String!
    $data: UpdateConsultantProfileInput!
  ) {
    updateConsultantProfile(token: $token, data: $data) {
      id
    }
  }
`;

export const DeleteConsultantMutation: any = gql`
  mutation deleteConsultant($id: ID!) {
    deleteConsultant(id: $id)
  }
`;

export const SaveChangePasswordMutation: any = gql`
  mutation saveChangePassword($data: SaveChangePasswordInput!) {
    saveChangePassword(data: $data) {
      id
    }
  }
`;

export const ActivateConsultantMutation: any = gql`
  mutation activateConsultant($id: ID!) {
    activateConsultant(id: $id) {
      id
    }
  }
`;

export const ResendPaymentInstructionsMessageMutation: any = gql`
  mutation resendPaymentInstructionsMessage($consultantId: ID!) {
    resendPaymentInstructionsMessage(consultantId: $consultantId)
  }
`;

export const ResendMembershipConfirmationMessageMutation: any = gql`
  mutation resendMembershipConfirmationMessage($consultantId: ID!) {
    resendMembershipConfirmationMessage(consultantId: $consultantId)
  }
`;
