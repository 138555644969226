import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextFieldProps } from '_template';
import AutoComplete from 'components/AutoComplete';
import { presentationCategories } from 'modules/Presentation/constants/presentation';
import { getPresentationCategoryNameTranslateKey } from 'modules/Presentation/helpers/presentation';

type PresentationCategorySelectProps = TextFieldProps & {
  readOnly?: boolean;
};

const PresentationCategorySelect = (props: PresentationCategorySelectProps) => {
  const { t } = useTranslation();

  const options = presentationCategories.map((category: string) => ({
    label: t(getPresentationCategoryNameTranslateKey(category)),
    value: category,
  }));

  return (
    <AutoComplete
      {...props}
      options={options}
      autocompleteProps={{
        disableClearable: true,
        renderOption: (props: any, option: any) => (
          <Box component='li' {...props}>
            {option.label}
          </Box>
        ),
      }}
    />
  );
};

export default PresentationCategorySelect;
