import * as Yup from 'yup';
import React from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Tabs, TabPanel, Switch } from '_template';
import { CreatePositionMutation, UpdatePositionMutation } from 'modules/Position/query';
import { PositionData } from 'modules/Position/interfaces/position';
import { PositionSelect } from 'modules/Position/components';
import { handleSaveData } from 'helpers/data/handleSaveData';
import TextField from 'components/TextField';
import { ROUTE } from 'constants/route';
import PositionConditionTable from './PositionConditionTable';
import { CurrencySelect } from 'modules/Currency/components';
import { PriceField } from 'components/PriceField';

interface PositionFormProps {
  recordId?: string;
  data?: PositionData;
}

const PositionForm = ({ recordId, data }: PositionFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isNew = recordId === 'new';

  const [createMutation] = useMutation(CreatePositionMutation);
  const [updateMutation] = useMutation(UpdatePositionMutation);

  const onCancel = () => navigate(ROUTE.POSITION_LIST);

  const validationSchema = Yup.object({
    code: Yup.string().required(t<string>(`POSITION.CODE_REQUIRED`)),
    name: Yup.string().required(t<string>(`POSITION.NAME_REQUIRED`)),
  });

  const onSubmit = (newData: PositionData) => {
    const variables = {
      id: recordId,
      data: {
        parentId: newData.parentId,
        currencyId: newData.currencyId,
        code: newData.code,
        name: newData.name,
        primary: !!newData.primary,
        published: !!newData.published,
        public: !!newData.public,
        progressive: !!newData.progressive,
        registrationFee: newData.registrationFee,
        commissionPerPoint: newData.commissionPerPoint,
        commissionPoints: newData.commissionPoints,
        conditions: newData.conditions,
      },
    };

    handleSaveData(isNew ? createMutation : updateMutation, variables, onCancel);
  };

  return (
    <Formik
      initialValues={{
        ...data,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form onSubmit={handleSubmit} onCancel={onCancel}>
          <Tabs panelsToCard>
            <TabPanel label={t('GENERAL.BASIC_DATA')}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <PositionSelect fullWidth name="parentId" label={t('POSITION.PARENT')} />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField fullWidth required name="code" label={t('POSITION.CODE')} />
                </Grid>
                <Grid item xs={9}>
                  <TextField fullWidth required name="name" label={t('POSITION.NAME')} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Switch name="primary" label={t('POSITION.PRIMARY')} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Switch name="published" label={t('POSITION.PUBLISHED')} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Switch 
                    name="public" 
                    label={t('POSITION.PUBLIC')} 
                    helperText={t('POSITION.PUBLIC_DESCRIPTION')} 
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Switch
                    name="progressive"
                    label={t('POSITION.PROGRESSIVE_POSITION')}
                    helperText={t('POSITION.PROGRESSIVE_POSITION_DESCRIPTION')}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel label={t('POSITION.COMMISSIONS')}>
              <Grid container spacing={4}>
                <Grid item xs={6} sm={3}>
                  <PriceField fullWidth name="registrationFee" label={t('POSITION.REGISTRATION_FEE')} />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <PriceField fullWidth name="commissionPerPoint" label={t('POSITION.COMMISSION_PER_POINT')} />
                </Grid>
                <Grid item xs={6} sm={5}>
                  <CurrencySelect fullWidth name="currencyId" label={t('POSITION.CURRENCY')} />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={6} sm={3}>
                  <PriceField fullWidth name="commissionPoints" label={t('POSITION.COMMISSION_POINTS')} />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel label={t('POSITION.CONDITIONS')}>
              <PositionConditionTable data={values.conditions || []} onChange={handleChange} />
            </TabPanel>
          </Tabs>
        </Form>
      )}
    </Formik>
  );
};

export default PositionForm;
